import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem, TreeNode, DialogService, ConfirmationService } from 'primeng/primeng';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Subscription } from 'rxjs';
import { Authentication } from '../security/entities/authentication';
import { Occurrence } from '../shared/com/eddimas/avairs/model/entities/occurrence.model';
import { OccurrenceService } from '../shared/services/occurrence.service';
import { Aircraft } from '../shared/com/eddimas/avairs/model/entities/aircraft.model';
import { ViewportScroller } from '@angular/common';
import { LocaleService } from '../shared/services/locale.service';
import { Translatable } from '../shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { Engine } from '../shared/com/eddimas/avairs/model/entities/engine.model';
import { FlightCrewMember } from '../shared/com/eddimas/avairs/model/entities/flight-crew-member.model';
import { AirSpace } from '../shared/com/eddimas/avairs/model/entities/airspace.model';


@Component({
    selector: 'app-menu',
    template: `
        <ul app-submenu [item]="menuModel" root="true" class="layout-menu" 
            [reset]="reset" visible="true" parentActive="true"></ul>
        <div></div>    `
})
export class AppMenuComponent extends Translatable implements OnInit, OnDestroy {

    @Input() reset: boolean;
    

    OCCURRENCE_VIEW = '/occurrence/occurrence-view';

    menuModel: MenuItem[];
    selectedFiles: TreeNode;

    authentication: Authentication;
    authenticationSubscription: Subscription;

    selectedOccurrence: Occurrence;
    occurrenceSubscription: Subscription;

    occurrenceView = false;
    occurrenceViewSubscription: Subscription;
    

    labels = {
        Dashboard: 'Dashboard',
        System: 'System',
        Organization: 'Organization',
        Users: 'Users',
        Licences: 'Licences',
        ClientLicences: 'Client Licences',
        Occurrences: 'Occurrences',
        OccurrencesList: 'Occurrences List',
        OccurrenceNew: 'New occurrence',
        OccurrencesAccidents: 'Accidents',
        OccurrencesSerioursIncidents: 'Serious incidents',
        OccurrencesIncidents: 'Incidents',
        Occurrence: 'Occurrence',
        ExportImport: 'Export-import',
        ReportForm: 'Report form',
        ICAOReport: 'ICAO Report',
        BEAReport: 'BEA Report',
        NotificationSheet: 'Notification sheet',
        Statistics: 'Statistics',
        Incidents: 'Incidents',
        Accidents: 'Accidents',
        Weather: 'Weather',
        FlightPhase: 'Flight Phase',
        Operators: 'Operator',
        Wildlife: 'Wildlife',
        Categories: 'Categories',
        GeneralData: 'General data',
        Classification: 'Classification',
        WeatherData: 'Weather data',
        AerodromeData: 'Aerodrome data',
        WeatherReports: 'Weather reports',
        Airspaces: 'Airspaces',
        Aircraft: 'Aircraft',
        AirNavigationServices: 'Air Navigation Services',
        Events: 'Events',
        Narratives: 'Narratives',
        Precipitations: 'Precipitations',
        Separations: 'Separations',
        DangerousGoods: 'Dangerous Goods',
        RunwayIncursions: 'Runway Incursions',
        ReportingHistory: 'Reporting',
        RiskAssessment: 'Risk Assessment',

        DeleteSelectedAircraft: 'Delete selected Aircraft ?',
        DeleteSelectedAirspace: 'Delete selected Airspace ?',
        DeleteSelectedAerodromeData: 'Delete Aerodrome data ?',
        DeleteSelectedAircraftEngine: 'Delete selected Engine ?',
        DeleteSelectedAircraftCrewMember: 'Delete selected Crew member ?',
        Update: 'Update',
        Delete: 'Delete',
        AddDetails: 'Add Details',
        Engine: 'Engine',
        CrewMember: 'Crew member',
        TechnicalCategory: 'Technical issues',
        Operations: "Operations",
        FlightRules: "Flight rules"
    };


    constructor(
        public app: AppComponent,
        public router: Router,
        public confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public authenticationService: AuthenticationService,
        public occurrenceService: OccurrenceService,
        private viewportScroller: ViewportScroller,
        public translate: TranslateService,
        public localeService: LocaleService
    ) {
        super('AppMenuComponent');
    }

    ngOnDestroy(): void {
        this.authenticationSubscription.unsubscribe();
        this.occurrenceSubscription.unsubscribe();
        this.localeSubscription.unsubscribe();
    }

    ngOnInit() {
        this.subscribeAuthentication();
        this.subscribeOccurrence();
        this.subscribeOccurrenceView();
        this.subscribeToLocale();

    }

    subscribeToLocale() {
        this.localeSubscription = this.localeService.localeSubject.subscribe((locale: string) => {
            this.locale = locale;
            this.edTranslate();
        });
        this.localeService.emitLocaleSubject();
    }

    edTranslate() {
        
        var className = 'AppMenuComponent';
		//console.log('Class name is: '+className);

		var labelsProperties = Object.getOwnPropertyNames(this.labels);

		var translatingProperties = [];
		labelsProperties.forEach(element => {
			translatingProperties.push(className + "Labels." + element);
		});

        this.translate.use(this.locale);
        this.translate.get(translatingProperties).subscribe(translated => {
            labelsProperties.forEach(element => {
                this.labels[element] = translated[className + "Labels." + element];

            });
            this.initMenuModel();
        });
    }

    /**
     * Subscribe to occurrence subject
     */
    subscribeAuthentication() {
        this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
            this.authentication = authentication;
            this.initMenuModel();
        });
        this.authenticationService.emitAuthenticationSubjctSubjct();
    }

    /**
     * Subscribe to occurrence subject
     */
    subscribeOccurrence() {
        this.occurrenceSubscription = this.occurrenceService.occurrenceSubject.subscribe((occurrence: Occurrence) => {
            this.selectedOccurrence = occurrence;
            this.initMenuModel();
        });
        this.occurrenceService.emitOccurrenceSubject();
    }

    expandOccurrenceMenu(){
        // activate current item and deactivate active sibling if any
        
        var OccurrenceItem = this.menuModel.find(item => item.id === 'OCCURRENCE_MENU');
        OccurrenceItem.expanded = true;
        
    }

    /**
     * Subscribe to occurrence subject
     */
    subscribeOccurrenceView() {
        this.occurrenceViewSubscription = this.occurrenceService.occurrenceViewSubject.subscribe((occurrenceView: boolean) => {
            this.occurrenceView = occurrenceView;
            this.initMenuModel();
            this.expandOccurrenceMenu();
        });
        this.occurrenceService.emitOccurrenceViewSubject();
    }

    occurrenceHasWeather() {

        return (
            this.selectedOccurrence &&
            (this.selectedOccurrence.attributes.lightConditions != undefined ||
                this.selectedOccurrence.attributes.visibility != undefined ||
                this.selectedOccurrence.attributes.wxConditions != undefined ||
                this.selectedOccurrence.attributes.weatherRelevant != undefined ||
                this.selectedOccurrence.attributes.speedMeasuredAt != undefined ||
                this.selectedOccurrence.attributes.windSpeed != undefined ||
                this.selectedOccurrence.attributes.heightOfCloudBase != undefined ||
                this.selectedOccurrence.attributes.cloudAmount != undefined ||
                this.selectedOccurrence.attributes.dewPoint != undefined ||
                this.selectedOccurrence.attributes.airTemperature != undefined ||
                this.selectedOccurrence.attributes.maximumGust != undefined ||
                this.selectedOccurrence.attributes.windDirection != undefined ||
                this.selectedOccurrence.attributes.windGusts != undefined ||
                this.selectedOccurrence.entities.precipitationAndOtherWeatherPhenomena.length > 0)
        ) ?
            true :
            false;
    }

    occurrenceHasClassification() {

        return (
            this.selectedOccurrence &&
            (this.selectedOccurrence.attributes.occurrenceClass != undefined ||
                this.selectedOccurrence.attributes.detectionPhase != undefined ||
                this.selectedOccurrence.attributes.occurrenceCategory.length > 0)
        ) ?
            true :
            false;
    }

    onSelectAirspace(name: string) {
        this.occurrenceService.setSelectedAirspaceName(name);
    }

    onDeleteAirspace(name: string) {
        this.confirmationService.confirm({
            message: this.labels.DeleteSelectedAirspace,
            accept: () => {
                this.occurrenceService.deleteSelectedAirspaceName(name);
                this.router.navigate([this.OCCURRENCE_VIEW]);
                this.initMenuModel();
            }
        });
    }

    onSelectAircraft(registration: string) {
        this.occurrenceService.setSelectedAircraftRegistration(registration);
    }

    onDeleteAircraft(registration: string) {
        this.confirmationService.confirm({
            message: this.labels.DeleteSelectedAircraft,
            accept: () => {
                this.occurrenceService.deleteSelectedAircraftRegistration(registration);
                this.router.navigate([this.OCCURRENCE_VIEW]);
                this.initMenuModel();
            }
        });
    }

    onSelectAircraftEngine(serialNumber: string) {
        this.occurrenceService.setSelectedEngineSerialNumber(serialNumber);
    }

    

    onDeleteAircraftEngine(serialNumber: string) {
        this.confirmationService.confirm({
            message: this.labels.DeleteSelectedAircraftEngine,
            accept: () => {
                this.occurrenceService.deleteSelectedEngineSerialNumber(serialNumber);
                this.router.navigate([this.OCCURRENCE_VIEW]);
                this.initMenuModel();
            }
        });
    }

    onSelectAircraftCrewMember(category: string) {
        this.occurrenceService.setSelectedCrewMemberCategory(category);
    }

    getAircraftCrewMemberCategory(category: string){
        return this.occurrenceService.getAircraftCrewMemberCategory(category);
    }

    onDeleteAircraftCrewMember(category: string) {
        this.confirmationService.confirm({
            message: this.labels.DeleteSelectedAircraftCrewMember,
            accept: () => {
                this.occurrenceService.deleteSelectedCrewMemberCategory(category);
                this.router.navigate([this.OCCURRENCE_VIEW]);
                this.initMenuModel();
            }
        });
    }
    

    onDeleteAerodromeGeneral(){
        this.confirmationService.confirm({
            message: this.labels.DeleteSelectedAerodromeData,
            accept: () => {
                this.occurrenceService.deleteSelectedOccurrenceAerodromeGeneral();
                this.router.navigate([this.OCCURRENCE_VIEW]);
                this.initMenuModel();
            }
        });
    }

    setAircaftSubmenu(items: MenuItem[]) {

        items.push({ label: this.labels.Aircraft, icon: 'fas fa-plane', id: 'AIRCRAFT_MENU', command: () => { this.onAnchorScroll('_OccurrenceAircraft'); } });

        var acItems: MenuItem[] = [];
        this.selectedOccurrence.entities.aircraft.forEach((aircraft: Aircraft) => {
            var acSubItems = {
                label: aircraft.attributes.aircraftRegistration.value, icon: 'fas fa-fighter-jet', command: () => { this.onAnchorScroll(aircraft.attributes.aircraftRegistration.value); },
                items: [
                    /* { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/aircraft-info-update'] },
                    { label: this.labels.Delete, icon: 'pi pi-trash', command: () => { this.onDeleteAircraft(aircraft.attributes.aircraftRegistration.value); } },
                    {
                        label: this.labels.AddDetails, icon: 'pi pi-pencil',
                        items: [
                            { label: this.labels.Engine, icon: 'pi pi-plus', routerLink: ['/occurrence/aircraft-engine-create'] },
                            { label: this.labels.CrewMember, icon: 'pi pi-plus', routerLink: ['/occurrence/flight-crew-member-create'] }
                        ]
                    } */
                ]
            };

            //add engines sub menu
            if (aircraft && aircraft.entities && aircraft.entities.engine && aircraft.entities.engine.length > 0) {
                var enginesItem = {
                    label: 'Engines', icon: 'fas fa-rocket',
                    items: []
                };

                //add engine item
                aircraft.entities.engine.forEach((engine: Engine) => {
                    enginesItem.items.push({
                        label: engine.attributes.engineSerialNumber.value, icon: 'fas fa-space-shuttle', command: () => { this.onSelectAircraftEngine(engine.attributes.engineSerialNumber.value); }
                        /* ,
                        items: [
                            { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/aircraft-engine-update'] },
                            { label: this.labels.Delete, icon: 'pi pi-trash', command: () => { this.onDeleteAircraftEngine(engine.attributes.engineSerialNumber.value); } }
                        ] */
                    });
                });

                acSubItems.items.push(enginesItem);
            }

            //add crewMember sub menu
            if (aircraft && aircraft.entities && aircraft.entities.flightCrewMember && aircraft.entities.flightCrewMember.length > 0) {
                var crewMembersItem = {
                    label: 'Crew members', icon: 'fas fa-users',
                    items: []
                };

                //add engine item
                aircraft.entities.flightCrewMember.forEach((crew: FlightCrewMember) => {
                    crewMembersItem.items.push({
                        label: this.getAircraftCrewMemberCategory(crew.attributes.category.value), icon: 'fas fa-user-tie', command: () => { this.onSelectAircraftCrewMember(crew.attributes.category.value); }
                        /* ,
                        items: [
                            { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/flight-crew-member-update'] },
                            { label: this.labels.Delete, icon: 'pi pi-trash', command: () => { this.onDeleteAircraftCrewMember(crew.attributes.category.value); } }
                        ] */
                    });
                });

                acSubItems.items.push(crewMembersItem);
            }

            if(acSubItems.items.length === 0) acSubItems.items = null;
            acItems.push(acSubItems);
        });

        items.find(item => item.id === 'AIRCRAFT_MENU').items = acItems;
    }

    setAirspaceSubmenu(items: MenuItem[]) {

        items.push({ label: this.labels.Airspaces, icon: 'fas fa-adjust', id: 'AIRSPACE_MENU', command: () => { this.onAnchorScroll('_OccurrenceAirspace'); } });

        var spaceItems: MenuItem[] = [];
        this.selectedOccurrence.entities.airSpace.forEach((airspace: AirSpace) => {
            var spaceSubItems = {
                label: airspace.attributes.airspaceName.value, icon: 'fas fa-bookmark', command: () => { this.onAnchorScroll(airspace.attributes.airspaceName.value.replace(' ','')); }
                /* ,
                items: [
                    { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/airspace-update'] },
                    { label: this.labels.Delete, icon: 'pi pi-trash', command: () => { this.onDeleteAirspace(airspace.attributes.airspaceName.value); } }
                ] */
            };


            spaceItems.push(spaceSubItems);
        });

        items.find(item => item.id === 'AIRSPACE_MENU').items = spaceItems;
    }

    /**
     * Set occcurrence menu content
     */
    setOccurrenceMenu() {

        if (this.occurrenceView) {

            var items: MenuItem[] = [];
            items.push({ 
                label: this.labels.GeneralData, icon: 'fas fa-database', command: () => { this.onAnchorScroll('_OccurrenceGeneral'); }
                /* ,
                items: [
                            { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/occurrence-update'] }
                ] */
            });


            if (this.occurrenceHasClassification()) {
                items.push({ label: this.labels.Classification, icon: 'fas fa-columns', command: () => { this.onAnchorScroll('_OccurrenceClassification'); } });
            }

            if (this.occurrenceHasWeather()) {
                items.push({ label: this.labels.WeatherData, icon: 'fas fa-cloud-rain', command: () => { this.onAnchorScroll('_OccurrenceWeather'); } });
            }

            if (this.selectedOccurrence && this.selectedOccurrence.entities.aerodromeGeneral.length > 0) {
                items.push({ 
                    label: this.labels.AerodromeData, icon: 'fas fa-plane-arrival', command: () => { this.onAnchorScroll('_OccurrenceAerodromeGeneral'); }
                    /* ,
                    items: [
                            { label: this.labels.Update, icon: 'pi pi-pencil', routerLink: ['/occurrence/aerodrome-general-edit'] },
                            { label: this.labels.Delete, icon: 'pi pi-trash', command: () => { this.onDeleteAerodromeGeneral(); } }
                        ] */
                });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.aerodromeWeatherReports.length > 0) {
                items.push({ label: this.labels.WeatherReports, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.airSpace.length > 0) {
                this.setAirspaceSubmenu(items);
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.aircraft.length > 0) {
                this.setAircaftSubmenu(items);
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.airNavigationService.length > 0) {
                items.push({ label: this.labels.AirNavigationServices, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.events.length > 0) {
                items.push({ label: this.labels.Events, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.narrative.length > 0) {
                items.push({ label: this.labels.Narratives, icon: 'fas fa-clipboard-list', command: () => { this.onAnchorScroll('_OccurrenceNarrative'); } });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.precipitationAndOtherWeatherPhenomena.length > 0) {
                items.push({ label: this.labels.Precipitations, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.separation.length > 0) {
                items.push({ label: this.labels.Separations, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.dangerousGoods.length > 0) {
                items.push({ label: this.labels.DangerousGoods, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.runwayIncursion.length > 0) {
                items.push({ label: this.labels.RunwayIncursions, icon: 'fa fa-fw fa-bars' });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.reportingHistory.length > 0) {
                items.push({ label: this.labels.ReportingHistory, icon: 'fa fa-fw fa-book', command: () => { this.onAnchorScroll('_OccurrenceReporting'); } });
            }
            if (this.selectedOccurrence && this.selectedOccurrence.entities.riskAssessment.length > 0) {
                items.push({ label: this.labels.RiskAssessment, icon: 'fas fa-traffic-light', command: () => { this.onAnchorScroll('_OccurrenceRiskAssessment'); } });
            }

            this.menuModel.find(item => item.id === 'OCCURRENCE_MENU').items = items;
        }
    }

    public onAnchorScroll(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    initMenuModel() {
        this.menuModel = [
            { label: this.labels.Dashboard, icon: 'fa fa-fw fa-home', routerLink: ['/'] },
            {
                label: this.labels.System, icon: 'fa fa-fw fa-cog',
                visible: this.selectedOccurrence === null && (this.authentication.hasSysRole || this.authentication.hasAdminRole),
                items: [
                    {
                        label: this.labels.Organization, icon: 'fa fa-fw fa-bars', routerLink: ['/security/organization-view'],
                        visible: this.authentication.hasSysRole
                    },
                    {
                        label: this.labels.Users, icon: 'fa fa-fw fa-user-plus', routerLink: ['/security/user-view']
                    }/* ,
                    {
                        label: this.labels.Licences, icon: 'fa fa-fw fa-key', routerLink: ['/security/licence-view'],
                        visible: this.authentication.hasAdminRole
                    },
                    {
                        label: this.labels.ClientLicences, icon: 'fa fa-fw fa-key', routerLink: ['/security/licence-client'],
                        visible: this.authentication.hasSysRole
                    } */
                ]

            },
            {
                label: this.labels.Occurrences, icon: 'fas fa-bullhorn',
                visible: this.selectedOccurrence === null && (this.authentication.hasAdminRole || this.authentication.hasSupRole || this.authentication.hasOprRole),
                routerLink: ['/occurrence/occurrence-list'],
                items: [
                    {label: this.labels.OccurrenceNew, icon: 'fas fa-bell', routerLink: ['/occurrence/occurrence-create'] },
                    { label: this.labels.OccurrencesAccidents, icon: 'fas fa-car-crash', routerLink: ['/occurrence/occurrence-list/accident'] },
                    { label: this.labels.OccurrencesSerioursIncidents, icon: 'fab fa-battle-net', routerLink: ['/occurrence/occurrence-list/seriousIncident'] },
                    { label: this.labels.OccurrencesIncidents, icon: 'fab fa-battle-net', routerLink: ['/occurrence/occurrence-list/incident'] }
                ]

            },
            {
                label: this.labels.ExportImport, icon: 'fas fa-upload', routerLink: ['/occurrence/export'] ,
                visible: this.selectedOccurrence === null && (this.authentication.hasAdminRole || this.authentication.hasSupRole || this.authentication.hasOprRole)
                
            },
            {
                label: this.labels.ReportForm, icon: 'fas fa-envelope', routerLink: ['/notification/notification-status'] ,
                visible: this.selectedOccurrence === null && (this.authentication.hasAdminRole || this.authentication.hasSupRole || this.authentication.hasOprRole)
                
            },
            {
                label: this.labels.Statistics, icon: 'fas fa-chart-line', routerLink: ['/statistics/statistics-dashboard'],
                visible: this.selectedOccurrence === null && (this.authentication.hasAdminRole || this.authentication.hasSupRole || this.authentication.hasOprRole),
                items: [
                    {
                        label: this.labels.Accidents, icon: 'fas fa-car-crash', routerLink: ['/statistics/statistics-accidents']
                    },
                    {
                        label: this.labels.Incidents, icon: 'fab fa-battle-net', routerLink: ['/statistics/statistics-incidents']
                    },
                    {
                        label: this.labels.FlightPhase, icon: 'fab fa-avianex', routerLink: ['/statistics/statistics-phases']
                    },
                    {
                        label: this.labels.Wildlife, icon: 'fas fa-dove', routerLink: ['/statistics/statistics-bird-wild']
                    },
                    {
                        label: this.labels.Weather, icon: 'fas fa-poo-storm', routerLink: ['/statistics/statistics-weather']
                    },
                    {
                        label: this.labels.Categories, icon: 'fas fa-layer-group', routerLink: ['/statistics/statistics-categories']
                    },
                    {
                        label: this.labels.Operators, icon: 'fas fa-crosshairs', routerLink: ['/statistics/statistics-operators']
                    },
                    {
                        label: this.labels.TechnicalCategory, icon: 'fas fa-fan', routerLink: ['/statistics/statistics-technical-categories']
                    },
                    {
                        label: this.labels.Operations, icon: 'fas fa-paper-plane', routerLink: ['/statistics/statistics-operations']
                    },
                    {
                        label: this.labels.FlightRules, icon: 'fas fa-helicopter', routerLink: ['/statistics/statistics-flight-rules']
                    }
                ]
            },
            {
                label: this.labels.Occurrence, icon: 'fas fa-bullhorn', id: 'OCCURRENCE_MENU', expanded: true,
                visible: this.selectedOccurrence != null && (this.authentication.hasAdminRole || this.authentication.hasSupRole || this.authentication.hasOprRole),
            }
        ];

        this.setOccurrenceMenu();
    }



    changeTheme(theme: string, scheme: string) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';

        this.app.menuMode = scheme;
    }
}



@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)"
                   class="ripplelink" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                </a>

                <a (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                </a>
                <div class="submenu-arrow" *ngIf="child.items"></div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]=" isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px',
                opacity: 0
            })),
            state('visibleAnimated', style({
                height: '*',
                opacity: 1
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppComponent) { }

    itemClick(event: Event, item: MenuItem, index: number) {

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
