import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { RepositoryService } from './repository.service';
import { E5XEnumItem } from '../com/eddimas/avairs/model/beans/e5x-enum-item.model';
import { HttpMapping } from '../constants/http-mapping';
import { LocaleService } from './locale.service';
import { Subscription } from 'rxjs';
import { AttributeValue } from '../com/eddimas/avairs/model/beans/attribute-value';

@Injectable({
  providedIn: 'root'
})
export class StaticValuesService implements OnInit, OnDestroy {


  locale = 'fr';
  localeSubscription: Subscription;

  constructor(
    public repository: RepositoryService,
    public localeService: LocaleService
  ) { 
    this.subscribeToLocale();
  }


  
  ngOnInit(): void {
    //this.subscribeToLocale();
  }

  ngOnDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  subscribeToLocale() {
    this.localeSubscription = this.localeService.localeSubject.subscribe((locale: string) => {
      this.locale = locale;
    });
    this.localeService.emitLocaleSubject();
  }

  getAttributeStaticValues(attributes: string) {
    const route = HttpMapping.OCCURRENCE_GET_ATTRIBUTES_VALUES.replace('{attritubes}', attributes).replace('{lang}', this.locale);
    return this.repository.getData(route, RepositoryService.SERVICE_AVAIRS);
  }

  getAttributesLabels(attributes: AttributeValue[]) {
    const route = HttpMapping.OCCURRENCE_GET_ATTRIBUTES_LABELS.replace('{lang}', this.locale);
    return this.repository.send(route, attributes, RepositoryService.SERVICE_AVAIRS);
  }

  getFilteredAttributeStaticValues(attributes: string) {

    const route = HttpMapping.OCCURRENCE_GET_FILTERED_ATTRIBUTES_VALUES.replace('{attritubes}', attributes).replace('{lang}', this.locale);
    return this.repository.getData(route, RepositoryService.SERVICE_AVAIRS);
  }
}
