import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RunwayDirection } from 'src/app/shared/entities/runway-direction';
import { RunwayProtectArea } from 'src/app/shared/entities/runway-protect-area';
import { GisElevatedPoint } from 'src/app/shared/entities/gis-elevated-point';
import { RunwayCentrelinePoint } from 'src/app/shared/entities/runway-centerline-point';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { Runway } from 'src/app/shared/entities/runway';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem, Message, MenuItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { CoordinatesConvertorService } from 'src/app/shared/services/coordinates-convertor.service';
import { CoordinatesRegex } from 'src/app/shared/constants/coordinates-regex';

@Component({
	selector: 'app-runway-create',
	templateUrl: './runway-create.component.html',
	styleUrls: ['./runway-create.component.css']
})
export class RunwayCreateComponent implements OnInit {

	//navigation routes
	ROUTE_RUNWAY_VIEW = '/aerodrome/runway-view';

	runwayForm: FormGroup;
	aerodrome: AirportHeliport;
	runway: Runway;


	menuItems: MenuItem[] = [];

	msgs: Message[] = [];

	labels = {
		Title: 'Create Runway',
		AddRunway: 'Add Runway',
		Designator: 'Runway Designator',
		Length: 'Length (M)',
		Width: 'Width (M)',
		LengthStrip: 'Strip length (M)',
		WidthStrip: 'Strip width (M)',
		Cancel: 'Cancel',
		Save: 'Save',
		GeneralData: ' RWY General Data',
		QFUDesignator: 'QFU Designator',
		RunwayQFU: 'Runway QFU',
		TrueBearing: 'True Bearing',
		SlopeTDZ: 'Slope TDZ',
		ElevationTDZ: 'Elevation TDZ',
		RemoveQFU: 'Remove QFU ?',
		RemoveCenterlinePoint: 'Remove Centerline Point ?',
		RemoveProtectArea: 'Remove runway Protect Area ?',
		ButtonRemoveQFU: 'Remove',
		LocationLatitue: 'Latitude',
		LocationLongitude: 'Longitude',
		LocationElevation: 'Elevation (M)',
		PointDesignator: 'Point Designator',
		AddPoint: 'Add Point',
		PointRole: 'Point Role',
		RunwaycenterlinePoints: 'Runway centerline Points',
		RunwayProtectAreas: 'Runway Protect Areas',
		AddArea: 'Add Area',
		ProtectAreaType: 'Area Type',
		ProtectAreaLength: 'Area Length (M)',
		ProtectAreaWidth: 'Area Width (M)',
		RemoveApproachType: 'Remove Approach Type ?',
		RemovePrecisionCategoryType: 'Remove Precision category Type ?',
		PrecisionCategory: 'Precision Category',
		RunwayPrecisionCategories: 'Runway Precision categories',
		ApproachType: 'Approach Type',
		RunwayApproachTypes: 'Runway Approach Types',
		AddApproachType: 'Add Approach Type',
		AddPrecisionCategory: 'Add Category',
		SaveOkMessage: 'Runway saved successfully !',
		SaveFailedMessage: 'Failed to save Runway !'
	};

	runwayCenterlinePointTypes: SelectItem[] = [
		{ label: '', value: null },
		{ label: 'START', value: 'START' },
		{ label: 'THR', value: 'THR' },
		{ label: 'DISTHR', value: 'DISTHR' },
		{ label: 'TDZ', value: 'TDZ' },
		{ label: 'MID', value: 'MID' },
		{ label: 'END', value: 'END' },
		{ label: 'START_RUN', value: 'START_RUN' },
		{ label: 'LAHSO', value: 'LAHSO' },
		{ label: 'ABEAM_GLIDESLOPE', value: 'ABEAM_GLIDESLOPE' },
		{ label: 'ABEAM_PAR', value: 'ABEAM_PAR' },
		{ label: 'ABEAM_ELEVATION', value: 'ABEAM_ELEVATION' },
		{ label: 'ABEAM_RER', value: 'ABEAM_RER' },
		{ label: 'OTHER', value: 'OTHER' }
	];

	runwayProtectAreaTypes: SelectItem[] = [
		{ label: '', value: null },
		{ label: 'CWY', value: 'CWY' },
		{ label: 'RESA', value: 'RESA' },
		{ label: 'OFZ', value: 'OFZ' },
		{ label: 'IOFZ', value: 'IOFZ' },
		{ label: 'POFZ', value: 'POFZ' },
		{ label: 'ILS', value: 'ILS' },
		{ label: 'VGSI', value: 'VGSI' },
		{ label: 'STOPWAY', value: 'STOPWAY' },
		{ label: 'OTHER', value: 'OTHER' }
	];

	runwayApproachTypes: SelectItem[] = [
		{ label: '', value: null },
		{ label: 'Visual Approach', value: 'VISUAL_APPROACH' },
		{ label: 'Classic Approach', value: 'CLASSIC_APPROACH' },
		{ label: 'Precision Approach', value: 'PRECISION_APPROACH' },
		{ label: 'Other', value: 'OTHER' }
	];

	runwayPrecisionApproachCategoryTypes: SelectItem[] = [
		{ label: '', value: null },
		{ label: 'Precision CAT I', value: 'CAT_I' },
		{ label: 'Precision CAT II', value: 'CAT_II' },
		{ label: 'Precision CAT III', value: 'CAT_III' },
		{ label: 'Other', value: 'OTHER' }
	];


	//showAddQFU: boolean = false;

	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent,
		public convertor: CoordinatesConvertorService,
		public cref: ChangeDetectorRef,
		public confirmationService: ConfirmationService
	) { }

	ngOnInit() {
		this.initRunwayForm();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}

	initRunwayForm() {
		this.runwayForm = this.fb.group({
			designator: ['', [Validators.required]],
			nominalLength: ['', [Validators.required]],
			nominalWidth: ['', [Validators.required]],
			lengthStrip: ['', [Validators.required]],
			widthStrip: ['', [Validators.required]],
			runwayDirections: this.fb.array([], [Validators.required])
		});
	}

	get runwayDirectionsArray(): FormArray {
		return this.runwayForm.get('runwayDirections') as FormArray;
	}

	get runwayDirectionGroup(): FormGroup {
		return this.fb.group({
			designator: ['', [Validators.required]],
			trueBearing: ['', [Validators.required]],
			slopeTDZ: ['', [Validators.required]],
			elevationTDZ: ['', [Validators.required]],
			approachType: ['', [Validators.required]],
			precisionApproachCategoryType: ['', [Validators.required]],
			runwayCentrelinePoints: this.fb.array([], [Validators.required]),
			runwayProtectAreas: this.fb.array([])
		});
	}

	runwayCentrelinePointsArray(runwayDirection: any): FormArray {
		return runwayDirection.get('runwayCentrelinePoints') as FormArray;
	}

	runwayProtectAreasArray(runwayDirection: any): FormArray {
		return runwayDirection.get('runwayProtectAreas') as FormArray;
	}


	get runwayCentrelinePointGroup(): FormGroup {
		return this.fb.group({
			role: ['', [Validators.required]],
			designator: ['', [Validators.required]],
			location: this.fb.group({
				latitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LATITUDE_COMPLETE_PATTERN)]],
				longitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LONGITUDE_COMPLETE_PATTERN)]],
				baseElevation: ['', [Validators.required]],
				topElevation: ['']
			})
		});
	}

	get runwayProtectAreaGroup(): FormGroup {
		return this.fb.group({
			type: ['', [Validators.required]],
			width: ['', [Validators.required]],
			length: ['', [Validators.required]]
		});
	}

	onAddRunwayDirection() {
		(this.runwayForm.get('runwayDirections') as FormArray).push(this.runwayDirectionGroup);
	}

	onAddRunwayCentrelinePoint(runwayDirection: FormGroup) {
		(runwayDirection.get('runwayCentrelinePoints') as FormArray).push(this.runwayCentrelinePointGroup);
	}

	onAddRunwayProtectArea(runwayDirection: FormGroup) {
		(runwayDirection.get('runwayProtectAreas') as FormArray).push(this.runwayProtectAreaGroup);
	}

	onRemoveRunwayDirection(index: number) {
		(this.runwayForm.get('runwayDirections') as FormArray).removeAt(index);
	}

	onRemoveRunwayCentrelinePoint(runwayDirection: FormGroup, event: any) {
		let index = event.index;
		(runwayDirection.get('runwayCentrelinePoints') as FormArray).removeAt(index);
	}

	onRemoveRunwayProtectArea(runwayDirection: FormGroup, event: any) {
		let index = event.index;
		(runwayDirection.get('runwayProtectAreas') as FormArray).removeAt(index);
	}


	onCreate() {

		if (this.runwayForm.valid) {
			this.executeCreate();
		}
	}

	executeCreate() {
		if (this.activatedRoute.snapshot.paramMap.keys.includes('adId')) {
			let aerodromeId = this.activatedRoute.snapshot.params['adId'];

			// create runway FormGroup
			this.runway = new Runway();
			this.runway.designator = this.runwayForm.value['designator'];
			this.runway.nominalLength = this.runwayForm.value['nominalLength'];
			this.runway.nominalWidth = this.runwayForm.value['nominalWidth'];
			this.runway.lengthStrip = this.runwayForm.value['lengthStrip'];
			this.runway.widthStrip = this.runwayForm.value['widthStrip'];

			let runwaydirections: RunwayDirection[] = [];
			this.runwayDirectionsArray.controls.forEach((directionConctrol: FormGroup) => {
				let runwayDirection = new RunwayDirection();
				runwayDirection.designator = directionConctrol.value['designator'];
				runwayDirection.trueBearing = directionConctrol.value['trueBearing'];
				runwayDirection.slopeTDZ = directionConctrol.value['slopeTDZ'];
				runwayDirection.elevationTDZ = directionConctrol.value['elevationTDZ'];
				runwayDirection.approachType = directionConctrol.value['approachType'];
				runwayDirection.precisionApproachCategoryType = directionConctrol.value['precisionApproachCategoryType'];

				let centerlinePoints: RunwayCentrelinePoint[] = [];
				this.runwayCentrelinePointsArray(directionConctrol).controls.forEach((pointConctrol: FormGroup) => {
					let centerlinePoint = new RunwayCentrelinePoint();
					centerlinePoint.role = pointConctrol.value['role'];
					centerlinePoint.designator = pointConctrol.value['designator'];
					centerlinePoint.location = new GisElevatedPoint(
						this.convertor.coordinateToDD(pointConctrol.value['location'].latitude),
						this.convertor.coordinateToDD(pointConctrol.value['location'].longitude),
						pointConctrol.value['location'].baseElevation,
						pointConctrol.value['location'].baseElevation
					);
					centerlinePoints.push(centerlinePoint);
				});
				runwayDirection.runwayCentrelinePoints = centerlinePoints;

				let runwayProtectAreas: RunwayProtectArea[] = [];
				this.runwayProtectAreasArray(directionConctrol).controls.forEach((areaConctrol: FormGroup) => {
					let runwayProtectArea = new RunwayProtectArea();
					runwayProtectArea.type = areaConctrol.value['type'];
					runwayProtectArea.width = areaConctrol.value['width'];
					runwayProtectArea.length = areaConctrol.value['length'];
					runwayProtectAreas.push(runwayProtectArea);
				});
				runwayDirection.runwayProtectAreas = runwayProtectAreas;

				runwaydirections.push(runwayDirection);
			});

			this.runway.runwayDirections = runwaydirections;

			// save to backend
			let route = HttpMapping.AIRPORT_HELIPORT_PUT_ADD_RUNWAY.replace('{id}', aerodromeId);
			this.repository.update(route, this.runway, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
				this.router.navigate([this.ROUTE_RUNWAY_VIEW]);
				this.app.showInfo(this.labels.SaveOkMessage);
			}, _error => {
				this.app.showError(this.labels.SaveFailedMessage);
			});
		}
	}

	get showAddQFU(): boolean {
		return this.runwayDirectionsArray.controls.length < 2;
	}

	onCancel() {
		this.router.navigate([this.ROUTE_RUNWAY_VIEW]);
	}

}
