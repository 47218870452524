import { Pipe, PipeTransform } from '@angular/core';
import { E5XEnumItem } from '../com/eddimas/avairs/model/beans/e5x-enum-item.model';

@Pipe({
  name: 'e5xEnum'
})
export class E5xEnumPipe implements PipeTransform {

  transform(value: string, items?: E5XEnumItem[]): string {
    let searched = items.find(item => (item.valueID === value));
    var label = searched ? searched.description : value;
    return label;
  }
}
