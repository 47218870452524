import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppComponent } from '../app.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { ThemeService } from '../shared/services/theme.service';
import { Authentication } from '../security/entities/authentication';
import { DialogService } from 'primeng/api';
import { PasswordChangeComponent } from '../security/password-change/password-change.component';
import { RepositoryService } from '../shared/services/repository.service';
import { HttpMapping } from '../shared/constants/http-mapping';
import { Occurrence } from '../shared/com/eddimas/avairs/model/entities/occurrence.model';
import { OccurrenceService } from '../shared/services/occurrence.service';
import { ProfileEditComponent } from '../security/profile-edit/profile-edit.component';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

	ROUTE_LOGIN = '/security/login';
	ROUTE_USER_LICENCES = '/security/licence-user';
	ROUTE_MAIN = '/main'




	labels = {
		Avairs: 'AViation Accidents and Incidents Reporting System',
		PasswordChangeOkMessage: 'Password changed successfuly !',
		ProfileSaveOkMessage: 'Profile saved successfuly !',
		Profile: 'Profile',
		Theme: 'Theme',
		Credentials: 'Credentials',
		MyLicences: 'My Licences',
		Login: 'Login',
		Logout: 'Logout'
	};

	themeModel: any[];

	authentication: Authentication;
	authenticationSubscription: Subscription;

	selectedOccurrence: Occurrence;
	occurrenceSubscription: Subscription;

	constructor(
		public app: AppComponent,
		public repository: RepositoryService,
		public router: Router,
		public dialogService: DialogService,
		public authenticationService: AuthenticationService,
		public themeService: ThemeService,
		public occurrenceService: OccurrenceService
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
		this.occurrenceSubscription.unsubscribe();
	}

	onLogin() {
		this.router.navigate([this.ROUTE_LOGIN]);
	}

	onChangePassword() {
		const ref = this.dialogService.open(PasswordChangeComponent, {
			header: 'Credentails',
			width: '30%',
			contentStyle: { "max-height": "400px", "overflow": "auto" }
		});

		ref.onClose.subscribe((passwordChange: any) => {

			if (passwordChange) {
				let username = this.authentication.subject;
				const route = HttpMapping.CORE_USER_PUT_PASS_BY_USERNAME
					.replace('{username}', username)
					.replace('{currentpassword}', passwordChange.currentPassword)
					.replace('{newpassword}', passwordChange.newPassword);
				this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
					this.app.showInfo(this.labels.PasswordChangeOkMessage);
				});
			}
		});
	}

	onEditProfile() {
		const ref = this.dialogService.open(ProfileEditComponent, {
			header: 'Profile',
			width: '30%',
			contentStyle: { "max-height": "400px", "overflow": "auto" }
		});

		ref.onClose.subscribe((profile: { locale: string }) => {

			if (profile) {
				let username = this.authentication.subject;
				const route = HttpMapping.CORE_USER_PUT_LOCALE_BY_USERNAME
					.replace('{username}', username)
					.replace('{locale}', profile.locale);
				this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
					this.app.showInfo(this.labels.ProfileSaveOkMessage);
				});
			}
		});
	}

	onShowLicences() {
		this.router.navigate([this.ROUTE_USER_LICENCES]);
	}

	onLogout() {
		this.authenticationService.logout();
		this.router.navigate([this.ROUTE_MAIN]);
	}

	ngOnInit() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
		this.initThemeModel();
		this.subscribeOccurrence();
	}


	/**
	 * Subscribe to occurrence subject
	 */
	subscribeOccurrence() {
		this.occurrenceSubscription = this.occurrenceService.occurrenceSubject.subscribe((occurrence: Occurrence) => {
			this.selectedOccurrence = occurrence;
		});
		this.occurrenceService.emitOccurrenceSubject();
	}

	initThemeModel() {
		this.themeModel = [
			{ label: 'Blue', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Cyan', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Green', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Yellow', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Purple', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Pink', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Blue Grey', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Teal', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Orange', icon: 'fa fa-fw fa-paint-brush' },
			{ label: 'Grey', icon: 'fa fa-fw fa-paint-brush' }

		];
	}

	handleChangeTheme(color: string) {
		var theme = color.replace(' ', '').toLowerCase();
		this.themeService.changeTheme(theme);
		
		let username = this.authentication.subject;
		const route = HttpMapping.CORE_USER_PUT_THEME_BY_USERNAME
			.replace('{username}', username)
			.replace('{theme}', theme);
		this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
		});
	}

	get showMenuButton(): boolean {
		return this.authentication.isAuthenticated;
	}


}
