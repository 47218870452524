import { Component, OnInit, Input, AfterViewInit, ElementRef } from '@angular/core';
import { InputText } from 'primeng/primeng';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-dms',
  templateUrl: './dms.component.html',
  styleUrls: ['./dms.component.css']
})
export class DmsComponent extends InputText implements OnInit, AfterViewInit {
  

  LATITUDE_DD_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}|"
    + "9{1}0{1}|"
    + "9{1}0{1}°{1}|"
    + "9{1}0{1}°{1}[NS]{1}|"
    + "9{1}0{1}\\.{1}0{0,16}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}[NS]{1}|"

    + "[0-8]{1}|"
    + "[0-8]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}|"
    + "[0-8]{1}\\d{1}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{0,16}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}[NS]{1})$"
  );

  LATITUDE_DM_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{0,8}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[NS]{1}|"

    + "[0-8]{1}\\d{1}°{1}[0-5]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[NS]{1})$"
  );

  LATITUDE_DMS_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{0,4}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[NS]{1}|"

    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[NS]{1})$"
  );

  LATITUDE_COMPLETE_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}[NS]{1}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}[NS]{1}|"

    + "9{1}0{1}°{1}0{1}0{1}'{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[NS]{1}|"

    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[NS]{1})$"
  );

  LONGITUDE_DD_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "[0-1]{1}|"
    + "1{1}8{1}|"
    + "1{1}8{1}0{1}|"
    + "1{1}8{1}0{1}°{1}|"
    + "1{1}8{1}0{1}°{1}[EW]{1}|"
    + "1{1}8{1}0{1}\\.{1}0{0,16}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}[EW]{1}|"

    + "0{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{0,16}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"

    + "1{1}[0-7]{1}|"
    + "1{1}[0-7]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{0,16}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1})$");

  LONGITUDE_DM_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{0,8}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[EW]{1}|"

    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"

    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1})$"
  );

  LONGITUDE_DMS_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{0,4}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[EW]{1}|"

    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1}|"

    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1})$"
  );

  LONGITUDE_COMPLETE_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}[EW]{1}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"

    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"

    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1})$"
  );

  dmsCoordinate: string;

  @Input() coordinateType: string; //LATITUDE, LONGITUDE
  @Input() coordinateValue: number;
  @Input() label: string;

  constructor(public el: ElementRef<any>, public ngModel: NgModel) {
    super(el, ngModel);
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    this.initCoordinate();
  }

  handleValueChange() {
    this.dmsCoordinate = this.dmsCoordinate === undefined ? '' : this.dmsCoordinate;
    var isValidValue: boolean = false;
    if (this.coordinateType === 'LATITUDE') isValidValue = this.LATITUDE_COMPLETE_PATTERN.test(this.dmsCoordinate);
    else isValidValue = this.LONGITUDE_COMPLETE_PATTERN.test(this.dmsCoordinate);
    this.coordinateValue = isValidValue ? this.coordinateToDD(this.dmsCoordinate) : this.coordinateValue;
    if (isValidValue) {

      console.log('Valid Cordinate !');
      console.log('DD value : ' + this.coordinateValue);
      console.log('DMS value : ' + this.coordinateToDMS(this.coordinateValue));

    }
    else {
      console.log('Latitude invalide !');
    }
  }

  handleKeypress(event) {
    this.dmsCoordinate = this.dmsCoordinate === undefined ? '' : this.dmsCoordinate;
    var keypress = event.keyCode || event.which || event.charCode;
    var key = String.fromCharCode(keypress);
    var text = this.dmsCoordinate + key;
    var isValidChar: boolean = false;
    if (this.coordinateType === 'LATITUDE') isValidChar = this.LATITUDE_DD_TYPING_PATTERN.test(text) || this.LATITUDE_DM_TYPING_PATTERN.test(text) || this.LATITUDE_DMS_TYPING_PATTERN.test(text);
    else isValidChar = this.LONGITUDE_DD_TYPING_PATTERN.test(text) || this.LONGITUDE_DM_TYPING_PATTERN.test(text) || this.LONGITUDE_DMS_TYPING_PATTERN.test(text);

    if (isValidChar) {

      console.log('Latitude typing valide !');

    }
    else {
      console.log('Latitude typing invalide !');
      event.preventDefault();
    }

  }

  coordinateToDD(coordinateString: string) {

    var degreesCoordinate = coordinateString;
    var splitDeegrees = degreesCoordinate.split('°');
    var degreesString = splitDeegrees.length > 1 ? splitDeegrees[0].trim() : '';
    var positionString = splitDeegrees.length > 1 ? splitDeegrees[1].trim() : '';

    var minutesCoordinate = splitDeegrees.length > 1 && degreesString != '' ? splitDeegrees[1].trim() : '';
    var splitMinutes = minutesCoordinate.split('\'');
    var minutesString = splitMinutes.length > 1 ? splitMinutes[0].trim() : '';
    positionString = splitMinutes.length > 1 ? splitMinutes[1].trim() : positionString;

    var secondsCoordinate = splitMinutes.length > 1 && minutesString != '' ? splitMinutes[1].trim() : '';
    var splitSeconds = secondsCoordinate.split('"');
    var secondsString = splitSeconds.length > 1 ? splitSeconds[0].trim() : '';
    positionString = splitSeconds.length > 1 ? splitSeconds[1].trim() : positionString;

    var degres: number = !Number.isNaN(Number.parseFloat(degreesString)) ? Number.parseFloat(degreesString) : 0.0;
    var minutes: number = !Number.isNaN(Number.parseFloat(minutesString)) ? Number.parseFloat(minutesString) : 0.0;
    var seconds: number = !Number.isNaN(Number.parseFloat(secondsString)) ? Number.parseFloat(secondsString) : 0.0;

    var coordinateDD = degres + (minutes / 60.0) + (seconds / 3600.0);

    var coordinateDDValue = positionString === 'S' || positionString === 'W' ? -coordinateDD : coordinateDD;

    return Number.parseFloat(coordinateDDValue.toFixed(16));

  }

  coordinateToDMS(coordinate: number) {
    var degreesDD = Math.abs(coordinate);
    var maxValue = this.coordinateType === 'LATITUDE' ? 90 : 180;
    var validCoordinateRange: boolean = !Number.isNaN(degreesDD) && degreesDD <= maxValue;

    var positivePosition = this.coordinateType === 'LATITUDE' ? 'N' : 'E';
    var negativePosition = this.coordinateType === 'LATITUDE' ? 'S' : 'W';
    var position = validCoordinateRange ? (coordinate < 0 ? negativePosition : positivePosition) : '';

    var degreesValue = validCoordinateRange ? Math.floor(degreesDD) : 0;
    var minutesMD = validCoordinateRange ? (degreesDD - degreesValue) * 60.0 : 0.0;
    var minutesValue = validCoordinateRange ? Math.floor(minutesMD) : 0;
    var secondsSD = validCoordinateRange ? (minutesMD - minutesValue) * 60.0 : 0.0;
    secondsSD = validCoordinateRange ? Number.parseFloat((secondsSD).toFixed(4)) : 0.0;

    var scondsValue = validCoordinateRange ? Math.floor(secondsSD) : 0;
    var resteSD = validCoordinateRange ? Number.parseFloat((secondsSD - scondsValue).toFixed(4)) : 0;
    resteSD = validCoordinateRange ? Math.abs(resteSD) * 10000.0 : 0;
    var resteValue = validCoordinateRange ? Math.floor(resteSD) : 0;
   
    var degreesString = this.coordinateType === 'LATITUDE' ? '0' + Math.abs(degreesValue) : '00' + Math.abs(degreesValue);
    var minutesString = this.coordinateType === 'LATITUDE' ? '0' + Math.abs(minutesValue) : '0' + Math.abs(minutesValue);
    var secondsString = this.coordinateType === 'LATITUDE' ? '0' + Math.abs(scondsValue) : '0' + Math.abs(scondsValue);
    var restString = resteValue  > 0 ? '.' + resteValue : '';

    degreesString = this.coordinateType === 'LATITUDE' ? degreesString.substring(degreesString.length - 2) : degreesString.substring(degreesString.length - 3);
    minutesString = this.coordinateType === 'LATITUDE' ? minutesString.substring(minutesString.length - 2) : minutesString.substring(minutesString.length - 2);
    secondsString = this.coordinateType === 'LATITUDE' ? secondsString.substring(secondsString.length - 2) : secondsString.substring(secondsString.length - 2);
    secondsString = secondsString + restString;


    return validCoordinateRange ? degreesString + '°' + minutesString + '\'' + secondsString + '"' + position : '';

  }

  initCoordinate(){
    this.coordinateValue = this.coordinateValue != undefined ? Number.parseFloat(this.coordinateValue.toFixed(16)) : this.coordinateValue;
    this.dmsCoordinate = this.coordinateValue != undefined ? this.coordinateToDMS(this.coordinateValue) : this.dmsCoordinate;
    console.log(this.dmsCoordinate);
  }


  public getValue(){
    return this.dmsCoordinate;
  }

  public setValue(value){
    this.dmsCoordinate = value;

  }

}
