import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppComponent } from '../app.component';
import { MenuItem } from 'primeng/api';
import { Translatable } from '../shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../shared/services/locale.service';

@Component({
    selector: 'app-megamenu',
    templateUrl: './app.megamenu.component.html',
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMegamenuComponent extends Translatable implements OnInit, OnDestroy {

    megaMenuItems: MenuItem[];

    tieredItems: MenuItem[];

    labels = {
        Title: 'Add or edit Occurrence Details',
        GeneralData: 'General data',
        WeatherReports: 'Weather reports',
        EventClassification: 'Event Classification',
        Airspaces: 'Airspaces',
        Aircraft: 'Aircraft',
        AerodromeData: 'Aerodrome data',
        DamageAndInjuries: 'Damage and injuries',
        ReportingHistory: 'Reporting history'
    };

    constructor(
        public app: AppComponent,
        public translate: TranslateService,
        public localeService: LocaleService) {
        super('AppMegamenuComponent');
    }

    ngOnInit() {
        this.subscribeToLocale();
    }

    ngOnDestroy(): void {
        this.localeSubscription.unsubscribe();
    }


}
