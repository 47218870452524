
export class CoreLicenceRequest{
	constructor(
		public validityDays?: number,
		public intendedUsers?: number,
		public assignementType?: number,
		public products?: string[],
		public licenceClaims?: any
	) { }

}