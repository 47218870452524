import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { MenuItem, Message, ConfirmationService } from 'primeng/api';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-licence-client',
  templateUrl: './licence-client.component.html',
  styleUrls: ['./licence-client.component.css']
})
export class LicenceClientComponent implements OnInit {

	ROUTE_LICENCE_GENERATE = '/security/licence-generate';

	labels = {
		Generate: 'Generate',
		GenerateTooltip: 'Generate licence',
		Download: 'Download',
		DownloadTooltip: 'Download licence',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected licence',
		Licences: 'Licences',
		LoadFailedMessage: 'Fail to load licences !',
		DownloadFailedMessage: 'Fail to load licences !',
		DeleteSelectedLicence: 'Delete selected licence ?'
	};


	selectedLicence: any;

	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyTabOpened = false;

	licences: [];

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public confirmationService: ConfirmationService,
		public fileLoadingService: FileLoadingService,
		public app: AppComponent
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadLicences();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}

	onTabOpen(e) {
		this.tabIndex = e.index;
		if (!this.anyTabOpened) {
			this.anyTabOpened = true;
			this.initMenuItems();
		}
	}
	
	initMenuItems() {
		this.menuItems = [
			{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyTabOpened, command: () => { this.confirmDelete(); } },
			{ label: this.labels.Download, icon: 'pi pi-plus', title: this.labels.DownloadTooltip, disabled: !this.anyTabOpened, command: () => { this.onDownload(); }},
			{ label: this.labels.Generate, icon: 'pi pi-plus', title: this.labels.GenerateTooltip, command: () => { this.onGenerate(); } }
		];
	}

	getItemStyle(disabled) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onGenerate() {
		this.router.navigate([this.ROUTE_LICENCE_GENERATE]);
	}

	onDownload(){
		this.setSelectedLicence();
		const route = HttpMapping.CLIENT_LICENCE_GET_DOWNLOAD.replace('{id}', this.selectedLicence.id);
		this.fileLoadingService.downloadFile(route, RepositoryService.SERVICE_SECURITY).subscribe((data) => {	
			let fileContent = new Blob([data.body], { type: data.headers.get('Content-Type') });
			let	fileName =  data.headers.get('File-Name');
			saveAs(fileContent, fileName); 
		}, err => {
			this.app.showError(this.labels.DownloadFailedMessage);
		});
	}

	confirmDelete() {
		this.setSelectedLicence();
		if (this.selectedLicence != null) {
			this.confirmationService.confirm({
				message: this.labels.DeleteSelectedLicence,
				accept: () => {
					this.onDelete();
				}
			});
		}

	}

	onDelete() {
		let route = HttpMapping.CLIENT_LICENCE_DELETE_BY_ID.replace('{id}', this.selectedLicence.id);
		this.repository.delete(route, RepositoryService.SERVICE_SECURITY).subscribe(
			() => this.onLoadLicences()
		);
	}

	onLoadLicences() {
		let route = HttpMapping.CLIENT_LICENCE_GET_DISPLAY;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((data: []) => {
			this.licences = data;
		}, err => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

	setSelectedLicence() {
		if (this.licences[this.tabIndex] != null) {
			this.selectedLicence = this.licences[this.tabIndex];
		}
	}

}
