import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AerodromeCreateComponent } from './aerodrome-create/aerodrome-create.component';
import { AerodromeViewComponent } from './aerodrome-view/aerodrome-view.component';
import { AerodromeUpdateComponent } from './aerodrome-update/aerodrome-update.component';
import { RunwayViewComponent } from './runway-view/runway-view.component';
import { RunwayCreateComponent } from './runway-create/runway-create.component';
import { RunwayUpdateComponent } from './runway-update/runway-update.component';

const routes: Routes = [
	{
		path: 'aerodrome-create',
		component: AerodromeCreateComponent
	},
	{
		path: 'aerodrome-view',
		component: AerodromeViewComponent
	},
	{
		path: 'aerodrome-update/:id',
		component: AerodromeUpdateComponent
	},
	{
		path: 'runway-view',
		component: RunwayViewComponent
	},
	{
		path: 'runway-create/:adId',
		component: RunwayCreateComponent
	},
	{
		path: 'runway-update/:adId/:rwyId',
		component: RunwayUpdateComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AerodromeRoutingModule { }
