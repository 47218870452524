import { Pipe, PipeTransform } from '@angular/core';
import { CoordinatesConvertorService } from '../services/coordinates-convertor.service';

@Pipe({
  name: 'longitudeDMS'
})
export class LongitudePipe implements PipeTransform {

  transform(value: number, args?: any): string {
	let convertor = new CoordinatesConvertorService();
    return convertor.coordinateToDMS(value, 'LONGITUDE');
  }

}
