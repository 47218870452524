import { Injectable } from '@angular/core';
//import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentUrlService {

  //coreUrlAddress: string = environment.coreUrlAddress;
  //olsatUrlAddress: string = environment.olsatUrlAddress;

  // Read environment variables from browser window
   browserWindow = window || {};
   browserWindowEnv = this.browserWindow['__env'] || {};

   coreUrlAddress: string = this.browserWindowEnv.coreUrlAddress;

  constructor() { }
}
