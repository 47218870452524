import { Component, OnInit } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { ConfirmationService, Message, MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-aerodrome-view',
  templateUrl: './aerodrome-view.component.html',
  styleUrls: ['./aerodrome-view.component.css']
})
export class AerodromeViewComponent implements OnInit {

  
	//navigation routes
	ROUTE_AERODROME_CREATE = '/aerodrome/aerodrome-create';
	ROUTE_AERODROME_UPDATE = '/aerodrome/aerodrome-update';

	labels = {
		Add: 'Add',
		AddTooltip: 'Add aerodrome',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected aerodrome',
		DeleteSelectedAerodrome: 'Delete selected Aerodrome ?',
		Edit: 'Edit',
		EditTooltip: 'Edit selected aerodrome',
		AerodromeHeloports: 'Aerodromes & Heliports',		
		LocationIndicator: 'Location Indicator',		
		ID: 'ID',
		Name: 'Name',
		Type: 'Type',
		ServecCity: 'Served city',
		AerodromesTable: 'Aerodromes table',
		LoadFailedMessage: 'Failed to load Aerodromes',
		DeleteFailedMessage: 'Failed to delete Aerodrome'
	};

	columns: any = [{ 'field': 'id', 'header': this.labels.ID, 'display': 'none' },
	{ 'field': 'locationIndicatorICAO', 'header': this.labels.LocationIndicator, 'display': 'table-cell' },
	{ 'field': 'name', 'header': this.labels.Name, 'display': 'table-cell' },
	{ 'field': 'servedCity', 'header': this.labels.ServecCity, 'display': 'table-cell' }];

	aerodromes: AirportHeliport[];
	selectedAerodrome: any;

	msgs: Message[] = [];
	menuItems: MenuItem[];
	anyRowSelected = false;



	constructor(
		public repository: RepositoryService, 
		public router: Router, 
		public app: AppComponent,
		public confirmationService: ConfirmationService
		) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadObjects();
	}

	onRowSelect() {
		if (!this.anyRowSelected) {
			this.anyRowSelected = true;
			this.initMenuItems();
		}
	}

	initMenuItems() {
		this.menuItems = [{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyRowSelected, command: () => { this.confirmDelete(); } },
		{ label: this.labels.Edit, icon: 'pi pi-pencil', title: this.labels.EditTooltip, disabled: !this.anyRowSelected, command: () => { this.onEdit(); } },
		{ label: this.labels.Add, icon: 'pi pi-plus', title: this.labels.AddTooltip, command: () => { this.onAdd(); } }];
	}

	getItemStyle(disabled: any) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onEdit() {
		this.router.navigate([this.ROUTE_AERODROME_UPDATE, this.selectedAerodrome.id]);
	}

	onAdd() {
		this.router.navigate([this.ROUTE_AERODROME_CREATE]);
	}

	confirmDelete() {
		this.confirmationService.confirm({
			message: this.labels.DeleteSelectedAerodrome,
			accept: () => {
				this.onDelete();
			}
		});
	}

	onDelete() {
		let route = HttpMapping.AIRPORT_HELIPORT_DELETE_BY_ID.replace('{id}', this.selectedAerodrome.id);;
		this.repository.delete(route, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
			this.onLoadObjects()
		}, _error => {
			this.app.showError(this.labels.DeleteFailedMessage);
		});
	}

	onLoadObjects() {
		let route = HttpMapping.AIRPORT_HELIPORT_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_AVAIRS).subscribe((data: AirportHeliport[]) => {
			this.aerodromes = data
		}, _error => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

}
