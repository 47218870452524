import { Pipe, PipeTransform } from '@angular/core';
import { CoordinatesConvertorService } from '../services/coordinates-convertor.service';

@Pipe({
  name: 'latitudeDMS'
})
export class LatitudePipe implements PipeTransform {

  transform(value: number, args?: any): string {
	let convertor = new CoordinatesConvertorService();
    return convertor.coordinateToDMS(value, 'LATITUDE');
  }

}
