import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-licence-view',
  templateUrl: './licence-view.component.html',
  styleUrls: ['./licence-view.component.css']
})
export class LicenceViewComponent implements OnInit {

	ROUTE_LICENCE_ADD = '/security/licence-add';
	ROUTE_LICENCE_ASSIGN = '/security/licence-assign';
	ROUTE_LICENCE_REQUEST = '/security/licence-request';

	labels = {
		Add: 'Add',
		AddTooltip: 'Add licence',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected licence',
		Assign: 'Assign',
		AssignTooltip: 'Grant or revoke selected licence',
		Request: 'Request',
		RequestTooltip: 'Generate licence request',
		Licences: 'Licences',
		ValidLicences: 'Valid Licences',
		LoadFailedMessage: 'Fail to loade licences !',
		DeleteSelectedLicence: 'Delete selected licence ?',
		LicenceNotAssigned: 'Licence not assigned to any user'
	};


	selectedLicence: any;

	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyLicenceSelected = false;
	anyTabOpened = false;

	licences = [];

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public confirmationService: ConfirmationService,
		public fileLoadingService: FileLoadingService,
		public app: AppComponent
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadLicences();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}

	onRowSelect() {
		if (!this.anyLicenceSelected) {
			this.anyLicenceSelected = true;
			this.initMenuItems();
		}
	}

	onLicenceSelect(licence: any) {
		this.selectedLicence = licence;
		if (!this.anyLicenceSelected) {
			this.anyLicenceSelected = true;
			this.initMenuItems();
		}
	}

	initMenuItems() {
		this.menuItems = [{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyLicenceSelected, command: () => { this.confirmDelete(); } },
		{ label: this.labels.Assign, icon: 'pi pi-sign-in', title: this.labels.AssignTooltip, disabled: !this.anyLicenceSelected, command: () => { this.onAssign(); } },
		{ label: this.labels.Request, icon: 'pi pi-pencil', title: this.labels.RequestTooltip, command: () => { this.onRequest(); } },
		{ label: this.labels.Add, icon: 'pi pi-plus', title: this.labels.AddTooltip, command: () => { this.onAdd(); } }];
	}

	getItemStyle(disabled) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onRequest() {
		this.router.navigate([this.ROUTE_LICENCE_REQUEST]);
	}

	onAdd() {
		this.router.navigate([this.ROUTE_LICENCE_ADD]);
	}

	confirmDelete() {
		this.setSelectedLicence();
		if (this.selectedLicence != null) {
			this.confirmationService.confirm({
				message: this.labels.DeleteSelectedLicence,
				accept: () => {
					this.onDelete();
				}
			});
		}

	}

	onAssign(){
		if (this.selectedLicence != null) {
			this.router.navigate([this.ROUTE_LICENCE_ASSIGN, this.selectedLicence.id]);
		}		
	}

	onDelete() {
		let route = HttpMapping.CORE_LICENCE_DELETE_BY_ID.replace('{id}', this.selectedLicence.id);
		this.repository.delete(route, RepositoryService.SERVICE_SECURITY).subscribe(
			() => this.onLoadLicences()
		);
	}

	onLoadLicences() {
		let route = HttpMapping.CORE_LICENCE_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((data: []) => {
			this.licences = data;
			this.selectedLicence = this.licences.length > 0 ? this.licences[0]: null;
		}, err => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

	setSelectedLicence() {
		if (this.licences[this.tabIndex] != null) {
			this.selectedLicence = this.licences[this.tabIndex];
		}
	}

	onTabOpen(e) {
		this.tabIndex = e.index;
		if (!this.anyTabOpened) {
			this.anyTabOpened = true;
			this.initMenuItems();
		}
	}


}
