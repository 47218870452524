import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreCredentials } from '../entities/credentials';
import { AppComponent } from 'src/app/app.component';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { CoreUser } from '../entities/core-user';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {


	ROUTE_MAIN = '/main'

	userForm: FormGroup;

	coreCredentials: CoreCredentials;
	rememberMe = true;

	labels = {
		Welcome: 'Welcome, please use the form to sign-in network.',
		Login: 'Login',
		Password: 'Password',
		UsernameEmail: 'Username or Email',
		RememberMe: 'Remember me',
		SignIn: 'Sign in',
		SignInFailedMessage: 'Failed to sign in with given credentials !'
	};

	constructor(
		public authenticationService: AuthenticationService,
		public repository: RepositoryService,
		public localeService: LocaleService,
		public themeService: ThemeService,
		public router: Router,
		public fb: FormBuilder,
		public app: AppComponent
	) { }

	ngOnInit() {
		this.initUserForm();
	}

	ngAfterViewInit(): void {
		this.app.hideStaticMenu();
	}

	initUserForm() {
		this.userForm = this.fb.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
			remember: [true]
		});
	}

	onLogin() {
		if (this.userForm.valid) {
			this.proceedLogin();
		}
	}

	proceedLogin() {
		this.coreCredentials = new CoreCredentials(
			this.userForm.value['username'],
			this.userForm.value['password']
		);

		this.rememberMe = this.userForm.value['remember'];
		this.authenticationService.login(this.coreCredentials).subscribe(resp => {
			this.authenticationService.handleSignedIn(resp, this.rememberMe);
			this.loadLocaleFromUserprofile();
			this.app.showStaticMenu();
			this.router.navigate([this.ROUTE_MAIN]);
		}, _error => {
			this.app.showError(this.labels.SignInFailedMessage);
		});
	}

	loadLocaleFromUserprofile(){
		var username = this.coreCredentials.username;
		let route = HttpMapping.CORE_USER_GET_BY_USERNAME.replace('{username}', username);
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((user: CoreUser) => {
			 
			//use the current user profile locale, if any 
			if(user.userProfile[this.localeService.USER_PROFILE_LOCALE]){
				var locale = user.userProfile[this.localeService.USER_PROFILE_LOCALE];
				this.localeService.setLocale(locale);
			}

			//use the current user profile theme, if any 
			if(user.userProfile[this.themeService.USER_PROFILE_THEME]){
				var theme = user.userProfile[this.themeService.USER_PROFILE_THEME];
				this.themeService.changeTheme(theme);
			}
		});
	}

}
