import { CoreOrganization } from "./core-organization";
import { CoreRole } from "./core-role";

export class CoreUser {

	constructor(
		public id?: string,
		public activated?: number,
		public address?: string,
		public email?: string,
		public firstname?: string,
		public lastname?: string,
		public password?: string,
		public phone?: string,
		public title?: string,
		public username?: string,
		public userProfile?: Map<string, string>,
		public coreOrganization?: CoreOrganization,
		public coreRoles?: CoreRole[]
	) { }

}