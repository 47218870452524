import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OrganizationViewComponent } from './organization-view/organization-view.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './organization-update/organization-update.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { LicenceViewComponent } from './licence-view/licence-view.component';
import { LicenceAddComponent } from './licence-add/licence-add.component';
import { LicenceRequestComponent } from './licence-request/licence-request.component';
import { LicenceClientComponent } from './licence-client/licence-client.component';
import { LicenceGenerateComponent } from './licence-generate/licence-generate.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { LicenceUserComponent } from './licence-user/licence-user.component';
import { LicenceAssignComponent } from './licence-assign/licence-assign.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'password-change',
		component: PasswordChangeComponent
	},
	{
		path: 'organization-view',
		component: OrganizationViewComponent
	},
	{
		path: 'organization-create',
		component: OrganizationCreateComponent
	},
	{
		path: 'organization-update/:id',
		component: OrganizationUpdateComponent
	},
	{
		path: 'user-view',
		component: UserViewComponent
	},
	{
		path: 'user-create',
		component: UserCreateComponent
	},
	{
		path: 'user-update/:id',
		component: UserUpdateComponent
	},
	{
		path: 'licence-view',
		component: LicenceViewComponent
	},
	{
		path: 'licence-add',
		component: LicenceAddComponent
	},
	{
		path: 'licence-request',
		component: LicenceRequestComponent
	},
	{
		path: 'licence-client',
		component: LicenceClientComponent
	}
	,
	{
		path: 'licence-generate',
		component: LicenceGenerateComponent
	},
	{
		path: 'licence-user',
		component: LicenceUserComponent
	},
	{
		path: 'licence-assign/:id',
		component: LicenceAssignComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SecurityRoutingModule { }
