import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uuidParts'
})
export class UuidPartsPipe implements PipeTransform {

  transform(value: string, args?: number): string {
	  let parts = value ? value.split('-'): [];
	  let res = parts && parts.length > 0 ? parts[0] : value;
	  if(args && args > 0){
		  let index = 1;
		  while(index < args && index < parts.length){
			  res = res + '-' + parts[index];
			  index = index+1;
		  }
	  }
    return res;
  }

}
