import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpMapping } from '../constants/http-mapping';
import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Authentication } from 'src/app/security/entities/authentication';
import { CoreCredentials } from 'src/app/security/entities/credentials';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	protected backendServer = ConfigService.settings.backendServer;

	authentication = new Authentication(false, [], false, false, false, false, null);
	authenticationSubjct = new Subject<Authentication>();

	jwtHelperService: JwtHelperService;

	HEADER_AUTHORIZATION: string = "authorization";
	STORAGE_ITEM_TOKEN: string = "token";	
	OBSERVE_RESPONSE: string = "response";

	constructor(
		private http: HttpClient,
		
	) {
		this.jwtHelperService = new JwtHelperService();
		this.checkAuthentication();
	}


	emitAuthenticationSubjctSubjct() {
		this.authenticationSubjct.next(this.authentication);
	}

	login(coreCredentials: CoreCredentials) {
		this.removeToken();
		let route = HttpMapping.LOGIN_URL;				
		return this.proceedToLogin(route, coreCredentials);
	}

	handleSignedIn(resp: HttpResponse<Object>, remember: boolean) {
		let jwtToken = resp.headers.get(this.HEADER_AUTHORIZATION);
		if (remember) this.saveToken(jwtToken);
		this.parseToken(jwtToken);
	}

	logout() {
		this.removeToken();
		this.authentication = new Authentication(false, [], false, false, false, false, null, null);
		this.emitAuthenticationSubjctSubjct();
	}

	parseToken(token) {
		if (token) {
			if (this.isTockenPasrsable(token) && !this.jwtHelperService.isTokenExpired(token)) {
				this.authentication = new Authentication();
				this.authentication.isAuthenticated = true;
				let roles = this.jwtHelperService.decodeToken(token).roles;
				this.authentication.subject = this.jwtHelperService.decodeToken(token).sub;

				this.authentication.userRoles = [];
				if (roles) {
					roles.forEach(role => {
						this.authentication.userRoles.push(role.authority);
					});
				}

				this.authentication.hasSysRole = this.authentication.userRoles.includes('ROLE_SYS') ? true : false;
				this.authentication.hasAdminRole = this.authentication.userRoles.includes('ROLE_ADMIN') ? true : false;
				this.authentication.hasSupRole = this.authentication.userRoles.includes('ROLE_SUP') ? true : false;
				this.authentication.hasOprRole = this.authentication.userRoles.includes('ROLE_OPR') ? true : false;
				this.authentication.token = token;				

				this.emitAuthenticationSubjctSubjct();

			}
		}
	}

	isTockenPasrsable(token: any){
		try{
			this.jwtHelperService.decodeToken(token);
			return true;
		}catch{
			return false;
		}
	}


	checkAuthentication() {
		this.parseToken(this.loadToken());
	}

	saveToken(token) {
		localStorage.setItem(this.STORAGE_ITEM_TOKEN, token);
	}


	loadToken() {
		return localStorage.getItem(this.STORAGE_ITEM_TOKEN);
	}

	removeToken() {
		localStorage.removeItem(this.STORAGE_ITEM_TOKEN);
	}

	public proceedToLogin(route: string, user) {
		return this.http.post(this.createCompleteRoute(route, this.backendServer.securityServer), user, { observe: 'response' });
	}

	private createCompleteRoute(route: string, envAddress: string) {
		return `${envAddress}${route}`;
	}
}
