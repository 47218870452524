import { RunwayCentrelinePoint } from "./runway-centerline-point";
import { RunwayProtectArea } from "./runway-protect-area";

export class RunwayDirection {
    id: string;
	designator: string;
	trueBearing: number;
	trueBearingAccuracy: number;
	magneticBearing: number;
	patternVFR: string;
	slopeTDZ: number;
	elevationTDZ: number;
	elevationTDZAccuracy: number;
	approachMarkingType: string;
	approachMarkingCondition: string;
	classLightingJAR: string;
	precisionApproachGuidance: string;
	startingElement: string;
	annotation: string;
	availability: string;
	runwayCentrelinePoints: RunwayCentrelinePoint[];
	runwayProtectAreas: RunwayProtectArea[];
	approachType: string;
	precisionApproachCategoryType: string;

}