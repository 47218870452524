import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AerodromeRoutingModule } from './aerodrome-routing.module';
import { AerodromeViewComponent } from './aerodrome-view/aerodrome-view.component';
import { AerodromeCreateComponent } from './aerodrome-create/aerodrome-create.component';
import { AerodromeUpdateComponent } from './aerodrome-update/aerodrome-update.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MenubarModule, DropdownModule, ContextMenuModule, ConfirmDialogModule, InputTextModule, ButtonModule, PanelModule, TooltipModule, ColorPickerModule, AccordionModule, FieldsetModule, TabViewModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { GisModule } from '../gis/gis.module';
import { HttpClientModule } from '@angular/common/http';
import { RunwayViewComponent } from './runway-view/runway-view.component';
import { RunwayCreateComponent } from './runway-create/runway-create.component';
import { RunwayUpdateComponent } from './runway-update/runway-update.component';

@NgModule({
  declarations: [
	  AerodromeViewComponent, 
	  AerodromeCreateComponent, 
	  AerodromeUpdateComponent, 
	  RunwayViewComponent, 
	  RunwayCreateComponent, 
	  RunwayUpdateComponent
	],
  imports: [
    CommonModule,
	AerodromeRoutingModule,
	SharedModule,
    ReactiveFormsModule,
    CommonModule,
    MenubarModule,
    FormsModule,
    DropdownModule,
    TableModule,
    ContextMenuModule,
    ConfirmDialogModule,
    GisModule,
    InputTextModule,
    HttpClientModule,
    ButtonModule,
	PanelModule,
	TooltipModule,
	ColorPickerModule,
	AccordionModule,
	FieldsetModule,
	TabViewModule
  ]
})
export class AerodromeModule { }
