import { Component, OnInit } from '@angular/core';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { GisElevatedPoint } from 'src/app/shared/entities/gis-elevated-point';
import { City } from 'src/app/shared/entities/city';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CoordinatesRegex } from 'src/app/shared/constants/coordinates-regex';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CoordinatesConvertorService } from 'src/app/shared/services/coordinates-convertor.service';
import { SelectItem, Message } from 'primeng/api';

@Component({
	selector: 'app-aerodrome-create',
	templateUrl: './aerodrome-create.component.html',
	styleUrls: ['./aerodrome-create.component.css']
})
export class AerodromeCreateComponent implements OnInit {

	aerodromeForm: FormGroup;
	aerodrome: AirportHeliport;
	
	//navigation routes
	ROUTE_AERODROME_VIEW = '/aerodrome/aerodrome-view';

	testCoordinate: any;

	arpLatitude: any = '';
	arpLongitude: any = '';

	arp: GisElevatedPoint;
	servedCity: City;

	msgs: Message[] = [];

	labels = {
		Title: 'Ceate Aerodrome',
		GeneralData: 'General data',
		ARPData: 'ARP data',
		LocationIndicator: 'Location indicator',
		Designator: 'Designator',
		Name: 'Name',
		ARPLatitude: 'Latitude',
		ARPLongitude: 'Longitude',
		ARPElevation: 'Elevation (M)',
		Cancel: 'Cancel',
		Save: 'Save',
		EditAerodrome: 'Edit Aerodrome',
		AddAerodrome: 'Add Aerodrome',
		ServedCity: 'Served City',
		OLSColors: 'OLS Colors',
		SurfaceOLSType: 'Surface OLS Type',
		SurfaceColor: 'Surface Color',
		AddOLSColor: 'Add OLS Color',
		RemoveOLSColor: 'X',
		SaveFailedMessage: 'Failed to save aerodrome !',
		SaveOkMessage: 'Aerodrome saved successfully !'
	};

	surfacesOLSTypes: SelectItem[] = [
		{ label: 'Other', value: 'OTHER' },
		{ label: 'Outer Horizontal', value: 'OUTER_HORIZONTAL_SURFACE' },
		{ label: 'Conical', value: 'CONICAL_SURFACE' },
		{ label: 'Inner Horizontal', value: 'INNER_HORIZONTAL_SURFACE' },
		{ label: 'Approach', value: 'APPROACH_SURFACE' },
		{ label: 'Inner Approach', value: 'INNER_APPROACH_SURFACE' },
		{ label: 'Transitional', value: 'TRANSITIONAL_SURFACE' },
		{ label: 'Inner Transitional', value: 'INNER_TRANSITIONAL_SURFACE' },
		{ label: 'Balked Landing', value: 'BALKED_LANDING_SURFACE' },
		{ label: 'Takeoff Climb', value: 'TAKE_OFF_CLIMB_SURFACE' }
	];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent,
		public convertor: CoordinatesConvertorService
	) { }

	ngOnInit() {
		this.initAerodromeForm();
	}

	initAerodromeForm() {
		this.aerodromeForm = this.fb.group({
			name: ['', [Validators.required]],
			designator: ['', [Validators.required]],
			locationIndicatorICAO: ['', [Validators.required]],
			type: ['Aerodrome'],
			servedCityName: [''],
			arp: this.fb.group({
				latitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LATITUDE_COMPLETE_PATTERN)]],
				longitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LONGITUDE_COMPLETE_PATTERN)]],
				baseElevation: ['', [Validators.required]],
				topElevation: ['']
			})
		});
	}

	onCancel() {
		this.router.navigateByUrl(this.ROUTE_AERODROME_VIEW);
	}

	onCreate() {
		if (this.aerodromeForm.valid) {
			this.executeCreate();
		}
	}

	executeCreate() {
		this.aerodrome = new AirportHeliport();
		this.aerodrome.name = this.aerodromeForm.value['name'];
		this.aerodrome.designator = this.aerodromeForm.value['designator'];
		this.aerodrome.locationIndicatorICAO = this.aerodromeForm.value['locationIndicatorICAO'];
		this.aerodrome.type = this.aerodromeForm.value['type'];
		this.aerodrome.servedCity = new City(this.aerodromeForm.value['servedCityName']);
		this.aerodrome.arp = new GisElevatedPoint(
			this.convertor.coordinateToDD(this.aerodromeForm.value['arp'].latitude),
			this.convertor.coordinateToDD(this.aerodromeForm.value['arp'].longitude),
			this.aerodromeForm.value['arp'].baseElevation,
			this.aerodromeForm.value['arp'].baseElevation
		);

		let route = HttpMapping.AIRPORT_HELIPORT_POST;
		this.repository.create(route, this.aerodrome, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
			this.router.navigate([this.ROUTE_AERODROME_VIEW]);
			this.app.showInfo(this.labels.SaveOkMessage);
		}, _error => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}


}
