import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LocaleService implements OnInit {

	STORAGE_ITEM_USER_PROFILE_LOCALE: string = 'locale';
	USER_PROFILE_LOCALE = 'USER_PROFILE_LOCALE';

	private locale = 'fr';
	localeSubject = new Subject<string>();


	constructor() {
		this.loadLocale();
	}

	ngOnInit() {
	}


	emitLocaleSubject() {
		this.localeSubject.next(this.locale);
	}

	getLocale() {
		return this.locale;
	}

	setLocale(locale: string) {
		this.locale = locale;
		this.saveLocale();
		this.emitLocaleSubject();
	}

	saveLocale() {
		localStorage.setItem(this.STORAGE_ITEM_USER_PROFILE_LOCALE, this.locale);
	}

	loadLocale(){
		var loc = localStorage.getItem(this.STORAGE_ITEM_USER_PROFILE_LOCALE);
		if(loc) this.locale = loc;
	}

}
