import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { Runway } from 'src/app/shared/entities/runway';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-runway-view',
  templateUrl: './runway-view.component.html',
  styleUrls: ['./runway-view.component.css']
})
export class RunwayViewComponent implements OnInit {

	//navigation routes
	ROUTE_RUNWAY_CREATE = '/aerodrome/runway-create';
	ROUTE_RUNWAY_UPDATE = '/aerodrome/runway-update';

	labels = {
		Add: 'Add',
		AddTooltip: 'Add runway',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected runway',
		Edit: 'Edit',
		EditTooltip: 'Edit selected runway',
		AerodromesRunways: 'Aerodromes Runways',
		Runways: 'Runways on this aerodrome',
		DeleteSelectedRunway: 'Delete selected Runway ?',
		Aerodrome: 'Aerodrome',
		ID: 'ID',
		Designator: 'Designator',
		Length: 'Length (M)',
		Width: 'Width (M)',
		LengthStrip: 'Strip length (M)',
		WidthStrip: 'Strip width (M)',
		LoadFailedMessage: 'Failed to load Runways',
		DeleteFailedMessage: 'Failed to delete Runway'
	};

	columns: any = [{ 'field': 'id', 'header': this.labels.ID, 'display': 'none' },
	{ 'field': 'designator', 'header': this.labels.Designator, 'display': 'table-cell' },
	{ 'field': 'nominalLength', 'header': this.labels.Length, 'display': 'table-cell' },
	{ 'field': 'nominalWidth', 'header': this.labels.Width, 'display': 'table-cell' },
	{ 'field': 'lengthStrip', 'header': this.labels.LengthStrip, 'display': 'table-cell' },
	{ 'field': 'widthStrip', 'header': this.labels.WidthStrip, 'display': 'table-cell' }];


	selectedAerodrome: AirportHeliport;
	selectedRunway: Runway;

	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyRowSelected = false;
	anyTabOpened = false;

	aerodromes: AirportHeliport[];

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public app: AppComponent,
		public confirmationService: ConfirmationService
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadObjects();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}

	onRowSelect() {
		if (!this.anyRowSelected) {
			this.anyRowSelected = true;
			this.initMenuItems();
		}
	}

	initMenuItems() {
		this.menuItems = [{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyRowSelected, command: () => { this.confirmDelete(); } },
		{ label: this.labels.Edit, icon: 'pi pi-pencil', title: this.labels.EditTooltip, disabled: !this.anyRowSelected, command: () => { this.onEdit(); } },
		{ label: this.labels.Add, icon: 'pi pi-plus', title: this.labels.AddTooltip, command: () => { this.onAdd(); } }];
	}

	getItemStyle(disabled: any) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onEdit() {
		this.setSelectedAerodrome();
		if (this.selectedAerodrome != null) {
			if (this.selectedRunway != null) {
				this.router.navigate([this.ROUTE_RUNWAY_UPDATE, this.selectedAerodrome.id, this.selectedRunway.id]);
			} 
		}
	}

	onAdd() {
		this.setSelectedAerodrome();
		if (this.selectedAerodrome != null) {
			this.router.navigate([this.ROUTE_RUNWAY_CREATE, this.selectedAerodrome.id]);
		}
	}

	confirmDelete() {
		this.setSelectedAerodrome();
		if (this.selectedAerodrome != null && this.selectedRunway != null) {
			this.confirmationService.confirm({
				message: this.labels.DeleteSelectedRunway,
				accept: () => {
					this.onDelete();
				}
			});
		}

	}

	onDelete() {
		let route = HttpMapping.AIRPORT_HELIPORT_DELETE_REMOVE_RUNWAY.replace('{adId}', this.selectedAerodrome.id).replace('{rwyId}', this.selectedRunway.id);
		this.repository.delete(route, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
			this.onLoadObjects();
		}, _error => {
			this.app.showError(this.labels.DeleteFailedMessage);
		});
	}

	onLoadObjects() {
		let route = HttpMapping.AIRPORT_HELIPORT_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_AVAIRS).subscribe((data: AirportHeliport[]) => {
			this.aerodromes = data;
		}, _error => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

	setSelectedAerodrome() {
		if (this.aerodromes[this.tabIndex] != null) {
			this.selectedAerodrome = this.aerodromes[this.tabIndex];
		}
	}

	onTabOpen(e: any) {
		this.tabIndex = e.index;
		if (!this.anyTabOpened) {
			this.anyTabOpened = true;
			this.initMenuItems();
		}
	}
}
