import { Component, OnInit, OnDestroy } from '@angular/core';
import { Authentication } from 'src/app/security/entities/authentication';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-data-dashboard',
  templateUrl: './data-dashboard.component.html',
  styleUrls: ['./data-dashboard.component.css']
})
export class DataDashboardComponent implements OnInit, OnDestroy {

  authentication: Authentication;
  authenticationSubscription: Subscription;

  constructor(
    public authenticationService: AuthenticationService
  ) { }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  ngOnInit() {
	this.authentication = new Authentication();
    this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
      this.authentication = authentication;
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

}
