import { Component, AfterViewInit, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ScrollPanel, Message, MessageService } from 'primeng/primeng';
import { ThemeService } from './shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {


	//olsTreeModel: TreeNode[] = [];

	//esrimap: EsriMapComponent;
	//streets, satellite, hybrid, topo, gray, oceans, national-geographic, and osm

	//selectedBaseMap: SelectItem;

	/* esrimapBaseMap: SelectItem[] = [
		{ label: 'Gray Vector', value: 'gray-vector' },
		{ label: 'Hybrid', value: 'hybrid' },
		{ label: 'National Geographic', value: 'national-geographic' },
		{ label: 'Oceans', value: 'oceans' },
		{ label: 'Open Street Map', value: 'osm' },
		{ label: 'Satellite', value: 'satellite' },
		{ label: 'Streets Relief', value: 'streets-relief-vector' },
		{ label: 'Streets Vector', value: 'streets-vector' },
		{ label: 'Terrain', value: 'terrain' },
		{ label: 'Topo Vector', value: 'topo-vector' }
	]; */

	messages: Message[] = [];

	layoutMode = 'static';

	megaMenuMode = 'dark';

	menuMode = 'light';

	profileMode = 'top';//'inline';

	topbarMenuActive: boolean;

	overlayMenuActive: boolean;

	staticMenuDesktopInactive: boolean;

	staticMenuMobileActive: boolean;

	layoutMenuScroller: HTMLDivElement;

	menuClick: boolean;

	topbarItemClick: boolean;

	activeTopbarItem: any;

	resetMenu: boolean;

	rightPanelActive: boolean;

	rightPanelShow=true;

	rightPanelClick: boolean;

	megaMenuActive: boolean;

	megaMenuClick: boolean;


	@ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

	constructor(
		public renderer: Renderer2,
		public messageService: MessageService,
		public themeService: ThemeService,
		public cdRef: ChangeDetectorRef,
		public translate: TranslateService

	) {
		translate.addLangs(['en', 'fr']);
		translate.setDefaultLang('en');

	 }

	onCloseMessage() {
		this.messageService.clear('msgKey');
	}

	showInfo(infoMessage: any) {
		this.messageService.add({ key: 'msgKey', severity: 'info', summary: 'Info', detail: infoMessage });
	}

	showWarning(warningMessage: any) {
		this.messageService.add({ key: 'msgKey', severity: 'warn', summary: 'Warning', detail: warningMessage });
	}

	showError(errorMessage: any) {
		this.messageService.add({ key: 'msgKey', severity: 'error', summary: 'Error', detail: errorMessage });
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.layoutMenuScrollerViewChild) {
				this.layoutMenuScrollerViewChild.moveBar();
			}
		}, 100);
	}

	onLayoutClick() {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		if (!this.rightPanelClick) {
			this.rightPanelActive = false;
		}

		if (!this.megaMenuClick) {
			this.megaMenuActive = false;
		}

		if (!this.menuClick) {
			if (this.overlayMenuActive || this.staticMenuMobileActive) {
				this.hideOverlayMenu();
			}
		}

		this.topbarItemClick = false;
		this.menuClick = false;
		this.rightPanelClick = false;
		this.megaMenuClick = false;
	}

	onMenuButtonClick(event) {
		this.menuClick = true;
		this.topbarMenuActive = false;

		if (this.layoutMode === 'overlay') {
			this.overlayMenuActive = !this.overlayMenuActive;
		} else {
			if (this.isDesktop()) {
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			} else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}
		}

		event.preventDefault();
	}

	hideStaticMenu() {
		if (this.isDesktop()) {
			this.staticMenuDesktopInactive = true;
		} else {
			this.staticMenuMobileActive = false;
		}
		this.cdRef.detectChanges();
	}

	showStaticMenu() {
		if (this.isDesktop()) {
			this.staticMenuDesktopInactive = false;
		} else {
			this.staticMenuMobileActive = true;
		}
		this.cdRef.detectChanges();
	}

	onMenuClick() {
		this.menuClick = true;
		this.resetMenu = false;
	}

	onTopbarMenuButtonClick(event) {
		this.topbarItemClick = true;
		this.topbarMenuActive = !this.topbarMenuActive;

		this.hideOverlayMenu();

		event.preventDefault();
	}

	onTopbarItemClick(event, item) {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event.preventDefault();
	}

	onTopbarSubItemClick(event) {
		event.preventDefault();
	}

	onRightPanelButtonClick(event) {
		this.rightPanelClick = true;
		this.rightPanelActive = !this.rightPanelActive;
		event.preventDefault();
	}

	onRightPanelClick() {
		this.rightPanelClick = true;
	}

	onMegaMenuButtonClick(event) {
		this.megaMenuClick = true;
		this.megaMenuActive = !this.megaMenuActive;
		event.preventDefault();
	}

	onMegaMenuClick() {
		this.megaMenuClick = true;
	}

	hideOverlayMenu() {
		this.overlayMenuActive = false;
		this.staticMenuMobileActive = false;
	}

	isTablet() {
		const width = window.innerWidth;
		return width <= 1024 && width > 640;
	}

	isDesktop() {
		return window.innerWidth > 1024;
	}

	isMobile() {
		return window.innerWidth <= 640;
	}

	isOverlay() {
		return this.layoutMode === 'overlay';
	}
}
