import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfig } from '../interfaces/iconfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  static settings: IConfig;

  constructor(private http: HttpClient) {}


  load() {
        const jsonFile = `assets/config/config-file.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response : IConfig) => {
               ConfigService.settings = <IConfig>response;
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }

}
