import { OnTranslate } from "../interfaces/on-translate";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { LocaleService } from "../services/locale.service";

export class Translatable implements OnTranslate {


	labels: any;
	
	translate: TranslateService

	locale: string;
	localeSubscription: Subscription;
	localeService: LocaleService

	constructor(private className: string) {
		//this.subscribeToLocale();
	}

	subscribeToLocale() {
		this.localeSubscription = this.localeService.localeSubject.subscribe((locale: string) => {
			this.locale = locale;
			this.edTranslate();
		});
		this.localeService.emitLocaleSubject();
	}

	edTranslate() {
				
		var className = this.className;
		//console.log('Class name is: '+className);

		var labelsProperties = Object.getOwnPropertyNames(this.labels);

		var translatingProperties = [];
		labelsProperties.forEach(element => {
			translatingProperties.push(className + "Labels." + element);
		});

		this.translate.use(this.locale);
		this.translate.get(translatingProperties).subscribe(translated => {
			labelsProperties.forEach(element => {
				this.labels[element] = translated[className + "Labels." + element];
				
			});

			//console.log(translated);			
			
		});
		
	}
}