import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainDashboardComponent } from './dashboard/main-dashboard/main-dashboard.component';

const routes: Routes = [ 
    {
        path: '',
        component: MainDashboardComponent
    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
	},
	{
        path: 'occurrence',
        loadChildren: './occurrence/occurrence.module#OccurrenceModule'
    },
    {
        path: 'statistics',
        loadChildren: './statistics/statistics.module#StatisticsModule'
    },
	{
        path: 'security',
        loadChildren: './security/security.module#SecurityModule'
    },
    /* {
        path: 'gis',
        loadChildren: './gis/gis.module#GisModule'
    }, */
    {
        path: 'notification',
        loadChildren: './notification/notification.module#NotificationModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes),RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
