import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreUser } from '../entities/core-user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CoreOrganization } from '../entities/core-organization';
import { CoreRole } from '../entities/core-role';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { Authentication } from '../entities/authentication';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
	selector: 'app-user-create',
	templateUrl: './user-create.component.html',
	styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit, OnDestroy {

	authentication: Authentication;
	authenticationSubscription: Subscription;

	userForm: FormGroup;
	user = new CoreUser();

	//navigation routes
	ROUTE_USER_VIEW = '/security/user-view';

	labels = {
		Title: 'Create User',
		FirstName: 'First Name',
		LastName: 'Last Name',
		Username: 'Username',
		UserTitle: 'Title',
		Email: 'Email',
		Organization: 'Organization',
		UserRoles: 'User Roles',
		Activated: 'Activated',
		Address: 'Address',
		Telephone: 'Telephone',
		Save: 'Save',
		Cancel: 'Cancel',
		SaveOkMessage: 'User saved successfully !',
		SaveFailedMessage: 'Fail to save User !',
		LoadFailedMessage: 'Fail to load User !',
		LoadOrganizationsFailedMessage: 'Fail to load Organization !'
	};

	organizationItems: SelectItem[] = [];
	organizations: CoreOrganization[] = [];
	selectedOrganization: CoreOrganization;


	roletems: SelectItem[] = [];
	availableRoles: CoreRole[] = [];
	selectedRoles: CoreRole[] = [];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public authenticationService: AuthenticationService,
		public app: AppComponent
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	ngOnInit() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
		this.loadOrganizations();
		this.initUserForm();
	}

	loadOrganizations() {
		if (this.authentication.hasSysRole) {
			const route = HttpMapping.CORE_ORGANIZATION_GET_ALL;
			this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((organizations: CoreOrganization[]) => {
				this.organizations = organizations.sort((a: CoreOrganization, b: CoreOrganization) => a.name < b.name ? -1 : 1);
				this.organizationItems = [];
				this.organizationItems.push({ label: '', value: null });
				this.organizations.forEach(organization => {
					this.organizationItems.push(
						{
							label: organization.name,
							value: organization
						}
					);
				});
			}, err => {
				this.app.showError(this.labels.LoadOrganizationsFailedMessage);
			});
		} else {
			this.loadAuthenticatedUserOrganization();
		}
	}

	loadAuthenticatedUserOrganization() {
		const route = HttpMapping.CORE_USER_GET_BY_USERNAME.replace('{username}', this.authentication.subject);
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((coreUser: CoreUser) => {
			this.selectedOrganization = coreUser ? coreUser.coreOrganization : null;
			//this.availableRoles = this.selectedOrganization ? this.selectedOrganization.coreOrganizationType.coreRoles : [];
			this.availableRoles = [];
			if (this.selectedOrganization) {
				this.selectedOrganization.coreOrganizationTypes.forEach(org => {
					org.coreRoles.forEach(rol => this.availableRoles.push(rol));
				});
			}

			this.userForm.patchValue({
				coreOrganization: this.selectedOrganization
			});
		}, err => {
			this.app.showError(this.labels.LoadOrganizationsFailedMessage);
		});
	}

	initUserForm() {
		this.userForm = this.fb.group({
			activated: [true, [Validators.required]],
			address: [''],
			email: ['', [Validators.required]],
			firstname: ['', [Validators.required]],
			lastname: ['', [Validators.required]],
			phone: [''],
			title: [''],
			username: ['', [Validators.required]],
			userProfile: [null],
			coreOrganization: [this.selectedOrganization, [Validators.required]],
			coreRoles: [[], [Validators.required]]
		});

	}

	public create() {
		if (this.userForm.valid) {
			this.executeCreation();
		}

	}

	private executeCreation() {
		this.user = new CoreUser(
			null,
			this.userForm.value['activated'] ? 1 : 0,
			this.userForm.value['address'],
			this.userForm.value['email'],
			this.userForm.value['firstname'],
			this.userForm.value['lastname'],
			null,
			this.userForm.value['phone'],
			this.userForm.value['title'],
			this.userForm.value['username'],
			null,
			this.userForm.value['coreOrganization'] ? this.userForm.value['coreOrganization'] : this.selectedOrganization,
			this.getSelectedRoles()
		);


		const route = HttpMapping.CORE_USER_POST;
		this.repository.create(route, this.user, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.app.showInfo(this.labels.SaveOkMessage);
			this.router.navigate([this.ROUTE_USER_VIEW]);
		}, err => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}

	onOrganizationChange() {
		this.selectedOrganization = this.userForm.value['coreOrganization'];
		//this.availableRoles = this.selectedOrganization ? this.selectedOrganization.coreOrganizationType.coreRoles : [];
		this.availableRoles = [];
		if (this.selectedOrganization) {
			this.selectedOrganization.coreOrganizationTypes.forEach(org => {
				org.coreRoles.forEach(rol => this.availableRoles.push(rol));
			});
		}
	}

	getSelectedRoles() {
		var userRoles = [];
		var selection = this.userForm.value['coreRoles'];
		selection = selection ? selection : [];
		this.availableRoles.forEach(role => {
			if (selection.includes(role.label)) userRoles.push(role);
		});
		return userRoles;
	}

	onRolesChange() {
		/*
		var userRoles = [];
		this.availableRoles.forEach(role => {
			if(this.userForm.value['coreRoles'].includes(role.label)) userRoles.push(role);
		})
		console.log(userRoles);
		/*
		this.userForm.value['coreRoles'].forEach((role: string)=> {
			console.log(role);
		});*/

	}

	onCancel() {
		this.router.navigate([this.ROUTE_USER_VIEW]);
	}
}
