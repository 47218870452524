import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	STORAGE_ITEM_TOKEN: string = 'theme';
	USER_PROFILE_THEME: string = 'USER_PROFILE_THEME';

	
	constructor() { 
		this.initTheme();
	}

	initTheme(){
		let theme = this.loadTheme();
		if(theme) this.applyTheme(theme);

	}

	saveTheme(theme) {
		localStorage.setItem(this.STORAGE_ITEM_TOKEN, theme);
	}

	loadTheme() {
		return localStorage.getItem(this.STORAGE_ITEM_TOKEN);
	}

	changeTheme(theme: string) {
		this.saveTheme(theme);
		this.applyTheme(theme);
	}
	
	applyTheme(theme: string) {
		theme = theme.replace(' ','').toLowerCase();
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }
}
