import { AirportHeliport } from "./airport-heliport";
import { RunwayDirection } from "./runway-direction";

export class Runway {

    id: string;
	designator: string ;
	type: string ;
	nominalLength: number ;
	lengthAccuracy: number ;
	nominalWidth: number ;
	widthAccuracy: number ;
	widthShoulder: number ;
	lengthStrip: number ;
	widthStrip: number ;
	lengthOffset: number ;
	widthOffset: number ; 
	abandoned: string ;
	surfaceProperties: string ;
	associatedAirportHeliport: AirportHeliport;
	overallContaminant: string ;
	annotation: string ;
	areaContaminant: string ;
	runwayDirections: RunwayDirection[];

}