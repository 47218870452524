import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CoreOrganization } from '../entities/core-organization';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { DomSanitizer } from '@angular/platform-browser';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: ['./organization-view.component.css']
})
export class OrganizationViewComponent implements OnInit {

	//navigation routes
	ROUTE_ORGANIZATION_CREATE = '/security/organization-create';
	ROUTE_ORGANIZATION_UPDATE = '/security/organization-update';

	labels = {
		Add: 'Add',
		AddTooltip: 'Add organization',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected organization',
		Edit: 'Edit',
		EditTooltip: 'Edit selected organization',
		Organizations: 'Organizations',
		DeleteSelectedOrganization: 'Delete selected Organization ?',
		Name: 'Name',
		Label: 'Label',
		Description: 'Description',
		Domaine: 'Domaine',
		State: 'State',
		Address: 'Address',
		Telephone: 'Telephone',
		Email: 'Email',
		WebSite: 'Web site'
	};

	logoImageSrcMap = new Map<string, any>();
	imageLoadRequested = new Map<string, string>();

	selectedOrganization: CoreOrganization;

	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyRowSelected = false;
	anyTabOpened = false;

	organizations: CoreOrganization[];

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public confirmationService: ConfirmationService,
		private domSanitizer: DomSanitizer,
		public fileLoadingService: FileLoadingService
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadOrganizations();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}

	onRowSelect() {
		if (!this.anyRowSelected) {
			this.anyRowSelected = true;
			this.initMenuItems();
		}
	}

	initMenuItems() {
		this.menuItems = [{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyTabOpened, command: () => { this.confirmDelete(); } },
		{ label: this.labels.Edit, icon: 'pi pi-pencil', title: this.labels.EditTooltip, disabled: !this.anyTabOpened, command: () => { this.onEdit(); } },
		{ label: this.labels.Add, icon: 'pi pi-plus', title: this.labels.AddTooltip, command: () => { this.onAdd(); } }];
	}

	getItemStyle(disabled) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onEdit() {
		this.setSelectedOrganization();
		if (this.selectedOrganization != null) {
			this.router.navigate([this.ROUTE_ORGANIZATION_UPDATE, this.selectedOrganization.id]);
		}
	}

	onAdd() {
		this.router.navigate([this.ROUTE_ORGANIZATION_CREATE]);
	}

	confirmDelete() {
		this.setSelectedOrganization();
		if (this.selectedOrganization != null) {
			this.confirmationService.confirm({
				message: this.labels.DeleteSelectedOrganization,
				accept: () => {
					this.onDelete();
				}
			});
		}

	}

	onDelete() {
		let route = HttpMapping.CORE_ORGANIZATION_DELETE_BY_ID.replace('{id}', this.selectedOrganization.id);
		this.repository.delete(route, RepositoryService.SERVICE_SECURITY).subscribe(
			() => this.onLoadOrganizations()
		);
	}

	onLoadOrganizations() {
		let route = HttpMapping.CORE_ORGANIZATION_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((data: CoreOrganization[]) => {
			this.organizations = data;
		});
	}

	setSelectedOrganization() {
		if (this.organizations[this.tabIndex] != null) {
			this.selectedOrganization = this.organizations[this.tabIndex];
		}
	}

	onTabOpen(e) {
		this.tabIndex = e.index;
		if (!this.anyTabOpened) {
			this.anyTabOpened = true;
			this.initMenuItems();
		}
	}

	loadImage(image) {
		if(!this.imageLoadRequested.has(image)){
			console.log('Requesting image '+image+' ...');
			this.imageLoadRequested.set(image, image);
			this.downloadLogo(image, image);
		}
		return this.logoImageSrcMap.get(image);
	}

	downloadLogo(logo, key: string) {
		const route = HttpMapping.FILE_DOWNLOAD.replace('{filename}', logo);		
		this.fileLoadingService.downloadFile(route, FileLoadingService.SERVICE_SECURITY).subscribe((data) => {	
			this.displayLogo(data.body, key);
		}, err => {
			console.log(err);
		});
	}

	displayLogo(file, key: string) {
		if (file) {
			const reader = new FileReader();
			reader.onload = ((e) => {
				this.logoImageSrcMap.set(key, this.domSanitizer.bypassSecurityTrustUrl(<string> e.target['result']));
			});
			reader.readAsDataURL(file);
		}
	}

	openUrl(url){
		window.open(url, '_blank');
	}

}
