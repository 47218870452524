import { Component, OnInit } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-licence-generate',
  templateUrl: './licence-generate.component.html',
  styleUrls: ['./licence-generate.component.css']
})
export class LicenceGenerateComponent implements OnInit {

	//navigation routes
	ROUTE_LICENCE_CLIENT = '/security/licence-client';

	requestFile: any;

	labels = {
		Title: 'Generate licence',
		UplaodFile: 'Upload request file',
		SelectRequestFile: 'Select request file to upload',
		Upload: 'Upload',
		Cancel: 'Cancel',
		GenerateOkMessage: 'Licence generated successfully !',
		GenerateFailedMessage: 'Fail to generate licence from request file !'
	};

	
	constructor(
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public fileLoadingService: FileLoadingService,
		public app: AppComponent
	) { }

	ngOnInit() {
	}

	onUpload() {
		if (this.requestFile) {
			const route = HttpMapping.CLIENT_LICENCE_POST_GENERATE_FROM_REQUEST_FILE;
			this.fileLoadingService.uploadFile(route, this.requestFile, RepositoryService.SERVICE_SECURITY).subscribe(() => {
				this.app.showInfo(this.labels.GenerateOkMessage);
				this.router.navigate([this.ROUTE_LICENCE_CLIENT]);
			}, err => {
				this.app.showError(this.labels.GenerateFailedMessage);
			});
		}
	}

	onFileSelect(event){
		this.requestFile = event.target.files[0];
	}

	onCancel() {
		this.router.navigate([this.ROUTE_LICENCE_CLIENT]);
	}


}
