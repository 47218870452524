import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { SafePipe } from './pipes/safe.pipe';
import { DisableControlDirective } from './directives/disable-control.directive';
import { LatitudePipe } from './pipes/latitude.pipe';
import { LongitudePipe } from './pipes/longitude.pipe';
import { UuidPartsPipe } from './pipes/uuid-parts.pipe';
import { E5xEnumPipe } from './pipes/e5x-enum.pipe';
import { AircraftAttributesPipe } from './pipes/aircraft-attributes.pipe';
import { CoreStatePipe } from './pipes/core-state.pipe';

@NgModule({
	declarations: [
		SafePipe,
		DisableControlDirective,
		LatitudePipe,
		LongitudePipe,
		UuidPartsPipe,
		E5xEnumPipe,
		AircraftAttributesPipe,
		CoreStatePipe
		
	],
	imports: [
		CommonModule,
		SharedRoutingModule
	],
	exports: [
		SafePipe,
		DisableControlDirective,
		LatitudePipe,
		LongitudePipe,
		UuidPartsPipe,
		E5xEnumPipe,
		AircraftAttributesPipe,
		CoreStatePipe	
	]
})
export class SharedModule { }
