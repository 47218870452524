import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DynamicDialogRef, DynamicDialogConfig, SelectItem } from 'primeng/api';
import { Authentication } from '../entities/authentication';
import { Subscription } from 'rxjs';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent extends Translatable implements OnInit, OnDestroy{

  profileForm: FormGroup;

  localeItems: SelectItem[] = [
    {value: null, label: 'Select'},
    {value: 'fr', label: 'Français'},
    {value: 'en', label: 'English'}
  ];

  authentication: Authentication;
  authenticationSubscription: Subscription;
  

	labels = {
		Title: 'Edit profile',
		Language: 'Language',
		Save: 'Save',
		SaveOkMessage: 'Profile saved successfuly !'
	};

  constructor(
    public fb: FormBuilder,
		public authenticationService: AuthenticationService,
		public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public translate: TranslateService,
		public localeService: LocaleService
  ) {
    super('ProfileEditComponent');
   }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
    this.localeSubscription.unsubscribe();
	}

	ngOnInit() {
    this.subscribeToLocale();
		this.initProfileForm();
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}

	initProfileForm() {
		this.profileForm = this.fb.group({
			locale: ['', [Validators.required]]
    });
    
    this.profileForm .patchValue({locale: this.localeService.getLocale()});
	}

	onSaveProfile() {
    let locale = this.profileForm.value['locale'];
    this.localeService.setLocale(locale);

		this.ref.close({locale: locale});
  }
  
}
