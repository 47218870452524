import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { ScrollPanel, SelectItem } from 'primeng/primeng';
import { AppComponent } from '../app.component';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OccurrencesFilter } from '../shared/com/eddimas/avairs/model/beans/occurrences-filter.model';
import { StatisticService } from '../statistics/service/statistic.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../shared/services/locale.service';
import { Translatable } from '../shared/utilities/translatable';
import { DatePipe } from '@angular/common';
import { E5XEnumItem } from '../shared/com/eddimas/avairs/model/beans/e5x-enum-item.model';
import { OccurrenceFilterService } from '../shared/services/occurrence-filter.service';
import { StaticValuesService } from '../shared/services/static-values.service';

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html'
})
export class AppRightPanelComponent extends Translatable implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('scrollRightPanel') rightPanelMenuScrollerViewChild: ScrollPanel;

    //set statistics view param
    activeStatisticsView: string = '';
    activeStatisticsViewSubscription: Subscription;

    //Filter
    occurrencesFilter: OccurrencesFilter;
    occurrencesFilterSubscription: Subscription;

    filterForm: FormGroup;

    period: string;
    periodSubscription: Subscription;

    occurrenceClassItems: SelectItem[];
    occurrenceClassItemsSubscription: Subscription;

    occurrencesCategories: E5XEnumItem[];
    occurrencesCategoriesSubscription: Subscription;
    occurrenceCategoriesItems: SelectItem[];
    locationIndicatorItems: SelectItem[];

    //isPeriodSelected: boolean = true;
    occurrenceYear: string;
    periodStart: Date;
    periodEnd: Date;
    occurrenceClass: string[];



    yearItems: SelectItem[];
    languageItems: SelectItem[];

    labels = {
        Accidents: 'Accidents',
        AccidentsCapital: 'ACCIDENTS',
        SeriousIncidents: 'Serious incidents',
        SeriousIncidentsCapital: ' SERIOUS INCIDENTS',
        OccurrenceClass: 'Occurrence class',
        OccurrenceCategory: 'Occurrence category',
        Incidents: 'Incidents',
        IncidentsCapital: 'INCIDENTS',
        OtherOccurrences: 'Other occurrences',
        Filter: 'Filter',
        Year: 'Year',
        Period: 'Period',
        PeriodStart: 'Period start',
        PeriodEnd: 'Period end',
        Start: 'Start',
        From: 'from',
        End: 'End',
        To: 'TO',
        Language: 'Language',
        LocationIndicator: 'Location indicator',
        Aerodromes: 'Aerodromes'
    }

    constructor(
        public app: AppComponent,
        public fb: FormBuilder,
        public translate: TranslateService,
        public localeService: LocaleService,
        public statisticService: StatisticService,
        public datepipe: DatePipe,
        public occurrenceFilterService: OccurrenceFilterService,
        public staticValuesService: StaticValuesService
    ) {
        super('AppRightPanelComponent');
    }

    ngAfterViewInit() {
        setTimeout(() => { this.rightPanelMenuScrollerViewChild.moveBar(); }, 100);
    }

    onTabChange() {
        setTimeout(() => { this.rightPanelMenuScrollerViewChild.moveBar(); }, 450);
    }

    ngOnInit() {
        this.subscribeToLocale();
        this.initFilterForm();
        this.onYear();
        this.loadStaticValues();

        this.filterForm.controls['language'].patchValue(this.localeService.getLocale());
        this.filterForm.controls['language'].disable();

        this.occurrenceClassItems = [
            { label: this.labels.Accidents, value: 'accidents' },
            { label: this.labels.SeriousIncidents, value: 'seriousIncidents' },
            { label: this.labels.Incidents, value: 'incidents' },
            { label: this.labels.OtherOccurrences, value: 'otherOccurrences' }
        ];

        

        this.activeStatisticsViewSubscription = this.statisticService.activeStatisticsViewSubject.subscribe(
            (activeStatisticsView: string) => {
                this.activeStatisticsView = activeStatisticsView ? activeStatisticsView : '';
            }
        )
        this.statisticService.emitActiveStatisticsViewSubject();

        this.occurrencesFilterSubscription = this.statisticService.occurrencesFilterSubject.subscribe(
            (occurrencesFilter: OccurrencesFilter) => {
                this.occurrencesFilter = occurrencesFilter ? occurrencesFilter : null;

                if (this.occurrencesFilter) {
                    this.filterForm.controls['occurrenceYear'].patchValue(this.occurrencesFilter.occurrenceYear);
                    this.filterForm.controls['periodStart'].patchValue(this.occurrencesFilter.periodStart);
                    this.filterForm.controls['periodEnd'].patchValue(this.occurrencesFilter.periodEnd);
                    this.filterForm.controls['occurrenceClass'].patchValue(this.occurrencesFilter.eventTypes);
                    this.filterForm.controls['eventCategories'].patchValue(this.occurrencesFilter.eventCategories); 
                    this.filterForm.controls['aerodromeLocationIndicators'].patchValue(this.occurrencesFilter.aerodromeLocationIndicators); 
                };
            }
        )
        this.statisticService.emitOccurrencesFilterSubject();

        /*this.occurrenceClassItemsSubscription = this.statisticService.occurrenceClassItemsSubject.subscribe(
            (occurrenceClassItems: SelectItem[]) => {
                this.occurrenceClassItems = occurrenceClassItems ? occurrenceClassItems : null;
            }
        )*/
        this.statisticService.emitOccurrenceClassItemsSubject();

        this.occurrencesCategoriesSubscription = this.statisticService.occurrencesCategoriesSubject.subscribe(
            (occurrencesCategories: E5XEnumItem[]) => {
                this.occurrencesCategories = occurrencesCategories ? occurrencesCategories : null;
                //console.log(this.occurrencesCategories);
                this.occurrenceCategoriesItems = [];
                if (occurrencesCategories) {
                    occurrencesCategories.sort((a, b) => parseInt(a.valueID) - parseInt(b.valueID)).forEach(element => {
                        this.occurrenceCategoriesItems.push({ label: element.description, value: element.valueID });
                    })
                }
            }
        )
        this.statisticService.emitOccurrencesCategoriesSubject();

        /*this.yearItems = [
            { label: 'Choose', value: null },
            { label: '2014', value: '2014' },
            { label: '2015', value: '2015' },
            { label: '2016', value: '2016' },
            { label: '2017', value: '2017' },
            { label: '2018', value: '2018' },
            { label: '2019', value: '2019' },
            { label: '2020', value: '2020' }
        ]*/

        this.languageItems = [
            { label: 'English', value: 'en' },
            { label: 'Français', value: 'fr' }
        ]

    }

    ngOnDestroy() {
        this.localeSubscription.unsubscribe();
        this.periodSubscription.unsubscribe();
        this.activeStatisticsViewSubscription.unsubscribe();
        this.occurrencesFilterSubscription.unsubscribe();
        this.occurrenceClassItemsSubscription.unsubscribe();
        this.occurrencesCategoriesSubscription.unsubscribe();
    }

    buildPeriod(occurrenceFilter: OccurrencesFilter) {
        if (this.filterForm.value['year'] === 'year') {
            if (occurrenceFilter.occurrenceYear === null) {
                this.period = null
            } else {
                this.period = this.labels.Year + ": " + occurrenceFilter.occurrenceYear;
            }
        }

        if (this.filterForm.value['period'] === 'period') {
            if (occurrenceFilter.periodStart !== null && occurrenceFilter.periodEnd !== null) {
                this.period = this.labels.Period + ": " + this.datepipe.transform(occurrenceFilter.periodStart, 'dd/MM/yyyy') + " - " + this.datepipe.transform(occurrenceFilter.periodEnd, 'dd/MM/yyyy');
            } else {
                this.period = ''
            }
        }
        this.statisticService.period = this.period;
        this.statisticService.emitPeriodSubject();
    }

    initFilterForm() {
        this.filterForm = this.fb.group({            
            occurrenceClass: [''],
            year: ['year'],
            period: [''],
            occurrenceYear: [null],
            periodStart: [null],
            periodEnd: [null],
            language: [null],
            eventCategories: [''],
            aerodromeLocationIndicators: ['']
        });
    }


    /*
			load filtered static values
        */
        loadStaticValues() {
    const filteredAttributes = 'locationIndicator';
    this.staticValuesService.getFilteredAttributeStaticValues(filteredAttributes).subscribe((filteredValues: Map<string, E5XEnumItem[]>) => {

      //locationIndicators
      this.locationIndicatorItems = [];
      this.locationIndicatorItems.push({ label: 'Select', value: null });
      filteredValues['locationIndicator'].forEach(value => {
        this.locationIndicatorItems.push(
          {
            label: value.detailedDescription,
            value: value.valueID
          }
        );
      });

    }, () => {
      //this.app.showError(this.labels.LoadFailedMessage);
    });
}

    executeCreate() {
        this.occurrencesFilter = {            
            occurrenceYear: this.filterForm.value['occurrenceYear'],
            periodStart: this.filterForm.value['periodStart'],
            periodEnd: this.filterForm.value['periodEnd'],
            eventTypes: this.filterForm.value['occurrenceClass'],
            eventCategories: this.filterForm.value['eventCategories'],
            aerodromeLocationIndicators: this.filterForm.value['aerodromeLocationIndicators']
        };
        this.statisticService.occurrencesFilter = this.occurrencesFilter;
        this.statisticService.emitOccurrencesFilterSubject();

        //console.log(this.occurrencesFilter);

        this.occurrenceFilterService.setOccurrencesFilter(this.occurrencesFilter);
    }

    onFilter() {
        this.executeCreate();
        this.buildPeriod(this.occurrencesFilter);

        if (this.activeStatisticsView === 'statistics-accidents') {
            this.statisticService.getAllOccurrencesByMonth(this.occurrencesFilter);
            this.statisticService.getAllOccurrencesByYear(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-incidents') {
            this.statisticService.getAllOccurrencesByMonth(this.occurrencesFilter);
            this.statisticService.getAllOccurrencesByYear(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-wild-life') {
            this.statisticService.getOccurrencesBirdWildByMonth(this.occurrencesFilter);
            this.statisticService.getOccurrencesBirdWildBySpecies(this.occurrencesFilter);
            this.statisticService.getOccurrencesBirdWildBySizes(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-phases') {
            this.statisticService.getOccurrencesByDetectionPhase(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-weather') {
            this.statisticService.getOccurrencesByWeatherCondition(this.occurrencesFilter);
            this.statisticService.getOccurrencesByWeatherCategory(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-operators') {
            this.statisticService.getOccurrencesByOperator(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-categories') {
            this.statisticService.getOccurrencesByCategory(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-technical-categories') {
            this.statisticService.getOccurrencesByTechnicalCategory(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-operations') {
            this.statisticService.getOccurrencesByOperation(this.occurrencesFilter);
        }

        if (this.activeStatisticsView === 'statistics-flight-rules') {
            this.statisticService.getOccurrencesByFightRules(this.occurrencesFilter);
        }

    }

    onYear() {
        this.filterForm.controls['year'].patchValue('year');
        this.filterForm.controls['occurrenceYear'].enable();

        this.filterForm.controls['period'].patchValue('');
        this.filterForm.controls['periodStart'].disable();
        this.filterForm.controls['periodEnd'].disable();
    }

    onPeriod() {
        this.filterForm.controls['period'].patchValue('period');
        this.filterForm.controls['periodEnd'].enable();
        this.filterForm.controls['periodStart'].enable();

        this.filterForm.controls['year'].patchValue('');
        this.filterForm.controls['occurrenceYear'].disable();
    }

}
