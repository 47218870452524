import { Pipe, PipeTransform } from '@angular/core';
import { CoreState } from 'src/app/security/entities/core-state';
import { state } from '@angular/animations';

@Pipe({
  name: 'coreState'
})
export class CoreStatePipe implements PipeTransform {

  transform(value: CoreState[], args?: any): any {
    var label = '';
    if(args){
      if(args === 'statesNames'){
        value.forEach(state =>{
          label += label != ''? (', '+state.name) : state.name;
        })
      }
    }
    return label;
  }

}
