
export class NotificationDestinator {

	name: string;
	address: string;
	phone: string;
	fax: string;
	email: string;


	constructor()
	constructor(m: NotificationDestinator)
	constructor(m?: NotificationDestinator) {

		if (m) {
			this.name = m.name;
			this.address = m.address;
			this.phone = m.phone;
			this.fax = m.fax;
			this.email = m.email;
		}
	}
}
