import { Runway } from "./runway";
import { City } from "./city";
import { OLSColor } from "./ols-color";
import { GisElevatedPoint } from "./gis-elevated-point";

export class AirportHeliport {
    id: string;
	designator: string ;
	name: string ;
	locationIndicatorICAO: string ;
	designatorIATA: string ;
	type: string ;
	certifiedICAO: string ;
	privateUse: string ;
	controlType: string ;
	fieldElevation: number ;
	fieldElevationAccuracy: number ;
	verticalDatum: string ;
	magneticVariation: number; 
	magneticVariationAccuracy: number ;
	dateMagneticVariation: number ;
	magneticVariationChange: number ;
	referenceTemperature: number ;
	olsColors: OLSColor[];
    runways: Runway[];
    servedCity: City; 
    arp: GisElevatedPoint;

}