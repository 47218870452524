
export class GisElevatedPoint {

	id: string;
	//topElevation: number;
	geoidUndulation: number;
	verticalDatum: string;
	verticalAccuracy: number;
	position: string;
	//longitude: number;
	//latitude: number;
	//baseElevation: number;
	title: string;

	constructor(
		public latitude?: number,
		public longitude?: number,
		public baseElevation?: number,
		public topElevation?: number
	) {}

}