import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DataDashboardComponent } from './data-dashboard/data-dashboard.component';
import { SecurityModule } from '../security/security.module';
import { AerodromeModule } from '../aerodrome/aerodrome.module';

@NgModule({
	declarations: [
	  MainDashboardComponent, 
	  AdminDashboardComponent,
	  DataDashboardComponent
	],
  imports: [
    CommonModule,
	DashboardRoutingModule,
	SecurityModule,
	AerodromeModule
  ]
})
export class DashboardModule { }
