import { CoreUser } from "./core-user";

export class Authentication {
	coreUser: CoreUser;

	constructor(
		public isAuthenticated?: boolean,
		public userRoles?: string[],
		public hasSysRole?: boolean,
		public hasAdminRole?: boolean,
		public hasSupRole?: boolean,
		public hasOprRole?: boolean,
		public token?: string,
		public subject?: string,
	) { };
}