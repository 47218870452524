import { Pipe, PipeTransform } from '@angular/core';
import { Aircraft } from '../com/eddimas/avairs/model/entities/aircraft.model';

@Pipe({
  name: 'aircraftAttributes'
})
export class AircraftAttributesPipe implements PipeTransform {

  transform(value: Aircraft[], attribute?: string): any {
    var label = '';
    if(value){
      if(attribute === 'aircraftRegistration') {
        value.forEach(ac => {
          label += label === '' ? 
          (ac.attributes.aircraftRegistration ? ac.attributes.aircraftRegistration.value : '') : 
          (ac.attributes.aircraftRegistration ? ', '+ac.attributes.aircraftRegistration.value : '');
        });
      }
    }
    return label;
  }

}
