import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { DataDashboardComponent } from './data-dashboard/data-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';

const routes: Routes = [
	{
		path: 'main',
		component: MainDashboardComponent
	},
	{
		path: 'admin',
		component: AdminDashboardComponent
	},
	{
		path: 'data',
		component: DataDashboardComponent
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
