import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DmsComponent } from './dms/dms.component';

const routes: Routes =
    [
        {
            path: 'dms',
            component: DmsComponent
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GisRoutingModule { }
