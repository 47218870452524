import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { AppComponent } from 'src/app/app.component';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { Router } from '@angular/router';
import { RepositoryService } from 'src/app/shared/services/repository.service';

@Component({
	selector: 'app-licence-user',
	templateUrl: './licence-user.component.html',
	styleUrls: ['./licence-user.component.css']
})
export class LicenceUserComponent implements OnInit {

	labels = {
		Title: 'User Licences',
		Subscribe: 'Subscribe',
		SubscribeTooltip: 'Subscribe to selected licence',
		Return: 'Return',
		ReturnTooltip: 'Return selected licence',
		Licences: 'Licences',
		AvailableLicences: 'Available Licences',
		UserLicences: 'User Licences',
		LoadFailedMessage: 'Fail to loade licences !',
		SubscribeSelectedLicence: 'Subscribe to selected licence ?',
		ReturnSelectedLicence: 'Return selected licence ?',
		SubscribedOkMessage: 'Licence subscribed successfully ?',
		ReturnedOkMessage: 'Licence returned successfully ?'
	};


	selectedLicence: any;

	selectedUserLicence: any;

	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyLicenceSelected = false;
	anyUserLicenceSelected = false;

	licenceIconImages = ['key-black-1.png', 'key-black-2.png', 'key-grey-1.png', 'key-grey-2.png'];


	licences = [];
	userLicences = [];

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public confirmationService: ConfirmationService,
		public fileLoadingService: FileLoadingService,
		public app: AppComponent
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadLicences();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}


	initMenuItems() {
		this.menuItems = [{ label: this.labels.Subscribe, icon: 'pi pi-sign-in', title: this.labels.SubscribeTooltip, disabled: !this.anyLicenceSelected, command: () => { this.confirmSubscribe(); } },
		{ label: this.labels.Return, icon: 'pi pi-sign-out', title: this.labels.ReturnTooltip, disabled: !this.anyUserLicenceSelected, command: () => { this.confirmReturn(); } }];
	}

	getItemStyle(disabled) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onLoadLicences() {
		let route = HttpMapping.CORE_LICENCE_GET_USER_AVAILABLE;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((data: []) => {
			this.licences = data;
		});

		let grantRoute = HttpMapping.CORE_LICENCE_GET_USER_GRANTED;
		this.repository.getData(grantRoute, RepositoryService.SERVICE_SECURITY).subscribe((data: []) => {
			this.userLicences = data;
		});
	}

	onReturn() {
		let route = HttpMapping.CORE_LICENCE_PUT_UNSUBSCRIBE_BY_ID.replace('{id}', this.selectedUserLicence.id);
		this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.onLoadLicences();
			this.app.showInfo(this.labels.ReturnedOkMessage);
			this.onLicenceUnselect();
			this.onUserLicenceUnselect();
		});
	}

	onSubscribe() {
		let route = HttpMapping.CORE_LICENCE_PUT_SUBSCRIBE_BY_ID.replace('{id}', this.selectedLicence.id);
		this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.onLoadLicences();
			this.app.showInfo(this.labels.SubscribedOkMessage);
			this.onLicenceUnselect();
			this.onUserLicenceUnselect();
		});
	}

	onLicenceSelect(licence: any) {
		this.selectedLicence = licence;
		if (!this.anyLicenceSelected) {
			this.anyLicenceSelected = true;
			this.initMenuItems();
		}
	}

	onLicenceUnselect() {
		this.selectedLicence = null;
		if (this.anyLicenceSelected) {
			this.anyLicenceSelected = false;
			this.initMenuItems();
		}
	}

	onUserLicenceSelect(licence: any) {
		this.selectedUserLicence = licence;
		if (!this.anyUserLicenceSelected) {
			this.anyUserLicenceSelected = true;
			this.initMenuItems();
		}
	}

	onUserLicenceUnselect() {
		this.selectedUserLicence = null;
		if (this.anyUserLicenceSelected) {
			this.anyUserLicenceSelected = false;
			this.initMenuItems();
		}
	}

	confirmSubscribe() {
		if (this.selectedLicence != null) {
			this.confirmationService.confirm({
				message: this.labels.SubscribeSelectedLicence,
				accept: () => {
					this.onSubscribe();
				}
			});
		}

	}

	confirmReturn() {
		if (this.selectedUserLicence != null) {
			this.confirmationService.confirm({
				message: this.labels.ReturnSelectedLicence,
				accept: () => {
					this.onReturn();
				}
			});
		}

	}


	licenceIconeImage(i: number) {
		let index = i > 4 ? i % 4 : i;
		return this.licenceIconImages[index];
	}


}
