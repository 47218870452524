import { Component, OnInit } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { GisElevatedPoint } from 'src/app/shared/entities/gis-elevated-point';
import { City } from 'src/app/shared/entities/city';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CoordinatesRegex } from 'src/app/shared/constants/coordinates-regex';
import { CoordinatesConvertorService } from 'src/app/shared/services/coordinates-convertor.service';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SelectItem, Message } from 'primeng/api';
import { CoordinatesTypes } from 'src/app/shared/constants/coordinates-types';

@Component({
	selector: 'app-aerodrome-update',
	templateUrl: './aerodrome-update.component.html',
	styleUrls: ['./aerodrome-update.component.css']
})
export class AerodromeUpdateComponent implements OnInit {
	aerodromeForm: FormGroup;
	aerodrome: AirportHeliport;

	//navigation routes
	ROUTE_AERODROME_VIEW = '/aerodrome/aerodrome-view';

	testCoordinate: any;

	arpLatitude: any = '';
	arpLongitude: any = '';

	arp: GisElevatedPoint;
	servedCity: City;

	msgs: Message[] = [];

	labels = {
		Title: 'Update Aerodrome',
		GeneralData: 'General data',
		ARPData: 'ARP data',
		LocationIndicator: 'Location indicator',
		Designator: 'Designator',
		Name: 'Name',
		ARPLatitude: 'Latitude',
		ARPLongitude: 'Longitude',
		ARPElevation: 'Elevation (M)',
		Cancel: 'Cancel',
		Save: 'Save',
		EditAerodrome: 'Edit Aerodrome',
		AddAerodrome: 'Add Aerodrome',
		ServedCity: 'Served City',
		OLSColors: 'OLS Colors',
		SurfaceOLSType: 'Surface OLS Type',
		SurfaceColor: 'Surface Color',
		AddOLSColor: 'Add OLS Color',
		RemoveOLSColor: 'X',
		LoadFailedMessage: 'Failed to load aerodrome !',
		SaveFailedMessage: 'Failed to save aerodrome !',
		SaveOkMessage: 'Aerodrome saved successfully !'
	};

	surfacesOLSTypes: SelectItem[] = [
		{ label: 'Other', value: 'OTHER' },
		{ label: 'Outer Horizontal', value: 'OUTER_HORIZONTAL_SURFACE' },
		{ label: 'Conical', value: 'CONICAL_SURFACE' },
		{ label: 'Inner Horizontal', value: 'INNER_HORIZONTAL_SURFACE' },
		{ label: 'Approach', value: 'APPROACH_SURFACE' },
		{ label: 'Inner Approach', value: 'INNER_APPROACH_SURFACE' },
		{ label: 'Transitional', value: 'TRANSITIONAL_SURFACE' },
		{ label: 'Inner Transitional', value: 'INNER_TRANSITIONAL_SURFACE' },
		{ label: 'Balked Landing', value: 'BALKED_LANDING_SURFACE' },
		{ label: 'Takeoff Climb', value: 'TAKE_OFF_CLIMB_SURFACE' }
	];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent,
		public convertor: CoordinatesConvertorService
	) { }

	ngOnInit() {
		this.initAerodromeForm();
	}

	initAerodromeForm() {
		this.aerodromeForm = this.fb.group({
			name: ['', [Validators.required]],
			designator: ['', [Validators.required]],
			locationIndicatorICAO: ['', [Validators.required]],
			type: ['Aerodrome'],
			servedCityName: [''],
			arp: this.fb.group({
				latitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LATITUDE_COMPLETE_PATTERN)]],
				longitude: ['', [Validators.required, Validators.pattern(CoordinatesRegex.LONGITUDE_COMPLETE_PATTERN)]],
				baseElevation: ['', [Validators.required]],
				topElevation: ['']
			})
		});

		if (this.activatedRoute.snapshot.paramMap.keys.includes('id')) {
			this.loadAerodrome(this.activatedRoute.snapshot.params['id']);
		}
	}

	loadAerodrome(id: string) {
		let route = HttpMapping.AIRPORT_HELIPORT_GET_BY_ID.replace('{id}', id);
		this.repository.getData(route, RepositoryService.SERVICE_AVAIRS).subscribe((data: AirportHeliport) => {
			this.aerodrome = data;

			this.aerodromeForm.setValue({
				name: this.aerodrome.name,
				designator: this.aerodrome.designator,
				locationIndicatorICAO: this.aerodrome.locationIndicatorICAO,
				type: 'Aerodrome',
				servedCityName: this.aerodrome.servedCity ? this.aerodrome.servedCity.name: null,
				arp: {
					latitude: this.aerodrome.arp? this.convertor.coordinateToDMS(this.aerodrome.arp.latitude, CoordinatesTypes.TYPE_LATITUDE): null,
					longitude: this.aerodrome.arp? this.convertor.coordinateToDMS(this.aerodrome.arp.longitude, CoordinatesTypes.TYPE_LONGITUDE): null,
					baseElevation: this.aerodrome.arp? this.aerodrome.arp.baseElevation: null,
					topElevation: this.aerodrome.arp? this.aerodrome.arp.topElevation: null
				}
			});
		}, _error => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

	onCancel() {
		this.router.navigateByUrl(this.ROUTE_AERODROME_VIEW);
	}

	onUpdate() {
		if (this.aerodromeForm.valid) {
			this.executeUpdate();
		}
	}

	executeUpdate() {
		this.aerodrome.name = this.aerodromeForm.value['name'];
		this.aerodrome.designator = this.aerodromeForm.value['designator'];
		this.aerodrome.locationIndicatorICAO = this.aerodromeForm.value['locationIndicatorICAO'];
		this.aerodrome.type = this.aerodromeForm.value['type'];

		if (!this.aerodrome.servedCity) this.aerodrome.servedCity = new City();

		this.aerodrome.servedCity.name = this.aerodromeForm.value['servedCityName'];

		if (!this.aerodrome.arp) this.aerodrome.arp = new GisElevatedPoint();

		this.aerodrome.arp.latitude = this.convertor.coordinateToDD(this.aerodromeForm.value['arp'].latitude);
		this.aerodrome.arp.longitude = this.convertor.coordinateToDD(this.aerodromeForm.value['arp'].longitude);
		this.aerodrome.arp.baseElevation = this.aerodromeForm.value['arp'].baseElevation;
		this.aerodrome.arp.topElevation = this.aerodromeForm.value['arp'].baseElevation;


		let route = HttpMapping.AIRPORT_HELIPORT_PUT_BY_ID.replace('{id}', this.aerodrome.id);
		this.repository.update(route, this.aerodrome, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
			this.router.navigate([this.ROUTE_AERODROME_VIEW]);
			this.app.showInfo(this.labels.SaveOkMessage);
		}, _error => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}
}
