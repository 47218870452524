import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Authentication } from 'src/app/security/entities/authentication';
import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root'
})
export class FileLoadingService implements OnDestroy {

	authentication: Authentication;
	authenticationSubscription: Subscription;

	static SERVICE_SECURITY = 'SERVICE_SECURITY';
	static SERVICE_AVAIRS = 'SERVICE_AVAIRS';
	static SERVICE_STATISTICS = 'SERVICE_STATISTICS';

	protected backendServer = ConfigService.settings.backendServer;

	constructor(
		private http: HttpClient,
		public authenticationService: AuthenticationService
	) {
		this.subscribeAuthentication();
	}

	subscribeAuthentication() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}

	getServiceHost(service: string) {
		return service === FileLoadingService.SERVICE_SECURITY ? this.backendServer.securityServer :
			service === FileLoadingService.SERVICE_AVAIRS ? this.backendServer.avairsServer : this.backendServer.statisticsServer;
	}

	uploadFile(route: string, file: any, service: string) {
		let body = new FormData();
		body.append('file', file);
		return this.http.post(this.createCompleteRoute(route, this.getServiceHost(service)), body, { headers: { 'authorization': this.authentication.token } });
	}

	downloadFile(route: string, service: string) {
		return this.http.get(this.createCompleteRoute(route, this.getServiceHost(service)), {
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					'authorization': this.authentication.token
				}
			}
		);

	}

	downloadFileAfterPost(route: string, body, service: string) {
		return this.http.post(this.createCompleteRoute(route, this.getServiceHost(service)),
			body,
			{
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					'authorization': this.authentication.token
				}
			}
		);
	}

	createCompleteRoute(route: string, envAddress: string) {
		return `${envAddress}${route}`;
	}

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}
}
