import { Injectable, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import domtoimage from 'dom-to-image';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { StatsDashboard } from 'src/app/shared/com/eddimas/avairs/model/beans/stats-dashboard.model';
import { OccurrencesByYear } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-year.model';
import { OccurrencesFilter } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-filter.model';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { OccurrencesByMonth } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-month.model';
import { SelectItem } from 'primeng/api';
import { OccurrencesBirdWildByMonth } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-bird-wild-by-month.model';
import { OccurrencesBySpeciesDescription } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-species-description.model';
import { OccurrencesByBirdSize } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-bird-size.model';
import { OccurrencesByDetectionPhase } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-detection-phase.model';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { OccurrencesByWeatherCondition } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-weather-condition.model';
import { OccurrencesByOperator } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-operator.model';
import { StaticValuesService } from 'src/app/shared/services/static-values.service';
import { E5XEnumItem } from 'src/app/shared/com/eddimas/avairs/model/beans/e5x-enum-item.model';
import { OccurrencesByCategory } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-category.model';
import { OccurrencesByOperation } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-operation.model';
import { OccurrencesByFlightRules } from 'src/app/shared/com/eddimas/avairs/model/beans/occurrences-by-flight-rules.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  //set active statistics view param
  activeStatisticsView = '';
  activeStatisticsViewSubject = new Subject<any>();

  //filter period
  period: string;
  periodSubject = new Subject<String>();

  occurrencesFilter: OccurrencesFilter;
  occurrencesFilterSubject = new Subject<OccurrencesFilter>();

  occurrenceClassItems: SelectItem[] = [];
  occurrenceClassItemsSubject = new Subject<SelectItem[]>();

  //Dashboard statistics data model

  statsDashboard: StatsDashboard;
  statsDashboardSubject = new Subject<StatsDashboard>();

  occurrencesByYear: OccurrencesByYear[] = [];
  occurrencesByYearSubject = new Subject<OccurrencesByYear[]>();

  occurrencesByMonth: OccurrencesByMonth[] = [];
  occurrencesByMonthSubject = new Subject<OccurrencesByMonth[]>();

  occurrencesBirdWildByMonth: OccurrencesBirdWildByMonth[] = [];
  occurrencesBirdWildByMonthSubject = new Subject<OccurrencesBirdWildByMonth[]>();

  occurrencesBirdWildBySpecies: OccurrencesBySpeciesDescription[] = [];
  occurrencesBirdWildBySpeciesSubject = new Subject<OccurrencesBySpeciesDescription[]>();

  occurrencesByBirdSize: OccurrencesByBirdSize[] = [];
  occurrencesByBirdSizeSubject = new Subject<OccurrencesByBirdSize[]>();

  occurrencesByDetectionPhase: OccurrencesByDetectionPhase[] = [];
  occurrencesByDetectionPhaseSubject = new Subject<OccurrencesByDetectionPhase[]>();

  occurrencesByWeatherCondition: OccurrencesByWeatherCondition[] = [];
  occurrencesByWeatherConditionSubject = new Subject<OccurrencesByWeatherCondition[]>();

  occurrencesByWeatherCategory: OccurrencesByCategory[] = [];
  occurrencesByWeatherCategorySubject = new Subject<OccurrencesByCategory[]>();

  occurrencesByOperator: OccurrencesByOperator[] = [];
  occurrencesByOperatorSubject = new Subject<OccurrencesByOperator[]>();

  occurrencesByCategory: OccurrencesByCategory[] = [];
  occurrencesByCategorySubject = new Subject<OccurrencesByCategory[]>();

  occurrencesCategories: E5XEnumItem[] = [];
  occurrencesCategoriesSubject = new Subject<E5XEnumItem[]>();

  occurrencesByTechnicalCategory: OccurrencesByCategory[] = [];
  occurrencesByTechnicalCategorySubject = new Subject<OccurrencesByCategory[]>();

  occurrencesByOperation: OccurrencesByOperation[] = [];
  occurrencesByOperationSubject = new Subject<OccurrencesByOperation[]>();

  occurrencesByFlightRule: OccurrencesByFlightRules[] = [];
  occurrencesByFlightRuleSubject = new Subject<OccurrencesByFlightRules[]>();
  




  constructor(
    private repository: RepositoryService,
    public localeService: LocaleService,
    public staticValuesService: StaticValuesService
  ) {
    //Default occurrences filter
    this.occurrencesFilter = {
      occurrenceYear: null,
      periodStart: null,
      periodEnd: null,
      eventTypes: ["accidents", "seriousIncidents", "incidents", "otherOccurrences"],
      eventCategories: [],
      aerodromeLocationIndicators: null
    };
  }

  emitActiveStatisticsViewSubject() {
    this.activeStatisticsViewSubject.next(this.activeStatisticsView);
  }

  emitOccurrencesFilterSubject() {
    this.occurrencesFilterSubject.next(this.occurrencesFilter);
  }

  emitStatsDasboardSubject() {
    this.statsDashboardSubject.next(this.statsDashboard);
  }

  emitOccurrencesByYearSubject() {
    this.occurrencesByYearSubject.next(this.occurrencesByYear.slice());
  }

  emitOccurrencesByMonthSubject() {
    this.occurrencesByMonthSubject.next(this.occurrencesByMonth.slice());
  }

  emitPeriodSubject() {
    this.periodSubject.next(this.period);
  }

  emitOccurrenceClassItemsSubject() {
    this.occurrenceClassItemsSubject.next(this.occurrenceClassItems.slice())
  }

  emitOccurrencesBirdWildByMonthSubject() {
    this.occurrencesBirdWildByMonthSubject.next(this.occurrencesBirdWildByMonth.slice())
  }

  emitOccurrencesBirdWildBySpeciesSubject() {
    this.occurrencesBirdWildBySpeciesSubject.next(this.occurrencesBirdWildBySpecies.slice())
  }

  emitOccurrencesByBirdSizeSubject() {
    this.occurrencesByBirdSizeSubject.next(this.occurrencesByBirdSize.slice())
  }

  emitOccurrencesByDetectionPhaseSubject() {
    this.occurrencesByDetectionPhaseSubject.next(this.occurrencesByDetectionPhase.slice())
  }

  emitOccurrencesByWeatherConditionSubject() {
    this.occurrencesByWeatherConditionSubject.next(this.occurrencesByWeatherCondition.slice())
  }

  emitOccurrencesByWeatherCategorySubject() {
    this.occurrencesByWeatherCategorySubject.next(this.occurrencesByWeatherCategory.slice())
  }

  emitOccurrencesByOperatorSubject() {
    this.occurrencesByOperatorSubject.next(this.occurrencesByOperator.slice())
  }

  emitOccurrencesByCategorySubject() {
    this.occurrencesByCategorySubject.next(this.occurrencesByCategory.slice())
  }

  emitOccurrencesCategoriesSubject() {
    this.occurrencesCategoriesSubject.next(this.occurrencesCategories.slice())
  }

  emitOccurrencesByTechnicalCategorySubject() {
    this.occurrencesByTechnicalCategorySubject.next(this.occurrencesByTechnicalCategory.slice())
  }

  emitOccurrencesByOperationSubject() {
    this.occurrencesByOperationSubject.next(this.occurrencesByOperation.slice())
  }

  emitOccurrencesByFlightRuleSubject() {
    this.occurrencesByFlightRuleSubject.next(this.occurrencesByFlightRule.slice())
  }


  getOccurrencesCategories() {
    const attributes = 'occurrenceInflightCategories,occurrenceOnboardCategories,occurrenceLandingAndTakeOffCategories,occurrenceOngroundCategories,occurrenceNoRelatedToAicraftCategories,occurrenceWeatherCategories,occurrenceOtherCategories';
    this.staticValuesService.getAttributeStaticValues(attributes)
      .subscribe((staticValues: Map<string, E5XEnumItem[]>) => {

        var occurrenceInflightCategories = staticValues['occurrenceInflightCategories'] ? staticValues['occurrenceInflightCategories'] : [];
        var occurrenceOnboardCategories = staticValues['occurrenceOnboardCategories'] ? staticValues['occurrenceOnboardCategories'] : [];
        var occurrenceLandingAndTakeOffCategories = staticValues['occurrenceLandingAndTakeOffCategories'] ? staticValues['occurrenceLandingAndTakeOffCategories'] : [];
        var occurrenceOngroundCategories = staticValues['occurrenceOngroundCategories'] ? staticValues['occurrenceOngroundCategories'] : [];
        var occurrenceNoRelatedToAicraftCategories = staticValues['occurrenceNoRelatedToAicraftCategories'] ? staticValues['occurrenceNoRelatedToAicraftCategories'] : [];
        var occurrenceWeatherCategories = staticValues['occurrenceWeatherCategories'] ? staticValues['occurrenceWeatherCategories'] : [];
        var occurrenceOtherCategories = staticValues['occurrenceOtherCategories'] ? staticValues['occurrenceOtherCategories'] : [];

        this.occurrencesCategories = [];

        occurrenceInflightCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceOnboardCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceLandingAndTakeOffCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceOngroundCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceNoRelatedToAicraftCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceWeatherCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        occurrenceOtherCategories.forEach((element: E5XEnumItem) => {
          this.occurrencesCategories.push(element);
        });
        
        this.emitOccurrencesCategoriesSubject();

      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }


  getStatsDashboard() {
    const route = HttpMapping.STATISTICS_GET_OCCURRENCES_DASHBOARD.replace('{lang}', this.localeService.getLocale());
    this.repository.getData(route, RepositoryService.SERVICE_STATISTICS)
      .subscribe((statsDashboard: StatsDashboard) => {
        this.statsDashboard = statsDashboard ? statsDashboard : null;
        this.emitStatsDasboardSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getAllOccurrencesByYear(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_GET_OCCURRENCES_BY_YEAR_ALL;
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByYear: OccurrencesByYear[]) => {
        this.occurrencesByYear = occurrencesByYear ? occurrencesByYear : [];
        this.emitOccurrencesByYearSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getAllOccurrencesByMonth(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_GET_OCCURRENCES_BY_MONTH_ALL;
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByMonth: OccurrencesByMonth[]) => {
        this.occurrencesByMonth = occurrencesByMonth ? occurrencesByMonth : [];
        this.emitOccurrencesByMonthSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesBirdWildByMonth(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_MONTH;
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesBirdWildByMonth: OccurrencesBirdWildByMonth[]) => {
        this.occurrencesBirdWildByMonth = occurrencesBirdWildByMonth ? occurrencesBirdWildByMonth : [];
        this.emitOccurrencesBirdWildByMonthSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesBirdWildBySpecies(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_SPECIES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesBirdWildBySpecies: OccurrencesBySpeciesDescription[]) => {
        this.occurrencesBirdWildBySpecies = occurrencesBirdWildBySpecies ? occurrencesBirdWildBySpecies : [];
        this.emitOccurrencesBirdWildBySpeciesSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesBirdWildBySizes(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_SIZES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByBirdSize: OccurrencesByBirdSize[]) => {
        this.occurrencesByBirdSize = occurrencesByBirdSize ? occurrencesByBirdSize : [];
        this.emitOccurrencesByBirdSizeSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByDetectionPhase(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_PHASES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByDetectionPhase: OccurrencesByDetectionPhase[]) => {
        this.occurrencesByDetectionPhase = occurrencesByDetectionPhase ? occurrencesByDetectionPhase : [];
        this.emitOccurrencesByDetectionPhaseSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByWeatherCondition(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_WEATHER_CONDITIONS.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByWeatherCondition: OccurrencesByWeatherCondition[]) => {
        this.occurrencesByWeatherCondition = occurrencesByWeatherCondition ? occurrencesByWeatherCondition : [];
        this.emitOccurrencesByWeatherConditionSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByWeatherCategory(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_WEATHER_CATEGORIES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByWeatherCategory: OccurrencesByCategory[]) => {
        this.occurrencesByWeatherCategory = occurrencesByWeatherCategory ? occurrencesByWeatherCategory : [];
        this.emitOccurrencesByWeatherCategorySubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByOperator(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_OPERATORS.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByOperator: OccurrencesByOperator[]) => {
        this.occurrencesByOperator = occurrencesByOperator ? occurrencesByOperator : [];
        this.emitOccurrencesByOperatorSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByCategory(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_CATEGORY.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByCategory: OccurrencesByCategory[]) => {
        this.occurrencesByCategory = occurrencesByCategory ? occurrencesByCategory : [];
        this.emitOccurrencesByCategorySubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByTechnicalCategory(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_TECHNICAL_CATEGORIES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByCategory: OccurrencesByCategory[]) => {
        this.occurrencesByTechnicalCategory = occurrencesByCategory ? occurrencesByCategory : [];
        this.emitOccurrencesByTechnicalCategorySubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByOperation(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_OPERATIONS;
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByOperation: OccurrencesByOperation[]) => {
        this.occurrencesByOperation = occurrencesByOperation ? occurrencesByOperation : [];
        this.emitOccurrencesByOperationSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  getOccurrencesByFightRules(filter: OccurrencesFilter) {
    const route = HttpMapping.STATISTICS_POST_OCCURRENCES_BY_FLIGHT_RULES.replace('{lang}', this.localeService.getLocale());
    this.repository.send(route, filter, RepositoryService.SERVICE_STATISTICS)
      .subscribe((occurrencesByFlightRule: OccurrencesByFlightRules[]) => {
        this.occurrencesByFlightRule = occurrencesByFlightRule ? occurrencesByFlightRule : [];
        this.emitOccurrencesByFlightRuleSubject();
      }, (error) => {
        console.log("Erreur de chargement :" + error);
      });
  }

  onExportImagePng(divId: string) {

    var node = document.getElementById(divId);
    var name = 'my-image-name.png';

    domtoimage.toPng(node)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.download = name;
        link.href = dataUrl;
        link.click();
        document.body.removeChild(link);
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }


  onExportImageSvg(divId: string) {

    var node = document.getElementById(divId);
    var name = 'my-image-name.svg';

    domtoimage.toSvg(node)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.download = name;
        link.href = dataUrl;
        link.click();
        document.body.removeChild(link);
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  onExportImageJpeg(divId: string) {

    var node = document.getElementById(divId);
    var name = 'my-image-name.jpeg';
    var oldBackgroundColor = node.style.backgroundColor;
    node.style.backgroundColor = 'white';

    domtoimage.toJpeg(node)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.download = name;
        link.href = dataUrl;
        link.click();
        document.body.removeChild(link);
        node.style.backgroundColor = oldBackgroundColor;
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

}
