import { Injectable } from '@angular/core';
import { OccurrencesFilter } from '../com/eddimas/avairs/model/beans/occurrences-filter.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OccurrenceFilterService {

  occurrencesFilter: OccurrencesFilter;
  occurrencesFilterSubject = new Subject<OccurrencesFilter>();

  constructor() { }

  emitOccurrencesFilterSubject() {
    this.occurrencesFilterSubject.next(this.occurrencesFilter);
  }

  setOccurrencesFilter(occurrencesFilter: OccurrencesFilter) {
    this.occurrencesFilter = occurrencesFilter;
    this.emitOccurrencesFilterSubject();
  }
}
