import { CoreOrganizationType } from "./core-organization-type";
import { CoreState } from "./core-state";

export class CoreOrganization {

	constructor(
		public id?: string,
		public address?: string,
		public coreCode?: string,
		public description?: string,
		public email?: string,
		public label?: string,
		public licenceCheck?: number,
		public logo?: string,
		public name?: string,
		public phone?: string,
		public webSite?: string,
		public coreStates?: CoreState[],
		public coreOrganizationTypes?: CoreOrganizationType[]
	) { }

}