import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CoreUser } from '../entities/core-user';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { TreeNode, DialogService } from 'primeng/primeng';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router } from '@angular/router';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-user-view',
	templateUrl: './user-view.component.html',
	styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

	//navigation routes
	ROUTE_USER_CREATE = '/security/user-create';
	ROUTE_USER_UPDATE = '/security/user-update';

	labels = {
		Add: 'Add',
		AddTooltip: 'Add user',
		Delete: 'Delete',
		DeleteTooltip: 'Delete selected user',
		Edit: 'Edit',
		Password: 'Password',
		UserPassword: 'User password',
		EditTooltip: 'Edit selected user',
		PasswordTooltip: 'Reset selected user Password',
		Users: 'Users',
		OrganizationUsers: 'Organization Users',
		DeleteSelectedUser: 'Delete selected User ?',
		PasswordResetOkMessage: 'User Password resetted successfuly !',
		PasswordResetFailedMessage: 'Filed to reset user Password'
	};



	msgs: Message[] = [];
	menuItems: MenuItem[];

	anyUserSelected = false;
	anyNodeSelected = false;

	users: CoreUser[];
	selectedUser: CoreUser;

	userTreeModel: TreeNode[];
	selectedTreeNode: TreeNode;

	tabIndex: number;

	constructor(
		public cref: ChangeDetectorRef,
		public repository: RepositoryService,
		public router: Router,
		public confirmationService: ConfirmationService,
		public dialogService: DialogService,
		public app: AppComponent
	) { }

	ngOnInit() {
		this.initMenuItems();
		this.onLoadUsers();
	}

	ngAfterContentChecked() {
		this.cref.detectChanges();
	}


	initMenuItems() {
		this.menuItems = [{ label: this.labels.Delete, icon: 'pi pi-trash', title: this.labels.DeleteTooltip, disabled: !this.anyUserSelected, command: () => { this.confirmDelete(); } },
		{ label: this.labels.Edit, icon: 'pi pi-pencil', title: this.labels.EditTooltip, disabled: !this.anyUserSelected, command: () => { this.onEdit(); } },
		{ label: this.labels.Password, icon: 'pi pi-key', title: this.labels.PasswordTooltip, disabled: !this.anyUserSelected, command: () => { this.onResetPassword(); } },
		{ label: this.labels.Add, icon: 'pi pi-plus', title: this.labels.AddTooltip, command: () => { this.onAdd(); } }];
	}

	initUserTreeNodes() {
		this.userTreeModel = [];
		// order users by organization
		let organizations = new Map<string, CoreUser[]>();
		this.users.forEach(user => {
			if (organizations.has(user.coreOrganization.label)) organizations.get(user.coreOrganization.label).push(user);
			else organizations.set(user.coreOrganization.label, [user]);
		});

		organizations.forEach((value, key, map) => {
			//organization node
			let organizationNode = {
				key: key,
				label: key,
				data: key,
				expandedIcon: 'fa fa-folder-open',
				collapsedIcon: 'fa fa-fw fa-folder',
				expanded: true,
				children: []
			};

			value.forEach(user => {
				let userNode = {
					key: user.id,
					label: user.firstname + ' ' + user.lastname,
					data: user,
					expandedIcon: 'fa fa-fw fa-user',
					collapsedIcon: 'fa fa-fw fa-user'
				};

				organizationNode.children.push(userNode);
			});

			this.userTreeModel.push(organizationNode);

		});

	}

	getItemStyle(disabled) {
		let disabledStyle = {
			'pointer-events': 'none',
			'opacity': 0.5
		};
		let enabledStyle = {
			'cursor': 'pointer'
		}
		return disabled ? disabledStyle : enabledStyle;
	}

	onNodeSelect(event) {
		this.anyNodeSelected = true;
		this.setSelectedUser();
		this.initMenuItems();
	}

	onEdit() {
		this.setSelectedUser();
		if (this.selectedUser != null) {
			this.router.navigate([this.ROUTE_USER_UPDATE, this.selectedUser.id]);
		}
	}

	onResetPassword() {
		this.setSelectedUser();
		const ref = this.dialogService.open(PasswordResetComponent, {
			header: this.labels.UserPassword,
			width: '30%',
			contentStyle: { "max-height": "350px", "overflow": "auto" },
			data: this.selectedUser
		});

		ref.onClose.subscribe((resetPassword: {id: string, newPassword: string}) => {
			if (resetPassword) {
				let route = HttpMapping.CORE_USER_PUT_RESET_PASSWD_BY_ID.replace('{id}', resetPassword.id).replace('{newpassword}', resetPassword.newPassword);
				this.repository.update(route, null, RepositoryService.SERVICE_SECURITY).subscribe(() => {
					this.app.showInfo(this.labels.PasswordResetOkMessage);
				}, (_error) => {
					this.app.showError(this.labels.PasswordResetFailedMessage);
				});
			}
		});
	}

	onAdd() {
		this.router.navigate([this.ROUTE_USER_CREATE]);
	}

	confirmDelete() {
		this.setSelectedUser();
		if (this.selectedUser != null) {
			this.confirmationService.confirm({
				message: this.labels.DeleteSelectedUser,
				accept: () => {
					this.onDelete();
				}
			});
		}

	}

	onDelete() {
		let route = HttpMapping.CORE_USER_DELETE_BY_ID.replace('{id}', this.selectedUser.id);
		this.repository.delete(route, RepositoryService.SERVICE_SECURITY).subscribe(
			() => this.onLoadUsers()
		);
	}

	onLoadUsers() {
		let route = HttpMapping.CORE_USER_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((users: CoreUser[]) => {
			this.users = users;
			this.initUserTreeNodes();
		});
	}

	setSelectedUser() {
		if (this.selectedTreeNode != null && this.selectedTreeNode.data != this.selectedTreeNode.key) {
			this.selectedUser = this.selectedTreeNode.data;
			this.anyUserSelected = true;
		} else {
			this.selectedUser = null;
			this.anyUserSelected = false;
		}
	}


	openUrl(url) {
		window.open(url, '_blank');
	}

}
