import { Injectable } from '@angular/core';
import { AppComponent } from '../../app.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(public app: AppComponent) { }

  handleError(error) {
   let errorMessage = '';
   if (error.error instanceof ErrorEvent) {
     // client-side error
     errorMessage = `Error: ${error.error.message}`;
   } else {
     // server-side error
     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
   }
   this.app.showError(errorMessage);
 }
}
