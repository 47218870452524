import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Authentication } from 'src/app/security/entities/authentication';
import { OccurrenceService } from 'src/app/shared/services/occurrence.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.css']
})
export class MainDashboardComponent implements OnInit, OnDestroy {

  authentication: Authentication;
  authenticationSubscription: Subscription;


  constructor(
    public authenticationService: AuthenticationService,
    public occurrenceService: OccurrenceService
  ) { }

  ngOnDestroy(): void {
        this.authenticationSubscription.unsubscribe();
    }

    ngOnInit() {
        this.subscribeAuthentication();
        this.occurrenceService.setSelectedOccurrence(null);
    }

    /**
     * Subscribe to occurrence subject
     */
    subscribeAuthentication(){
        this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
            this.authentication = authentication;
        });
        this.authenticationService.emitAuthenticationSubjctSubjct();
    }

}
