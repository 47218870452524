import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Authentication } from 'src/app/security/entities/authentication';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-admin-dashboard',
	templateUrl: './admin-dashboard.component.html',
	styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

	authentication: Authentication;
	authenticationSubscription: Subscription;

	constructor(
		public authenticationService: AuthenticationService,
		public app: AppComponent
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	ngOnInit() {
		this.authentication = new Authentication();
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}


}
