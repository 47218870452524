import { Component, OnInit } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { AirportHeliport } from 'src/app/shared/entities/airport-heliport';
import { GisElevatedPoint } from 'src/app/shared/entities/gis-elevated-point';
import { CoreLicenceRequest } from '../entities/core-licence-request';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-licence-request',
	templateUrl: './licence-request.component.html',
	styleUrls: ['./licence-request.component.css']
})
export class LicenceRequestComponent implements OnInit {

	requestForm: FormGroup;
	licenceRequest: CoreLicenceRequest;

	//navigation routes
	ROUTE_LICENCE_VIEW = '/security/licence-view';

	labels = {
		Title: 'Generate Licence request',
		ValidityDays: 'Validity days',
		IntenedUsers: 'Intended usrs number',
		UnlimitedUsers: 'Unlimited',
		AssignmentType: 'Assignment Type',
		IndividualAssignment: 'Individual',
		SelfServiceAssignment: 'Self service',
		ProductsToCover: 'Products to cover',
		LicenceClaims: 'Licence Claims',
		AirportsToCover: 'Airports to cover',
		AirportsInvalid: 'Airports with invalid ARP',
		State: 'State',
		Address: 'Address',
		Telephone: 'Telephone',
		Email: 'Email',
		WebSite: 'Web site',
		LicenceRequired: 'Licence Required',
		Generate: 'Generate',
		Cancel: 'Cancel',
		SaveOkMessage: 'Organization saved successfully !',
		GenerateFailedMessage: 'Fail to generate licence request !',
		LoadFailedMessage: 'Fail to load Organization !',
		LoadStatesFailedMessage: 'Fail to load States !',
		LoadOrganizationTypesFailedMessage: 'Fail to load Organization types !'
	};

	//airportHeliportItems: SelectItem[] = [];
	airports = [];

	availableAirportHeliports: AirportHeliport[] = [];

	validAirportHeliports: AirportHeliport[] = [];

	invalidAirportHeliports: AirportHeliport[] = [];

	selectedAirportHeliports: AirportHeliport[] = [];

	availableProducts: string[] = ['olsat'];
	selectedProducts: string[] = [];

	areUsersUnlimited = false;

	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent,
		public fileLoadingService: FileLoadingService
	) { }

	ngOnInit() {
		this.loadAirportHeliports();
		this.initRequestForm();
	}

	loadAirportHeliports() {
		const route = HttpMapping.AIRPORT_HELIPORT_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((airportHeliports: AirportHeliport[]) => {
			this.availableAirportHeliports = airportHeliports.sort((a: AirportHeliport, b: AirportHeliport) => a.name < b.name ? -1 : 1);

			this.invalidAirportHeliports = [];
			this.validAirportHeliports = [];

			this.availableAirportHeliports.forEach(airportHeliport => {
				if (this.isArpValid(airportHeliport.arp)) {
					this.validAirportHeliports.push(airportHeliport);
				} else {
					this.invalidAirportHeliports.push(airportHeliport);
				}

			});

		}, _error => {
			this.app.showError(this.labels.LoadStatesFailedMessage);
		});
	}


	initRequestForm() {
		this.requestForm = this.fb.group({
			validityDays: ['', [Validators.required]],
			intendedUsers: ['', [Validators.required]],
			unlimitedUsers: [false],
			assignementType: ['', [Validators.required]],
			products: [[], [Validators.required]],
			airportHeliports: [[], [Validators.required]]
		});

	}

	onUnlimitedUser(event) {
		this.areUsersUnlimited = this.requestForm.value['unlimitedUsers'];
		if (this.areUsersUnlimited) this.requestForm.patchValue({ intendedUsers: -1 });
	}

	getSelectedProducts() {
		var products = [];
		var selection = this.requestForm.value['products'];
		selection = selection ? selection : [];
		this.availableProducts.forEach(pdt => {
			if (selection.includes(pdt)) products.push(pdt);
		});
		return products;
	}

	getLicenceClaims() {
		var airportsClaim = this.getSelectedAirportHeliports();
		let claims = {
			airportHeliports: airportsClaim
		}
		return claims;
	}

	getSelectedAirportHeliports() {
		var airportHeliports = [];
		var selection = this.requestForm.value['airportHeliports'];
		selection = selection ? selection : [];
		this.availableAirportHeliports.forEach((airport: AirportHeliport) => {
			if (selection.includes(airport.id)) {
				let ad = new AirportHeliport();
				ad.id = airport.id;
				ad.designator = airport.designator;
				ad.locationIndicatorICAO = airport.locationIndicatorICAO;
				ad.name = airport.name;
				ad.arp = new GisElevatedPoint();
				ad.arp.latitude = airport.arp.latitude;
				ad.arp.longitude = airport.arp.longitude;
				airportHeliports.push(ad);
			}
		});
		return airportHeliports;
	}


	public request() {
		if (this.requestForm.valid) {
			this.executeRequest();
		}
	}


	private executeRequest() {
		this.licenceRequest = new CoreLicenceRequest();
		this.licenceRequest.assignementType = Number.parseInt(this.requestForm.value['assignementType']);
		this.licenceRequest.intendedUsers = this.areUsersUnlimited ? -1 : this.requestForm.value['intendedUsers'];
		this.licenceRequest.validityDays = this.requestForm.value['validityDays'];
		this.licenceRequest.products = this.getSelectedProducts();
		this.licenceRequest.licenceClaims = this.getLicenceClaims();

		const route = HttpMapping.CORE_LICENCE_REQUEST_DOWNLOAD;
		this.fileLoadingService.downloadFileAfterPost(route, this.licenceRequest, RepositoryService.SERVICE_SECURITY).subscribe((data) => {
			let fileContent = new Blob([data.body], { type: data.headers.get('Content-Type') });
			let fileName = data.headers.get('File-Name');
			saveAs(fileContent, fileName);
		}, _error => {
			this.app.showError(this.labels.GenerateFailedMessage);
		});
	}

	isArpValid(arp: GisElevatedPoint) {
		if (arp === null) return false;
		if (arp.latitude === null) return false;
		if (arp.longitude === null) return false;
		if (Number.isNaN(arp.latitude)) return false;
		if (Number.isNaN(arp.longitude)) return false;
		return true;
	}

	onCancel() {
		this.router.navigate([this.ROUTE_LICENCE_VIEW]);
	}

}
