import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Authentication } from '../entities/authentication';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Subscription } from 'rxjs';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { CoreUser } from '../entities/core-user';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  passwordForm: FormGroup;

  user: CoreUser;

	labels = {
		Title: 'Reset user password',
		NewPassword: 'New Password',
		UserName: 'User name',
		Reset: 'Reset',
		LoadFailedMessage: 'Fail to load User !',
		ChangeOkMessage: 'Password resetted successfuly !',

	};

	authentication: Authentication;
	authenticationSubscription: Subscription;

	constructor(
		public fb: FormBuilder,
		public authenticationService: AuthenticationService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	ngOnInit() {
		this.loadUser();
		this.initPasswordForm();
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}

	initPasswordForm() {
		this.passwordForm = this.fb.group({
			newPassword: ['', [Validators.required]]
		});
	}

	onResetPassword() {
		if(this.passwordForm.valid){
			this.executeReset();
		}		
	}

	executeReset(){
		let newPassword = this.passwordForm.value['newPassword'];
		let resetPassword = {id: this.user.id, newPassword: newPassword};
		this.ref.close(resetPassword);
	}

	loadUser() {
		this.user = this.config.data;
	}

}
