import { Injectable } from '@angular/core';
import { Occurrence } from '../com/eddimas/avairs/model/entities/occurrence.model';
import { Subject } from 'rxjs';
import { Aircraft } from '../com/eddimas/avairs/model/entities/aircraft.model';
import { HttpMapping } from '../constants/http-mapping';
import { RepositoryService } from './repository.service';
import { Engine } from '../com/eddimas/avairs/model/entities/engine.model';
import { E5XEnumItem } from '../com/eddimas/avairs/model/beans/e5x-enum-item.model';
import { StaticValuesService } from './static-values.service';
import { FlightCrewMember } from '../com/eddimas/avairs/model/entities/flight-crew-member.model';
import { AirSpace } from '../com/eddimas/avairs/model/entities/airspace.model';

@Injectable({
  providedIn: 'root'
})
export class OccurrenceService {

  selectedOccurrence: Occurrence;
  occurrenceSubject = new Subject<Occurrence>();

  selectedAircraft: Aircraft;
  aircraftSubject = new Subject<Aircraft>();

  selectedEngine: Engine;
  engineSubject = new Subject<Engine>();

  selectedFlightCrewMember: FlightCrewMember;
  flightCrewMemberSubject = new Subject<FlightCrewMember>();

  selectedAirspace: AirSpace;
  airspaceSubject = new Subject<AirSpace>();

  staticValues: Map<string, E5XEnumItem[]>;


  isOccurrenceViewStatus: boolean;
  occurrenceViewSubject = new Subject<boolean>();

  constructor(public repository: RepositoryService, public staticValuesService: StaticValuesService) {
    this.selectedOccurrence = null;
    this.selectedAircraft = null;
    this.selectedEngine = null;
    this.selectedAirspace = null;
    this.isOccurrenceViewStatus = false;
    this.staticValues = new Map<string, E5XEnumItem[]>();
    this.loadCrewMemberCategory();
  }



  emitOccurrenceSubject() {
    this.occurrenceSubject.next(this.selectedOccurrence);
  }

  emitAircraftSubject() {
    this.aircraftSubject.next(this.selectedAircraft);
  }

  emitEngineSubject() {
    this.engineSubject.next(this.selectedEngine);
  }

  emitFlightCrewMemberSubject() {
    this.flightCrewMemberSubject.next(this.selectedFlightCrewMember);
  }

  emitAirspaceSubject() {
    this.airspaceSubject.next(this.selectedAirspace);
  }

  emitOccurrenceViewSubject() {
    this.occurrenceViewSubject.next(this.isOccurrenceViewStatus);
  }

  setSelectedOccurrence(occurrence: Occurrence) {
    this.selectedOccurrence = occurrence;
    this.selectedAircraft = null;
    this.selectedEngine = null;
    this.selectedAirspace = null;
    this.emitOccurrenceSubject();
    this.emitAircraftSubject();
    this.emitEngineSubject();
    this.emitAirspaceSubject();
  }

  setSelectedAirspaceName(name: string) {
    if (this.selectedOccurrence && this.selectedOccurrence.entities.airSpace) {
      this.selectedAirspace = this.selectedOccurrence.entities.airSpace.find(asp => asp.attributes.airspaceName && asp.attributes.airspaceName.value === name);
      this.emitAirspaceSubject();
    }
  }

  deleteSelectedAirspaceName(name: string) {
    if (this.selectedOccurrence && this.selectedOccurrence.entities.airSpace) {
      var selectedAirspaceIndex = this.selectedOccurrence.entities.airSpace.findIndex(asp => asp.attributes.airspaceName && asp.attributes.airspaceName.value === name);
      this.selectedOccurrence.entities.airSpace.splice(selectedAirspaceIndex, 1);
      this.selectedAirspace = null;

      //save occurrence
      let route = HttpMapping.OCCURRENCE_PUT_BY_ID.replace('{id}', this.selectedOccurrence.entityId);
      this.repository.update(route, this.selectedOccurrence, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
        this.emitAirspaceSubject();
        this.emitOccurrenceSubject();
      }, _error => {
        console.log(_error);
      });
    }
  }

  setSelectedAircraftRegistration(registration: string) {
    if (this.selectedOccurrence && this.selectedOccurrence.entities.aircraft) {
      this.selectedAircraft = this.selectedOccurrence.entities.aircraft.find(ac => ac.attributes.aircraftRegistration && ac.attributes.aircraftRegistration.value === registration);
      this.emitAircraftSubject();
    }
  }

  deleteSelectedAircraftRegistration(registration: string) {
    if (this.selectedOccurrence && this.selectedOccurrence.entities.aircraft) {
      var selectedAircraftIndex = this.selectedOccurrence.entities.aircraft.findIndex(ac => ac.attributes.aircraftRegistration && ac.attributes.aircraftRegistration.value === registration);
      this.selectedOccurrence.entities.aircraft.splice(selectedAircraftIndex, 1);
      this.selectedAircraft = null;

      //save occurrence
      let route = HttpMapping.OCCURRENCE_PUT_BY_ID.replace('{id}', this.selectedOccurrence.entityId);
      this.repository.update(route, this.selectedOccurrence, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
        this.emitAircraftSubject();
        this.emitOccurrenceSubject();
      }, _error => {
        console.log(_error);
      });
    }
  }

  setSelectedEngineSerialNumber(serialNumber: string) {
    if (this.selectedOccurrence && this.selectedAircraft && this.selectedAircraft.entities.engine) {
      this.selectedEngine = this.selectedAircraft.entities.engine.find(eng => eng.attributes.engineSerialNumber && eng.attributes.engineSerialNumber.value === serialNumber);
      this.emitEngineSubject();
    }
  }

  deleteSelectedEngineSerialNumber(serialNumber: string) {
    if (this.selectedOccurrence && this.selectedAircraft && this.selectedAircraft.entities.engine) {
      var selectedEngineIndex = this.selectedAircraft.entities.engine.findIndex(eng => eng.attributes.engineSerialNumber && eng.attributes.engineSerialNumber.value === serialNumber);
      this.selectedAircraft.entities.engine.splice(selectedEngineIndex, 1);
      this.selectedEngine = null;

      //save occurrence
      let route = HttpMapping.OCCURRENCE_PUT_BY_ID.replace('{id}', this.selectedOccurrence.entityId);
      this.repository.update(route, this.selectedOccurrence, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
        this.emitAircraftSubject();
        this.emitOccurrenceSubject();
      }, _error => {
        console.log(_error);
      });
    }
  }

  setSelectedCrewMemberCategory(category: string) {
    if (this.selectedOccurrence && this.selectedAircraft && this.selectedAircraft.entities.flightCrewMember) {
      this.selectedFlightCrewMember = this.selectedAircraft.entities.flightCrewMember.find(crew => crew.attributes.category && crew.attributes.category.value === category);
      this.emitFlightCrewMemberSubject();
    }
  }

  deleteSelectedCrewMemberCategory(category: string){
    if (this.selectedOccurrence && this.selectedAircraft && this.selectedAircraft.entities.flightCrewMember) {
      var selectedCrewIndex = this.selectedAircraft.entities.flightCrewMember.findIndex(crew => crew.attributes.category && crew.attributes.category.value === category);
      this.selectedAircraft.entities.flightCrewMember.splice(selectedCrewIndex, 1);

      //save occurrence
      let route = HttpMapping.OCCURRENCE_PUT_BY_ID.replace('{id}', this.selectedOccurrence.entityId);
      this.repository.update(route, this.selectedOccurrence, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
        this.emitAircraftSubject();
        this.emitOccurrenceSubject();
      }, _error => {
        console.log(_error);
      });
    }
  }

  deleteSelectedOccurrenceAerodromeGeneral(){
    if (this.selectedOccurrence && this.selectedOccurrence.entities) {
      this.selectedOccurrence.entities.aerodromeGeneral = [];
      //save occurrence
      let route = HttpMapping.OCCURRENCE_PUT_BY_ID.replace('{id}', this.selectedOccurrence.entityId);
      this.repository.update(route, this.selectedOccurrence, RepositoryService.SERVICE_AVAIRS).subscribe(() => {
        this.emitAircraftSubject();
        this.emitOccurrenceSubject();
      }, _error => {
        console.log(_error);
      });
    }
  }

  setOccurrenceViewStatus(status: boolean) {
    this.isOccurrenceViewStatus = status;
    this.emitOccurrenceViewSubject();
  }


  getAircraftCrewMemberCategory(category: string) {
    return this.staticValues['category'] && this.staticValues['category'].find(item => item.valueID === category) ?
      this.staticValues['category'].find(item => item.valueID === category).description : category;
  }

  loadCrewMemberCategory() {
    if (!this.staticValues['category']) {
      this.staticValuesService.getAttributeStaticValues('category').subscribe((staticValues: Map<string, E5XEnumItem[]>) => {
        if (staticValues['category']) this.staticValues['category'] = staticValues['category'];
      });
    }
  }

}
