import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreOrganization } from '../entities/core-organization';
import { SelectItem } from 'primeng/api';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { CoreState } from '../entities/core-state';
import { CoreOrganizationType } from '../entities/core-organization-type';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-organization-create',
	templateUrl: './organization-create.component.html',
	styleUrls: ['./organization-create.component.css']
})
export class OrganizationCreateComponent implements OnInit {

	organizationForm: FormGroup;
	organization = new CoreOrganization();

	logoImageSrc: any;

	//navigation routes
	ROUTE_ORGANIZATION_VIEW = '/security/organization-view';

	labels = {
		Title: 'Create Organization',
		Name: 'Name',
		Label: 'Label',
		Logo: 'Logo',
		Description: 'Description',
		ClientId: 'Clent ID',
		Type: 'Domaine',
		State: 'State',
		Address: 'Address',
		Telephone: 'Telephone',
		Email: 'Email',
		WebSite: 'Web site',
		LicenceRequired: 'Licence Required',
		Save: 'Save',
		Cancel: 'Cancel',
		SaveOkMessage: 'Organization saved successfully !',
		SaveFailedMessage: 'Fail to save Organization !',
		LoadStatesFailedMessage: 'Fail to load States !',
		LoadOrganizationTypesFailedMessage: 'Fail to load Organization types !'
	};

	organizationTypeItems: SelectItem[] = [];
	organizationTypes : CoreOrganizationType[] = [];


	stateItems: SelectItem[] = [];
	states: CoreState[] = [];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent,
		private domSanitizer: DomSanitizer,
		public fileLoadingService: FileLoadingService
	) { }

	ngOnInit() {
		this.organization.logo = 'ASECNA.jpg';
		this.loadStates();
		this.loadOrganizationTypes();
		this.initOrganizationForm();
	}

	loadStates(){
		const route = HttpMapping.CORE_STATE_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((states: CoreState[]) => {
			this.states = states.sort((a: CoreState, b: CoreState)  => a.name < b.name ? -1: 1);
			this.stateItems = [];
			this.stateItems.push({label: '', value: null});
			this.states.forEach(state => {
				this.stateItems.push(
					{ 
						label: state.name, 
						value: state 
					}
				);
			});
		}, err => {
			this.app.showError(this.labels.LoadStatesFailedMessage);
		});
	}

	loadOrganizationTypes(){
		const route = HttpMapping.CORE_ORGANIZATION_TYPE_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((organizationTypes: CoreOrganizationType[]) => {
			this.organizationTypes = organizationTypes.sort((a: CoreOrganizationType, b: CoreOrganizationType)  => a.label < b.label ? -1: 1);
			this.organizationTypeItems = [];
			this.organizationTypeItems.push({label: '', value: null});
			this.organizationTypes.forEach(organizationType => {
				this.organizationTypeItems.push(
					{ 
						label: organizationType.label, 
						value: organizationType 
					}
				);
			});
		}, err => {
			this.app.showError(this.labels.LoadOrganizationTypesFailedMessage);
		});
	}

	initOrganizationForm() {
		
		this.organizationForm = this.fb.group({
			address: ['', [Validators.required]],
			coreCode: [''],
			description: ['', [Validators.required]],
			email: ['', [Validators.required]],
			label: ['', [Validators.required]],
			licenceCheck: [true, [Validators.required]],
			logo: [''],
			name: ['', [Validators.required]],
			phone: ['', [Validators.required]],
			webSite: [''],
			coreStates: [null, [Validators.required]],
			coreOrganizationTypes: [null, [Validators.required]]
		});
	}


	public create() {
		if (this.organizationForm.valid) {
			this.executeCreation();
		}

	}

	private executeCreation() {
		var logo = this.organization.logo;
		this.organization = new CoreOrganization(
			null,
			this.organizationForm.value['address'],
			this.organizationForm.value['coreCode'],
			this.organizationForm.value['description'],
			this.organizationForm.value['email'],
			this.organizationForm.value['label'],
			this.organizationForm.value['licenceCheck']? 1: 0,
			this.organizationForm.value['logo'],
			this.organizationForm.value['name'],
			this.organizationForm.value['phone'],
			this.organizationForm.value['webSite'],
			this.organizationForm.value['coreStates'],
			this.organizationForm.value['coreOrganizationTypes']
		);


		this.organization.logo = logo;

		const route = HttpMapping.CORE_ORGANIZATION_POST;
		this.repository.create(route, this.organization, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.app.showInfo(this.labels.SaveOkMessage);
			this.router.navigate([this.ROUTE_ORGANIZATION_VIEW]);
		}, err => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}

	onCancel() {
		this.router.navigate([this.ROUTE_ORGANIZATION_VIEW]);
	}
	

	onUpload(event) {
		let file = event.target.files[0];
		if (file) {
			const route = HttpMapping.FILE_UPLOAD;
			this.fileLoadingService.uploadFile(route, file, FileLoadingService.SERVICE_SECURITY).subscribe(() => {
				this.organization.logo = file.name;
				this.displayLogo(file);
			}, err => {
				this.app.showError(this.labels.SaveFailedMessage);
			});
		}
	}

	downloadLogo(logo) {
		const route = HttpMapping.FILE_DOWNLOAD.replace('{filename}', logo);		
		this.fileLoadingService.downloadFile(route, FileLoadingService.SERVICE_SECURITY).subscribe((data) => {	
			this.displayLogo(data.body);
		}, err => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}

	displayLogo(file) {
		if (file) {
			const reader = new FileReader();
			reader.onload = ((e) => {
				this.logoImageSrc = this.domSanitizer.bypassSecurityTrustUrl(<string> e.target['result']); 
			});
			reader.readAsDataURL(file);
		}
	}

}
