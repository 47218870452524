import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoordinatesConvertorService {

  constructor() { }

  coordinateToDD(coordinateString: string) {

    var degreesCoordinate = coordinateString.toString();
    var splitDeegrees = degreesCoordinate.split('°');
    var degreesString = splitDeegrees.length > 1 ? splitDeegrees[0].trim() : '';
    var positionString = splitDeegrees.length > 1 ? splitDeegrees[1].trim() : '';

    var minutesCoordinate = splitDeegrees.length > 1 && degreesString != '' ? splitDeegrees[1].trim() : '';
    var splitMinutes = minutesCoordinate.split('\'');
    var minutesString = splitMinutes.length > 1 ? splitMinutes[0].trim() : '';
    positionString = splitMinutes.length > 1 ? splitMinutes[1].trim() : positionString;

    var secondsCoordinate = splitMinutes.length > 1 && minutesString != '' ? splitMinutes[1].trim() : '';
    var splitSeconds = secondsCoordinate.split('"');
    var secondsString = splitSeconds.length > 1 ? splitSeconds[0].trim() : '';
    positionString = splitSeconds.length > 1 ? splitSeconds[1].trim() : positionString;

    var degres: number = !Number.isNaN(Number.parseFloat(degreesString)) ? Number.parseFloat(degreesString) : 0.0;
    var minutes: number = !Number.isNaN(Number.parseFloat(minutesString)) ? Number.parseFloat(minutesString) : 0.0;
    var seconds: number = !Number.isNaN(Number.parseFloat(secondsString)) ? Number.parseFloat(secondsString) : 0.0;

    var coordinateDD = degres + (minutes / 60.0) + (seconds / 3600.0);

    var coordinateDDValue = positionString === 'S' || positionString === 'W' ? -coordinateDD : coordinateDD;

    return Number.parseFloat(coordinateDDValue.toFixed(16));

  }

  coordinateToDMS(coordinate: number, coordinateType) {
    var degreesDD = Math.abs(coordinate);
    var maxValue = coordinateType === 'LATITUDE' ? 90 : 180;
    var validCoordinateRange: boolean = !Number.isNaN(degreesDD) && degreesDD <= maxValue;

    var positivePosition = coordinateType === 'LATITUDE' ? 'N' : 'E';
    var negativePosition = coordinateType === 'LATITUDE' ? 'S' : 'W';
    var position = validCoordinateRange ? (coordinate < 0 ? negativePosition : positivePosition) : '';

    var degreesValue = validCoordinateRange ? Math.floor(degreesDD) : 0;
    var minutesMD = validCoordinateRange ? (degreesDD - degreesValue) * 60.0 : 0.0;
	var minutesValue = validCoordinateRange ? Math.floor(minutesMD) : 0;
	
    var secondsSD = validCoordinateRange ? (minutesMD - minutesValue) * 60.0 : 0.0;
    secondsSD = validCoordinateRange ? Number.parseFloat((secondsSD).toFixed(4)) : 0.0;

	var scondsValue = validCoordinateRange ? Math.floor(secondsSD) : 0;
	
    var resteSD = validCoordinateRange ? Number.parseFloat((secondsSD - scondsValue).toFixed(4)) : 0;
    resteSD = validCoordinateRange ? Math.abs(resteSD) * 10000.0 : 0;
    var resteValue = validCoordinateRange ? Math.floor(resteSD) : 0;
   
	if(scondsValue > 59){
		minutesValue++ ;
		scondsValue = scondsValue - 60;
	}
	
    var degreesString = coordinateType === 'LATITUDE' ? '0' + Math.abs(degreesValue) : '00' + Math.abs(degreesValue);
    var minutesString = coordinateType === 'LATITUDE' ? '0' + Math.abs(minutesValue) : '0' + Math.abs(minutesValue);
    var secondsString = coordinateType === 'LATITUDE' ? '0' + Math.abs(scondsValue) : '0' + Math.abs(scondsValue);
    var restString = resteValue  > 0 ? '.' + resteValue : '';

    degreesString = coordinateType === 'LATITUDE' ? degreesString.substring(degreesString.length - 2) : degreesString.substring(degreesString.length - 3);
    minutesString = coordinateType === 'LATITUDE' ? minutesString.substring(minutesString.length - 2) : minutesString.substring(minutesString.length - 2);
    secondsString = coordinateType === 'LATITUDE' ? secondsString.substring(secondsString.length - 2) : secondsString.substring(secondsString.length - 2);
    secondsString = secondsString + restString;


    return validCoordinateRange ? degreesString + '°' + minutesString + '\'' + secondsString + '"' + position : '';

  }
}
