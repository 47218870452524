import { RunwayDeclaredDistance } from "./runway-declared-distance";
import { GisElevatedPoint } from "./gis-elevated-point";

export class RunwayCentrelinePoint {
    id: string;
	role: string; // enumeration
	designator: string;
	location: GisElevatedPoint;
	associatedDeclaredDistances: RunwayDeclaredDistance[];
	navaidEquipment: string;
	annotation: string;

}