import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationViewComponent } from './organization-view/organization-view.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './organization-update/organization-update.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecurityRoutingModule } from './security-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MenubarModule, 
	DropdownModule, 
	ContextMenuModule, 
	ConfirmDialogModule, 
	InputTextModule, 
	ButtonModule, 
	TooltipModule, 
	AccordionModule, 
	PanelModule, 
	FileUploadModule, 
	TreeModule, 
	CheckboxModule, 
	RadioButtonModule, 
	DialogModule,
	DynamicDialogConfig,
	DynamicDialogRef,
	DialogService,
	MultiSelect,
	MultiSelectModule} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { HttpClientModule } from '@angular/common/http';
import { DataViewModule } from 'primeng/dataview';
import { LicenceViewComponent } from './licence-view/licence-view.component';
import { LicenceAddComponent } from './licence-add/licence-add.component';
import { LicenceRequestComponent } from './licence-request/licence-request.component';
import { LicenceClientComponent } from './licence-client/licence-client.component';
import { LicenceGenerateComponent } from './licence-generate/licence-generate.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { LicenceUserComponent } from './licence-user/licence-user.component';
import { LicenceAssignComponent } from './licence-assign/licence-assign.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';


@NgModule({
	declarations: [
		OrganizationViewComponent,
		OrganizationCreateComponent,
		OrganizationUpdateComponent,
		UserViewComponent,
		UserCreateComponent,
		UserUpdateComponent,
		LoginComponent,
		LicenceViewComponent,
		LicenceAddComponent,
		LicenceRequestComponent,
		LicenceClientComponent,
		LicenceGenerateComponent,
		PasswordChangeComponent,
		LicenceUserComponent,
		LicenceAssignComponent,
		PasswordResetComponent,
		ProfileEditComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SecurityRoutingModule,
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		CommonModule,
		MenubarModule,
		FormsModule,
		DropdownModule,
		TableModule,
		ContextMenuModule,
		ConfirmDialogModule,
		InputTextModule,
		DropdownModule,
		HttpClientModule,
		ButtonModule,
		TooltipModule,
		AccordionModule,
		DataViewModule,
		PanelModule,
		FileUploadModule,
		TreeModule,
		CheckboxModule,
		RadioButtonModule,
		DynamicDialogModule,
		DialogModule,
		MultiSelectModule
	],
	exports: [
		LoginComponent,
	],
	entryComponents: [
		PasswordChangeComponent,
		PasswordResetComponent,
		ProfileEditComponent
	]
})
export class SecurityModule { }
