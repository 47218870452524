import { Component, OnInit } from '@angular/core';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { FileLoadingService } from 'src/app/shared/services/file-loading.service';

@Component({
  selector: 'app-licence-add',
  templateUrl: './licence-add.component.html',
  styleUrls: ['./licence-add.component.css']
})
export class LicenceAddComponent implements OnInit {


	//navigation routes
	ROUTE_LICENCE_VIEW = '/security/licence-view';

	licenceFile: any;

	labels = {
		Title: 'Add licence',
		UplaodFile: 'Upload licence file',
		SelectLicenceFile: 'Select licence file to upload',
		Upload: 'Upload',
		Cancel: 'Cancel',
		UploadOkMessage: 'Licence uploaded successfully !',
		UploadFailedMessage: 'Fail to upload licence !',
		LoadStatesFailedMessage: 'Fail to load States !'
	};

	
	constructor(
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public fileLoadingService: FileLoadingService,
		public app: AppComponent
	) { }

	ngOnInit() {
	}

	onUpload() {
		if (this.licenceFile) {
			const route = HttpMapping.CORE_LICENCE_FILE_UPLOAD;
			this.fileLoadingService.uploadFile(route, this.licenceFile, RepositoryService.SERVICE_SECURITY).subscribe(() => {
				this.app.showInfo(this.labels.UploadOkMessage);
				this.router.navigate([this.ROUTE_LICENCE_VIEW]);
			}, err => {
				this.app.showError(this.labels.UploadFailedMessage);
			});
		}
	}

	onFileSelect(event){
		this.licenceFile = event.target.files[0];
	}

	onCancel() {
		this.router.navigate([this.ROUTE_LICENCE_VIEW]);
	}


}
