export class HttpMapping {

	/**
	 * File
	 */
	public static OCCURRENCE_FILE_UPLOAD = "/upload";
	public static OCCURRENCE_FILE_UPLOAD_MULTIFILES = "/upload/multifiles";
	public static OCCURRENCE_FILE_DOWNLOAD = "/download/{filename}";	
	
	public static OCCURRENCE_ATTACHMENT_FILE_DOWNLOAD = "/attachment/download/{id}/{filename}";
	public static OCCURRENCE_ATTACHMENT_FILE = "/attachment/{id}";
	public static OCCURRENCE_ATTACHMENT_MULTIFILES = "/attachment/multifiles/{id}";

	public static STATISTICS_GET_OCCURRENCES_BY_CLASS_ALL = "/statistics/classes";
	public static STATISTICS_GET_OCCURRENCES_BY_YEAR_ALL = "/statistics/years";
	public static STATISTICS_GET_OCCURRENCES_BY_MONTH_ALL = "/statistics/months";
	public static STATISTICS_GET_OCCURRENCES_DASHBOARD = "/statistics/dashboard/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_MONTH = "/statistics/bird-wild-months";
	public static STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_SPECIES = "/statistics/species-descriptions/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BIRD_WILD_BY_SIZES = "/statistics/bird-sizes/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_PHASES = "/statistics/phases/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_WEATHER_CONDITIONS = "/statistics/weather-conditions/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_WEATHER_CATEGORIES = "/statistics/weather-categories/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_OPERATORS = "/statistics/operators/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_CATEGORY = "/statistics/categories/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_TECHNICAL_CATEGORIES = "/statistics/technical-categories/{lang}";
	public static STATISTICS_POST_OCCURRENCES_BY_OPERATIONS = "/statistics/operations";
	public static STATISTICS_POST_OCCURRENCES_BY_FLIGHT_RULES = "/statistics/flight-rules/{lang}";

	public static OCCURRENCE_GET_ALL = "/occurrences";
	public static OCCURRENCE_GET_BY_ID = "/occurrences/{id}";
	public static OCCURRENCE_POST = "/occurrences/create";
	public static OCCURRENCE_PUT_BY_ID = "/occurrences/update/{id}";
	public static OCCURRENCE_DELETE_BY_ID = "/occurrences/delete/{id}";
	
	public static OCCURRENCE_IMPORT = "/occurrences/import";
	public static OCCURRENCE_IMPORT_MULTIPLE = "/occurrences/import_multiple";
	public static OCCURRENCE_EXPORT = "/occurrences/export/{filename}";


	//occurrence attributes values
	public static OCCURRENCE_GET_ATTRIBUTES_VALUES = "/occurrences/attributes/{attritubes}/{lang}";
	public static OCCURRENCE_GET_ATTRIBUTES_LABELS = "/occurrences/attributes/labels/{lang}";
	public static OCCURRENCE_GET_FILTERED_ATTRIBUTES_VALUES = "/occurrences/filteredAttributes/{attritubes}/{lang}";
	public static OCCURRENCE_GET_RESPONSIBLE_ENTITY = "/occurrences/responsibleEntities";

	public static OCCURRENCE_GET_WITH_CLASS = "/occurrences/withClass/{occurrenceClass}";

	//occurrence import export
	public static OCCURRENCE_GET_AVAIRS_EXPORT = "/occurrences/avairs/export/{idList}";	
	public static OCCURRENCE_GET_AVAIRS_EXPORT_ALL = "/occurrences/avairs/export-all";
	public static OCCURRENCE_GET_ECCAIRS_EXPORT = "/occurrences/eccairs/export/{idList}";
	public static OCCURRENCE_GET_ECCAIRS_EXPORT_ALL = "/occurrences/eccairs/export-all";
	
	public static OCCURRENCE_POST_AVAIRS_EXPORT = "/occurrences/avairs/export";
	public static OCCURRENCE_POST_ECCAIRS_EXPORT = "/occurrences/eccairs/export";
	public static OCCURRENCE_POST_AVAIRS_IMPORT = "/occurrences/avairs/import";
	public static OCCURRENCE_POST_ECCAIRS_IMPORT = "/occurrences/eccairs/import";
	
	public static OCCURRENCE_PUT_IMPORT_CONFIRM = "/occurrences/import/confirm/{overwrite}";
	
	public static OCCURRENCE_GET_IMPORTED = "/occurrences/imported";

	//notification
	public static OCCURRENCE_POST_NOTIFICATION_SHEET_PDF = "/occurrences/notification/sheet/pdf";
	public static OCCURRENCE_POST_NOTIFICATION_SHEET_DOCX = "/occurrences/notification/sheet/docx";
	public static OCCURRENCE_GET_NOTIFY_FORM = "/occurrences/notifyForm/{id}/{lang}";
	public static OCCURRENCE_GET_NOTIFICATION = "/occurrences/notification/{occurrenceId}/{notificationId}";
	public static OCCURRENCE_GET_NOTIFICATION_SHEET = "/occurrences/notification/sheet/{lang}";
	public static OCCURRENCE_GET_NOTIFICATION_REPORT = "/occurrences/notification/report/{occurrenceId}/{notificationId}"; 
	public static OCCURRENCE_GET_NOTIFICATION_BUILD = "/occurrences/notification/build/{id}/{lang}";
	public static OCCURRENCE_PUT_NOTIFICATION_DESTINATORS = "/occurrences/notification/destinators/{occurrenceId}/{notificationId}";
	public static OCCURRENCE_PUT_NOTIFICATION_INVESTIGATOR = "/occurrences/notification/investigator/{occurrenceId}/{notificationId}";
	public static OCCURRENCE_GET_NOTIFICATION_ALL= "/occurrences/notifications/{occurrenceId}";
	public static OCCURRENCE_GET_NOTIFICATION_DELETE = "/occurrences/notification/delete/{occurrenceId}/{notificationId}";

	/**
	 * Security REST Mapping
	 */

	public static FILE_UPLOAD = "/upload";
	public static FILE_UPLOAD_MULTIFILES = "/upload/multifiles";
	public static FILE_DOWNLOAD = "/download/{filename}";
	
	// Login
	public static LOGIN_URL = "/login";
	
	
	 // CoreLicence REST Mapping
	public static CORE_LICENCE_GET_ALL = "/coreLicences";
	public static CORE_LICENCE_GET_USER_AVAILABLE = "/coreLicences/user/available";
	public static CORE_LICENCE_GET_USER_GRANTED = "/coreLicences/user/granted";
	public static CORE_LICENCE_PUT_SUBSCRIBE_BY_ID = "/coreLicences/subscribe/{id}";
	public static CORE_LICENCE_PUT_ASSIGN_BY_ID = "/coreLicences/assign/{id}";
	public static CORE_LICENCE_PUT_UNSUBSCRIBE_BY_ID = "/coreLicences/unsubscribe/{id}";
	public static CORE_LICENCE_FILE_UPLOAD = "/coreLicences/upload";
	public static CORE_LICENCE_REQUEST_DOWNLOAD = "/coreLicences/request/download";
	public static CORE_LICENCE_GET_BY_ID = "/coreLicences/{id}";
	public static CORE_LICENCE_DECODE_GET_BY_ID = "/coreLicences/decode/{id}";

	public static CORE_LICENCE_POST = "/coreLicences/create";
	public static CORE_LICENCE_PUT_BY_ID = "/coreLicences/update/{id}";
	public static CORE_LICENCE_DELETE_BY_ID = "/coreLicences/delete/{id}";

	//Client licence
	public static CLIENT_LICENCE_GET_ALL = "/eddimas-licencing/coreLicences";
	public static CLIENT_LICENCE_GET_BY_ID = "/eddimas-licencing/coreLicences/{id}";
	public static CLIENT_LICENCE_POST = "/eddimas-licencing/coreLicences/create";
	public static CLIENT_LICENCE_POST_GENERATE = "/eddimas-licencing/coreLicences/generate";
	public static CLIENT_LICENCE_POST_GENERATE_FROM_REQUEST_FILE = "/eddimas-licencing/coreLicences/generate/fromRequestFile";
	public static CLIENT_LICENCE_POST_GENERATE_AND_DOWNLOAD_FROM_REQUEST_FILE = "/eddimas-licencing/coreLicences/generate/donwload/fromRequestFile";
	
	public static CLIENT_LICENCE_GET_DOWNLOAD = "/eddimas-licencing/coreLicences/download/{id}";
	public static CLIENT_LICENCE_GET_DISPLAY = "/eddimas-licencing/coreLicences/display";
	public static CLIENT_LICENCE_PUT_BY_ID = "/eddimas-licencing/coreLicences/update/{id}";
	public static CLIENT_LICENCE_DELETE_BY_ID = "/eddimas-licencing/coreLicences/delete/{id}";
	

	
	 // CoreOrganization REST Mapping
	 
	public static CORE_ORGANIZATION_GET_ALL = "/coreOrganizations";
	public static CORE_ORGANIZATION_GET_BY_ID = "/coreOrganizations/{id}";
	public static CORE_ORGANIZATION_POST = "/coreOrganizations/create";
	public static CORE_ORGANIZATION_PUT_BY_ID = "/coreOrganizations/update/{id}";
	public static CORE_ORGANIZATION_DELETE_BY_ID = "/coreOrganizations/delete/{id}";
	

	
	 // CoreOrganizationType REST Mapping
	 
	public static CORE_ORGANIZATION_TYPE_GET_ALL = "/coreOrganizationTypes";
	public static CORE_ORGANIZATION_TYPE_GET_BY_ID = "/coreOrganizationTypes/{id}";
	public static CORE_ORGANIZATION_TYPE_POST = "/coreOrganizationTypes/create";
	public static CORE_ORGANIZATION_TYPE_PUT_BY_ID = "/coreOrganizationTypes/update/{id}";
	public static CORE_ORGANIZATION_TYPE_DELETE_BY_ID = "/coreOrganizationTypes/delete/{id}";
	

	
	 // CoreRole REST Mapping
	 
	public static CORE_ROLE_GET_ALL = "/coreRoles";
	public static CORE_ROLE_GET_BY_ID = "/coreRoles/{id}";
	public static CORE_ROLE_POST = "/coreRoles/create";
	public static CORE_ROLE_PUT_BY_ID = "/coreRoles/update/{id}";
	public static CORE_ROLE_DELETE_BY_ID = "/coreRoles/delete/{id}";
	


	
	 // CoreState REST Mapping
	 
	public static CORE_STATE_GET_ALL = "/coreStates";
	public static CORE_STATE_GET_BY_ID = "/coreStates/{id}";
	public static CORE_STATE_POST = "/coreStates/create";
	public static CORE_STATE_PUT_BY_ID = "/coreStates/update/{id}";
	public static CORE_STATE_DELETE_BY_ID = "/coreStates/delete/{id}";
	


	
	 // CoreUser REST Mapping
	 
	public static CORE_USER_GET_ALL = "/coreUsers";
	public static CORE_USER_GET_BY_ID = "/coreUsers/{id}";
	public static CORE_USER_GET_BY_USERNAME = "/coreUsers/username/{username}";
	public static CORE_USER_GET_BY_EMAIL = "/coreUsers/email/{email}";
	public static CORE_USER_POST = "/coreUsers/create";
	public static CORE_USER_PUT_BY_ID = "/coreUsers/update/{id}";
	public static CORE_USER_PUT_RESET_PASSWD_BY_ID = "/coreUsers/resetpasswd/{id}/{newpassword}";
	public static CORE_USER_PUT_PASS_BY_ID = "/coreUsers/cc/{id}/{currentpassword}/{newpassword}";
	public static CORE_USER_PUT_PASS_BY_USERNAME = "/coreUsers/cc/{username}/{currentpassword}/{newpassword}";
	public static CORE_USER_DELETE_BY_ID = "/coreUsers/delete/{id}";
	public static CORE_USER_GRANT_ROLE = "/coreUsers/grant/{userId}/{roleIds}";
	public static CORE_USER_REVOKE_ROLE = "/coreUsers/revoke/{userId}/{roleIds}";
	public static CORE_USER_PUT_LOCALE_BY_USERNAME = "/coreUsers/profile/locale/{username}/{locale}";
	public static CORE_USER_PUT_THEME_BY_USERNAME = "/coreUsers/profile/theme/{username}/{theme}";
	

	/**
	 * AirportHeliport REST Mapping
	 */
	public static AIRPORT_HELIPORT_GET_ALL = "/eddimas-olsat/airportHeliports";
	public static AIRPORT_HELIPORT_GET_DEFAULT = "/eddimas-olsat/airportHeliports/default";
	public static AIRPORT_HELIPORT_GET_BY_ID = "/eddimas-olsat/airportHeliports/{id}";
	public static AIRPORT_HELIPORT_GET_USER_LICENCE_COVERED = "/eddimas-olsat/airportHeliports/licenceCovered";
	public static AIRPORT_HELIPORT_POST = "/eddimas-olsat/airportHeliports";
	public static AIRPORT_HELIPORT_PUT_BY_ID = "/eddimas-olsat/airportHeliports/update/{id}";
	public static AIRPORT_HELIPORT_DELETE_BY_ID = "/eddimas-olsat/airportHeliports/{id}";
	public static AIRPORT_HELIPORT_DELETE_RUNWAY_BY_ID = "/eddimas-olsat/airportHeliports/{id}/{rwy}";
	public static AIRPORT_HELIPORT_PUT_ADD_RUNWAY = "/eddimas-olsat/airportHeliports/add-runway/{id}";
	public static AIRPORT_HELIPORT_PUT_UPDATE_RUNWAY = "/eddimas-olsat/airportHeliports/update-runway/{id}";
	public static AIRPORT_HELIPORT_DELETE_REMOVE_RUNWAY = "/eddimas-olsat/airportHeliports/runway/{adId}/{rwyId}";
	
	
}