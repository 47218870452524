import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GisRoutingModule } from './gis-routing.module';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DmsComponent } from './dms/dms.component';
import { InputTextModule, ButtonModule, InputMaskModule } from 'primeng/primeng';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        DmsComponent
    ],
    imports: [
        CommonModule,
        GisRoutingModule,
        KeyFilterModule,
        InputTextModule,
        FormsModule,
        ButtonModule,
        InputMaskModule,
        SharedModule,
        ReactiveFormsModule

    ],
    exports: [
        DmsComponent
    ]
})
export class GisModule { }
