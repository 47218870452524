export class CoordinatesRegex {

   public static LATITUDE_DD_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}|"
    + "9{1}0{1}|"
    + "9{1}0{1}°{1}|"
    + "9{1}0{1}°{1}[NS]{1}|"
    + "9{1}0{1}\\.{1}0{0,16}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}[NS]{1}|"

    + "[0-8]{1}|"
    + "[0-8]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}|"
    + "[0-8]{1}\\d{1}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{0,16}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}[NS]{1})$"
  );

  public static LATITUDE_DM_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{0,8}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[NS]{1}|"

    + "[0-8]{1}\\d{1}°{1}[0-5]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[NS]{1})$"
  );

  public static LATITUDE_DMS_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{0,4}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[NS]{1}|"

    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[NS]{1})$"
  );

  public static LATITUDE_COMPLETE_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "9{1}0{1}°{1}[NS]{1}|"
    + "9{1}0{1}\\.{1}0{1,16}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}\\.{1}\\d{1,16}°{1}[NS]{1}|"

    + "9{1}0{1}°{1}0{1}0{1}'{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[NS]{1}|"

    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[NS]{1}|"
    + "9{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[NS]{1}|"
    + "[0-8]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[NS]{1})$"
  );

  public static LONGITUDE_DD_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "[0-1]{1}|"
    + "1{1}8{1}|"
    + "1{1}8{1}0{1}|"
    + "1{1}8{1}0{1}°{1}|"
    + "1{1}8{1}0{1}°{1}[EW]{1}|"
    + "1{1}8{1}0{1}\\.{1}0{0,16}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}[EW]{1}|"

    + "0{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{0,16}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"

    + "1{1}[0-7]{1}|"
    + "1{1}[0-7]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{0,16}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1})$");

  public static LONGITUDE_DM_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{0,8}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[EW]{1}|"

    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"

    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{0,8}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1})$"
  );

  public static LONGITUDE_DMS_TYPING_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{0,4}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[EW]{1}|"

    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1}|"

    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{0,4}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1})$"
  );

  public static LONGITUDE_COMPLETE_PATTERN: RegExp = new RegExp(""
    + "^(?:"
    + "1{1}8{1}0{1}°{1}[EW]{1}|"
    + "1{1}8{1}0{1}\\.{1}0{1,16}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}\\.{1}\\d{1,16}°{1}[EW]{1}|"

    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}\\.{1}0{1,8}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}\\.{1}\\d{1,8}'{1}[EW]{1}|"

    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\"{1}[EW]{1}|"
    + "1{1}8{1}0{1}°{1}0{1}0{1}'{1}0{1}0{1}\\.{1}0{1,4}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "0{1}\\d{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\"{1}[EW]{1}|"
    + "1{1}[0-7]{1}\\d{1}°{1}[0-5]{1}\\d{1}'{1}[0-5]{1}\\d{1}\\.{1}\\d{1,4}\"{1}[EW]{1})$"
  );

}