import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreUser } from '../entities/core-user';
import { SelectItem } from 'primeng/api';
import { CoreOrganization } from '../entities/core-organization';
import { CoreRole } from '../entities/core-role';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { Authentication } from '../entities/authentication';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
	selector: 'app-user-update',
	templateUrl: './user-update.component.html',
	styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit, OnDestroy {

	authentication: Authentication;
	authenticationSubscription: Subscription;

	userForm: FormGroup;
	user = new CoreUser();



	//navigation routes
	ROUTE_USER_VIEW = '/security/user-view';

	labels = {
		Title: 'Update User',
		FirstName: 'First Name',
		LastName: 'Last Name',
		Username: 'Username',
		UserTitle: 'Title',
		Email: 'Email',
		Organization: 'Organization',
		UserRoles: 'User Roles',
		Activated: 'Activated',
		Address: 'Address',
		Telephone: 'Telephone',
		Save: 'Save',
		Cancel: 'Cancel',
		SaveOkMessage: 'User saved successfully !',
		SaveFailedMessage: 'Fail to save User !',
		LoadFailedMessage: 'Fail to load User !',
		LoadOrganizationsFailedMessage: 'Fail to load Organization !'
	};

	organizationItems: SelectItem[] = [];
	organizations: CoreOrganization[] = [];
	selectedOrganization: CoreOrganization;

	availableRoles: CoreRole[] = [];
	selectedRoles: CoreRole[] = [];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public authenticationService: AuthenticationService,
		public app: AppComponent
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	ngOnInit() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
		this.loadOrganizations();
		this.initUserForm();
	}

	loadOrganizations() {
		if (this.authentication.hasSysRole) {
			const route = HttpMapping.CORE_ORGANIZATION_GET_ALL;
			this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((organizations: CoreOrganization[]) => {
				this.organizations = organizations.sort((a: CoreOrganization, b: CoreOrganization) => a.name < b.name ? -1 : 1);
				this.organizationItems = [];
				this.organizationItems.push({ label: '', value: null });
				this.organizations.forEach(organization => {
					this.organizationItems.push(
						{
							label: organization.name,
							value: organization
						}
					);
				});
			}, err => {
				this.app.showError(this.labels.LoadOrganizationsFailedMessage);
			});
		}
	}

	initUserForm() {
		this.userForm = this.fb.group({
			activated: [true, [Validators.required]],
			address: [''],
			email: ['', [Validators.required]],
			firstname: ['', [Validators.required]],
			lastname: ['', [Validators.required]],
			phone: [''],
			title: [''],
			username: ['', [Validators.required]],
			userProfile: [null],
			coreOrganization: [null, [Validators.required]],
			coreRoles: [[], [Validators.required]]
		});

		if (this.activatedRoute.snapshot.paramMap.keys.includes('id')) {
			this.loadUser(this.activatedRoute.snapshot.params['id']);
		}

	}

	loadUser(id: string) {
		let route = HttpMapping.CORE_USER_GET_BY_ID.replace('{id}', id);
		this.repository.getData(route,RepositoryService.SERVICE_SECURITY).subscribe((user: CoreUser) => {
			this.user = user ? user : null;

			this.userForm.setValue({
				activated: this.user.activated === 1 ? true : false,
				address: this.user.address,
				email: this.user.email,
				firstname: this.user.firstname,
				lastname: this.user.lastname,
				phone: this.user.phone,
				title: this.user.title,
				username: this.user.username,
				userProfile: this.user.userProfile,
				coreOrganization: this.user.coreOrganization,
				coreRoles: this.loadUserRolesLabels(this.user)
			});

			this.selectedOrganization = this.user.coreOrganization;

			this.availableRoles = [];
			if (this.selectedOrganization) {
				this.selectedOrganization.coreOrganizationTypes.forEach(org => {
					org.coreRoles.forEach(rol => this.availableRoles.push(rol));
				});
			}

		}, (error) => {
			this.app.showError(this.labels.LoadFailedMessage);
		});
	}

	public update() {
		if (this.userForm.valid) {
			this.executeUpdate();
		}

	}

	private executeUpdate() {

		this.user.activated = this.userForm.value['activated'] ? 1 : 0;
		this.user.address = this.userForm.value['address'];
		this.user.email = this.userForm.value['email'];
		this.user.firstname = this.userForm.value['firstname'];
		this.user.lastname = this.userForm.value['lastname'];
		this.user.phone = this.userForm.value['phone'];
		this.user.title = this.userForm.value['title'];
		this.user.username = this.userForm.value['username'];
		this.user.coreOrganization = this.userForm.value['coreOrganization'];
		this.user.coreRoles = this.getSelectedRoles();

		const route = HttpMapping.CORE_USER_PUT_BY_ID.replace('{id}', this.user.id);
		this.repository.update(route, this.user, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.app.showInfo(this.labels.SaveOkMessage);
			this.router.navigate([this.ROUTE_USER_VIEW]);
		}, err => {
			this.app.showError(this.labels.SaveFailedMessage);
		});
	}

	onOrganizationChange() {
		this.selectedOrganization = this.userForm.value['coreOrganization'];
		//this.availableRoles = this.selectedOrganization ? this.selectedOrganization.coreOrganizationType.coreRoles : [];
		this.availableRoles = [];
		if (this.selectedOrganization) {
			this.selectedOrganization.coreOrganizationTypes.forEach(org => {
				org.coreRoles.forEach(rol => this.availableRoles.push(rol));
			});
		}
	}

	getSelectedRoles() {
		var userRoles = [];
		var selection = this.userForm.value['coreRoles'];
		selection = selection ? selection : [];
		this.availableRoles.forEach(role => {
			if (selection.includes(role.label)) userRoles.push(role);
		});
		return userRoles;
	}

	loadUserRolesLabels(user: CoreUser) {
		var labels = [];
		user.coreRoles.forEach(role => {
			labels.push(role.label);
		});
		return labels;
	}

	onRolesChange() {
		/*
		var userRoles = [];
		this.availableRoles.forEach(role => {
			if(this.userForm.value['coreRoles'].includes(role.label)) userRoles.push(role);
		})
		console.log(userRoles);
		/*
		this.userForm.value['coreRoles'].forEach((role: string)=> {
			console.log(role);
		});*/

	}

	onCancel() {
		this.router.navigate([this.ROUTE_USER_VIEW]);
	}
}
