import { Component, OnInit } from '@angular/core';
import { CoreUser } from '../entities/core-user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';

@Component({
	selector: 'app-licence-assign',
	templateUrl: './licence-assign.component.html',
	styleUrls: ['./licence-assign.component.css']
})
export class LicenceAssignComponent implements OnInit {

	userForm: FormGroup;
	user = new CoreUser();



	//navigation routes
	ROUTE_LICENCE_VIEW = '/security/licence-view';

	labels = {
		Title: 'Assign licence to users',
		LicenceUsers: 'Licence Users',
		Save: 'Save',
		Cancel: 'Cancel',
		SaveOkMessage: 'Licence saved successfully !',
		SaveFailedMessage: 'Fail to save Licence !',
		LoadFailedMessage: 'Fail to load Licence !',
		LoadOrganizationsFailedMessage: 'Fail to load Organization !'
	};


	licence: any;

	users: CoreUser[] = [];
	licenceUsers: CoreUser[] = [];

	licenceUserNames: string[] = [];


	constructor(
		public fb: FormBuilder,
		public repository: RepositoryService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public app: AppComponent
	) { }


	ngOnInit() {
		this.onLoadUsers();
		this.onLoadLicence();
	}

	onLoadUsers() {
		let route = HttpMapping.CORE_USER_GET_ALL;
		this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((users: CoreUser[]) => {
			this.users = users;
		});
	}

	onLoadLicence() {
		if (this.activatedRoute.snapshot.paramMap.keys.includes('id')) {
			let licenceId = this.activatedRoute.snapshot.params['id'];
			let route = HttpMapping.CORE_LICENCE_DECODE_GET_BY_ID.replace('{id}', licenceId);
			this.repository.getData(route, RepositoryService.SERVICE_SECURITY).subscribe((licence) => {
				this.licence = licence;
				this.licenceUserNames = this.userNames();
			});
		}
	}

	userNames(): string[] {
		let userNames = [];
		if (this.licence) {
			let users: [] = this.licence.coreUsers != null ? this.licence.coreUsers : [];
			if (users.length > 0) {
				users.forEach((us: CoreUser) => {
					userNames.push(us.username);
				})
			}
		}
		return userNames;
	}



	public update() {
		this.executeUpdate();
	}

	private executeUpdate() {

		this.licenceUsers = this.getSelectedUsers();

		const route = HttpMapping.CORE_LICENCE_PUT_ASSIGN_BY_ID.replace('{id}', this.licence.id);
		this.repository.update(route, this.licenceUsers, RepositoryService.SERVICE_SECURITY).subscribe(() => {
			this.app.showInfo(this.labels.SaveOkMessage);
			this.router.navigate([this.ROUTE_LICENCE_VIEW]);
		});
	}


	getSelectedUsers() {
		let selectedUsers: CoreUser[] = [];
		this.users.forEach(us => {
			if (this.licenceUserNames.includes(us.username)) selectedUsers.push(us);
		});
		return selectedUsers;
	}


	disableUser(user: CoreUser) {
		let intendedUser: number = this.licence.licenceDetails.intendedUsers;
		if (intendedUser != -1 && this.licenceUserNames.length >= intendedUser && !this.licenceUserNames.includes(user.username)) {
			return true;
		} else {
			return false;
		}
	}

	onCancel() {
		this.router.navigate([this.ROUTE_LICENCE_VIEW]);
	}
}
