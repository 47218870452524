import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Authentication } from '../entities/authentication';
import { Subscription } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
	selector: 'app-password-change',
	templateUrl: './password-change.component.html',
	styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit, OnDestroy {

	passwordForm: FormGroup;

	labels = {
		Title: 'Change password',
		CurrentPassword: 'Current password',
		NewPassword: 'New Password',
		Confirm: 'Confirm new password',
		Change: 'Change',
		ChangeOkMessage: 'Password changed successfuly !',

	};

	authentication: Authentication;
	authenticationSubscription: Subscription;

	constructor(
		public fb: FormBuilder,
		public authenticationService: AuthenticationService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig
	) { }

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	ngOnInit() {
		this.initPasswordForm();
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}

	initPasswordForm() {
		this.passwordForm = this.fb.group({
			currentPassword: ['', [Validators.required]],
			newPasswordGroup: this.fb.group({
				newPassword: ['', [Validators.required]],
				confirmPassword: ['']
			},
				{
					validator: this.validateConfirm
				}
			)
		});
	}

	onChangePasswword() {
		let passwordChange = {
			currentPassword: this.passwordForm.value['currentPassword'],
			newPassword: this.passwordForm.value['newPasswordGroup'].newPassword
		}
		this.ref.close(passwordChange);
	}

	validateConfirm(passwdGroup: FormGroup) {
		let NEW_PASSWORD = passwdGroup.value['newPassword'];
		let CONFIRM = passwdGroup.value['confirmPassword'];
		return CONFIRM && CONFIRM === NEW_PASSWORD ? null : { valid: false };
	}


}
